import { ActionType, createAction } from "typesafe-actions";
import { RequestState } from "../constants/request-state";
import { SettlementAgentCommissionByRating } from "../types/bid-as-dealer/SettlementAgentCommissionByRating";

export type TSettlementAgentCommissionActionType = ActionType<
    typeof settlementAgentCommissionRequest |
    typeof settlementAgentCommissionResponse
>;

const saveClearingBankSettings = createAction('clearingBank/SAVE_SETTINGS',
    resolve => (companyId: number, isClearingBank: boolean, commission?: SettlementAgentCommissionByRating) =>
        resolve({ companyId, isClearingBank, commission })
);
const settlementAgentCommissionRequest = createAction('entities/settlement-agent-commission/SETTLEMENT-AGENT-COMMISSION-REQUEST',
    resolve => (companyId: number) => resolve({ companyId })
);
const settlementAgentCommissionResponse = createAction('entities/settlement-agent-commission/SETTLEMENT-AGENT-COMMISSION-RESPONSE',
    resolve => (requestState: RequestState, companyId: number, commission?: SettlementAgentCommissionByRating) =>
        resolve({ requestState, companyId, commission })
);

export const clearingBankActions = {
    saveClearingBankSettings,
    settlementAgentCommissionRequest,
    settlementAgentCommissionResponse
}
