import moment from 'moment';
import { values, isEqual } from 'lodash';
import {
    processActions as actionTypes,
    errorMessages,
    constants
} from '../constants';
import { bwicProcessService as processService } from '../services';
import { errorActions } from '.';
import { dateTimeUtils, arrayUtils, numericUtils, formatUtils } from '../utils';
import { LevelSpecificationType } from '../types/enums/LevelSpecificationType';
import { BwicProcessType } from '../types/models/Process';
import { JumpBallStage2Participants } from '../types/bwic-process/JumpBallSettings';
import { TopXStage2Participants } from '../types/bwic-process/TopXSettings';
import { appConfig } from '../app-config';
import { OpenBiddingImproverCount } from '../types/enums/OpenBiddingImproverCount';

export const processActions = {
    init,
    reset,
    changeDate,
    changeDaysToSettle,
    changeTimeZone,
    changeBidsDueTime,
    changeGoodUntilTime,
    validate,

    storeNextBusiessDay,
    selectTemplate,
    reserveLevelsApplyEnabled,
    earlyTradesEnabled,
    rulesText,
    setStage1Deadline,
    setImprovementRound,
    setMinBidIncrement,
    setLevelSpecificationType,
    setOpenBiddingImproverCount,
    resetStage1DeadlineError,
    resetImprovementRoundError,
    resetMinBidIncrementError,
    resetJumpBallStage2DurationMinutes,
    resetBestFootForwardDurationMinutes,
    resetJumpBallStage1AutomaticMinutes,
    resetTopXStage1AutomaticMinutes,
    resetTopXStage2DurationMinutes,
    titleChange,
    descriptionChange,
    setMinDate,
    setHolidays,
    resetChanges,

    jumpBallStage1EndManual,
    jumpBallStage1EndAuto,
    jumpBallStage1EndAutomaticMinutesChange,
    jumpBallStage2ParticipantsChange,
    jumpBallStage2DurationUnspecified,
    jumpBallStage2DurationPreset,
    jumpBallStage2DurationMinutesChange,

    autoFeedbackDelayChange,
    autoFeedbackDelayEnabled,
    autoFeedbackDelayDisabled,
    minBidIncrementChange,
    minBidIncrementEnabled,
    minBidIncrementDisabled,

    cutOffReminderChange,

    topXStage1EndManual,
    topXStage1EndAuto,
    topXStage1EndAutomaticMinutesChange,
    topXStage2ParticipantsChange,
    topXStage2DurationUnspecified,
    topXStage2DurationPreset,
    topXStage2DurationMinutesChange,

    bestFootForwardDurationMinutesChange,
    setAllToAllProtocol
}

function init() {
    return async (dispatch, getState) => {
        const { process } = getState();

        if (process.templates.all?.length) {
            return; // already initialized
        }

        try {
            const templates = await processService.getTemplates();
            dispatch(addTemplates(templates));
            dispatch(selectTemplate(0));
        } catch (e) {
            dispatch(errorActions.criticalError(e));
        }
    }
}

function setMinDate(minimumDate) {
    return {
        type: actionTypes.MIN_DATE,
        minimumDate
    };
}

function setBwicDisabledTime() {
    return (dispatch, getState) => {
        const { bidsDue, goodUntil, timeZone, timeZones } = getState().process;
        const { fromTime, toTime } = getBwicAllowedTimeRange(timeZone, timeZones);
        const all = arrayUtils.arrayFromRange(0, 23);
        const allowed = arrayUtils.arrayFromRange(fromTime.hour(), toTime.hour());
        const disabledHours = all.filter(h => !allowed.some(a => a === h));

        const selectedTimeZone = timeZones.find(t => t.id === timeZone);
        const frameEndHour = toTime.hour();
        const isBidsDueMinutesDisabled = shouldDisableMinutes(bidsDue, selectedTimeZone, frameEndHour);
        const isGoodUntilMinutesDisabled = shouldDisableMinutes(goodUntil, selectedTimeZone, frameEndHour);

        dispatch({
            type: actionTypes.SET_DISABLED_TIME,
            disabledHours,
            isBidsDueMinutesDisabled,
            isGoodUntilMinutesDisabled
        });
    };
}

function shouldDisableMinutes(time, timeZone, frameEndHour) {
    const hour = moment
        .utc(time)
        .utcOffset(timeZone.utcOffsetMinutes, true)
        .hour();

    return hour === frameEndHour;
}

function getBwicAllowedTimeRange(timeZoneId, timeZones) {
    const est = timeZones.find(t => t.abbreviation === 'EST');
    const selected = timeZones.find(t => t.id === timeZoneId);
    const { from, to } = constants.bwicAllowedTimeRange;
    const fromTime = moment.utc(dateTimeUtils.timestampToUtcString(from)).utcOffset(est.utcOffsetMinutes, true).utcOffset(selected.utcOffsetMinutes);
    const toTime = moment.utc(dateTimeUtils.timestampToUtcString(to)).utcOffset(est.utcOffsetMinutes, true).utcOffset(selected.utcOffsetMinutes);

    return { fromTime, toTime };
}

function storeNextBusiessDay(date) {
    return {
        type: actionTypes.NEXT_BUSINESS_DAY,
        date
    };
}

function changeDate(date) {
    return (dispatch, getState) => {
        const { process } = getState();
        if (process.date !== date) {
            dispatch({ type: actionTypes.CHANGE_DATE, date });
            if (process.errors) {
                dispatch(validate());
            }
        }
    }
}

function setHolidays(holidays) {
    return {
        type: actionTypes.HOLIDAYS,
        holidays
    }
}

function changeDaysToSettle(days) {
    return {
        type: actionTypes.DAYS_TO_SETTLE,
        days
    };
}

function changeTimeZone(timeZone) {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.TIME_ZONE,
            timeZone
        });
        dispatch(setBwicDisabledTime());

        const { errors = {} } = getState().process;
        if (errors.time) {
            dispatch(validate());
        }
    };
}

function changeBidsDueTime(time) {
    return (dispatch, getState) => {
        const { errors = {}, goodUntil } = getState().process;

        dispatch({ type: actionTypes.BIDS_DUE, time });

        if (errors.time || hasTemplateErrors(errors)) {
            dispatch(validate());
        }

        if (time) {
            const bidsDueTime = moment.utc(time);
            const goodUntilCurrentTime = moment.utc(goodUntil);
            const frameEnd = dateTimeUtils.timestampToUtcString(constants.bwicAllowedTimeRange.to);

            const goodUntilNewTime = goodUntilCurrentTime.isSame(bidsDueTime, 'day') && goodUntilCurrentTime <= moment.utc(frameEnd)
                ? goodUntilCurrentTime.format(constants.dateTimeFormatUtc)
                : frameEnd;

            dispatch(changeGoodUntilTime(goodUntilNewTime));
        }
    }
}

function changeGoodUntilTime(time) {
    return (dispatch, getState) => {
        const { errors = {} } = getState().process;

        dispatch({ type: actionTypes.GOOD_UNTIL, time });
        if (errors.time || hasTemplateErrors(errors)) {
            dispatch(validate());
        }

        if (time) {
            dispatch(setBwicDisabledTime());
        }
    }
}

function hasTemplateErrors(errors) {
    return errors.templates && Object.values(errors.templates).some(e => e);
}

function validate() {
    return (dispatch, getState) => {
        const { process } = getState();
        const errors = {};
        errors.date = validateDate(process.date, process.holidays);
        errors.time = validateTime(!errors.date && process.date, process.bidsDue, process.goodUntil, process.timeZone, process.timeZones);
        errors.templates = validateTemplate(process.bidsDue, process.goodUntil, process.templates.current || {});
        const isDateValid = !(errors.date || errors.time);
        const isTemplateValid = !hasTemplateErrors(errors);
        const isValid = !(errors.date || errors.time || !isTemplateValid);

        dispatch({
            type: actionTypes.VALIDATE,
            validation: {
                errors,
                isValid,
                isTemplateValid,
                isDateValid
            }
        })
    }
}

function validateDate(date, holidays) {
    if (date === '') {
        return errorMessages.empty;
    } else if (typeof date === 'undefined') {
        return errorMessages.invalidValue;
    }

    const momentDate = moment(date);

    if (momentDate.diff(new Date(), 'days') < 0) {
        return errorMessages.dateShouldNotBeInPast;
    }

    const weekday = momentDate.isoWeekday();

    if ([6, 7].some(d => d === weekday)) {
        return errorMessages.bwicDateShouldNotBeWeekend;
    }

    if (holidays.some(h => moment(h).isSame(momentDate, 'day'))) {
        return errorMessages.bwicDateShouldNotBeHoliday;
    }
}

function validateTime(date, start, end, timeZoneId, timeZones) {
    const bidsDueUtc = moment.utc(start);
    const goodUntilUtc = moment.utc(end);

    if (bidsDueUtc >= goodUntilUtc) {
        return errorMessages.bidsDueShoulbBeLessThanGoodUntil;
    }

    const timeZone = timeZones.find(t => t.id === timeZoneId)
    const bidsDue = bidsDueUtc.utcOffset(timeZone.utcOffsetMinutes, true);
    const goodUntil = goodUntilUtc.utcOffset(timeZone.utcOffsetMinutes, true);

    if (!appConfig.enableAnyTimeNewBwic) { // for testing purposes
        const { fromTime, toTime } = getBwicAllowedTimeRange(timeZoneId, timeZones);

        if (bidsDue < fromTime || bidsDue > toTime || goodUntil < fromTime || goodUntil > toTime) {
            return errorMessages.bwicTimeInvalidRange(moment(fromTime).format(constants.timeFormat3), moment(toTime).format(constants.timeFormat3), timeZone.abbreviation);
        }
    }

    if (date) {
        const bwicDate = moment(date).utcOffset(timeZone.utcOffsetMinutes, true);
        const now = moment.utc().utcOffset(timeZone.utcOffsetMinutes);

        if (now.isSame(bwicDate, 'day')) {
            const bidsDueDateTime = bidsDue.set({ date: bwicDate.date(), month: bwicDate.month(), year: bwicDate.year() });
            if (bidsDueDateTime < now) {
                return errorMessages.bwicTimeShouldNotBeInPast;
            }
        }
    }

    return undefined;
}

function validateTemplate(start, end, template) {
    const bidsDueTimestamp = dateTimeUtils.timestampFromUtcString(start);
    const goodUntilTimestamp = dateTimeUtils.timestampFromUtcString(end);

    const bidsDue = moment.utc(bidsDueTimestamp, constants.timeFormat);
    const goodUntil = moment.utc(goodUntilTimestamp, constants.timeFormat);

    const bwicDuration = goodUntil.diff(bidsDue, 'minutes');

    return {
        ...validateMinBidIncrementTemplate(template),
        ...validateAutoFeedbackDelay(template, bwicDuration),
        ...validateCutOffReminder(template, bwicDuration),
        ...validateLive(template, bwicDuration),
        ...validateJumpBall(template, bwicDuration),
        ...validateTopX(template, bwicDuration),
        ...validateBestFootForward(template, bwicDuration)
    };
}

function validateLive(template, bwicDurationMinutes) {
    const result = {};

    if (template.type === BwicProcessType.Live) {
        const stage1Max = template.liveBidding.improvementRound
            ? bwicDurationMinutes - template.liveBidding.improvementRound
            : bwicDurationMinutes - constants.openBiddingMinStage2Duration

        result.stage1Deadline = validateNumber(
            template.liveBidding.stage1Deadline, {
            min: constants.openBiddingMinStage1Duration,
            max: Math.max(constants.openBiddingMinStage1Duration, stage1Max)
        });

        if (bwicDurationMinutes < (template.liveBidding.improvementRound + template.liveBidding.stage1Deadline)) {
            result.improvementRound = errorMessages.setMoreThanTotalDuration(bwicDurationMinutes);
            result.stage1Deadline = errorMessages.setMoreThanTotalDuration(bwicDurationMinutes);
        }
        else {
            result.improvementRound = validateNumber(
                template.liveBidding.improvementRound, {
                min: constants.openBiddingMinStage2Duration,
                max: constants.openBiddingMaxStage2Duration
            });
        }

        result.minBidIncrement = validateMinBidIncrement(template.liveBidding.minBidIncrement);
    }

    return result;
}

function validateCutOffReminder(template, bwicDuration) {
    const result = {};

    let processRange = {
        range: {},
        lessThanErrorMessage: '',
        greaterThenErrorMessage: ''
    };

    function getBestFootForwardCutOffReminderRange(stageDuration) {

        const stageDurationMinutes =
            stageDuration > constants.minBwicDuration
                ? stageDuration
                : constants.minBwicDuration

        return processRange = {
            range: {
                min: constants.cutOffReminderRange.min,
                max: stageDurationMinutes - constants.differenceBetweenStageAndCutOffReminder,
            }
        }
    }

    function getRangeWithStage1Deadline(stage1Deadline) {
        const stage1DeadlineMinutes =
            stage1Deadline > constants.minBwicDuration
                ? stage1Deadline
                : constants.minBwicDuration

        return processRange = {
            range: stage1Deadline
                ? {
                    min: constants.cutOffReminderRange.min,
                    max: stage1DeadlineMinutes - constants.differenceBetweenStageAndCutOffReminder,
                }
                : {
                    min: constants.cutOffReminderRange.min,
                    max: constants.cutOffReminderRange.max,
                }
        }
    }

    switch (template.type) {
        case BwicProcessType.JumpBall: getRangeWithStage1Deadline(dateTimeUtils.parseTimeSpan(template.jumpBall.automaticStage1Deadline).totalMinutes); break;
        case BwicProcessType.TopX: getRangeWithStage1Deadline(dateTimeUtils.parseTimeSpan(template.topX.automaticStage1Deadline).totalMinutes); break;
        case BwicProcessType.Live: getRangeWithStage1Deadline(template.liveBidding.stage1Deadline); break;
        case BwicProcessType.BestFootForward: getBestFootForwardCutOffReminderRange(
            dateTimeUtils.parseTimeSpan(template.bff.stage1Deadline).totalMinutes,
            dateTimeUtils.parseTimeSpan(template.cutOffReminder).totalMinutes); break;
        default: processRange = { range: constants.cutOffReminderRange };
    }
    result.cutOffReminder = validateNumber(
        dateTimeUtils.parseTimeSpan(template.cutOffReminder).totalMinutes,
        processRange.range,
        processRange.lessThanErrorMessage,
        processRange.greaterThenErrorMessage
    );

    return result
}
function validateAutoFeedbackDelay(template, bwicDuration) {
    const result = {};

    if (template.autoFeedbackDelay != null) {
        result.autoFeedbackDelay = validateNumber(
            dateTimeUtils.parseTimeSpan(template.autoFeedbackDelay).totalMinutes, {
            min: constants.autoFeedbackDelayRange.min,
            max: Math.min(constants.autoFeedbackDelayRange.max, bwicDuration)
        }
        );
    }
    return result;
}
function validateMinBidIncrementTemplate(template) {
    const result = {};

    if (template.minBidIncrement != null) {
        result.minBidIncrement = validateNumber(
            template.minBidIncrement, {
            min: constants.minBidIncrementRange.min,
            max: constants.minBidIncrementRange.max
        }
        );
    }
    return result;
}

function validateJumpBall(template, bwicDuration) {
    const result = {};

    if (template.type === BwicProcessType.JumpBall) {
        const settings = template.jumpBall ?? {};

        if (settings.automaticStage1Deadline != null) {
            result.jumpBallStage1AutomaticMinutes = validateNumber(
                dateTimeUtils.parseTimeSpan(settings.automaticStage1Deadline).totalMinutes,
                constants.jumpBall.stage1EndAutoRange
            );
        }

        if (settings.improvementRound != null) {
            result.jumpBallStage2DurationMinutes = validateNumber(
                dateTimeUtils.parseTimeSpan(settings.improvementRound).totalMinutes,
                constants.jumpBall.stage2DurationRange
            );
        }

        const stagesDuration = (
            dateTimeUtils.parseTimeSpan(settings.automaticStage1Deadline).totalMinutes + dateTimeUtils.parseTimeSpan(settings.improvementRound).totalMinutes);
        if (stagesDuration > 0 && bwicDuration < stagesDuration && !values(result).some(r => r)) {
            result.jumpBallStage1AutomaticMinutes = result.jumpBallStage2DurationMinutes =
                `Total of first and second stages should not be greater than ${bwicDuration}`;
        }

    }

    return result;
}

function validateTopX(template, bwicDuration) {
    const result = {};

    if (template.type === BwicProcessType.TopX) {
        const settings = template.topX ?? {};

        if (settings.automaticStage1Deadline != null) {
            result.topXStage1AutomaticMinutes = validateNumber(
                dateTimeUtils.parseTimeSpan(settings.automaticStage1Deadline).totalMinutes,
                constants.topX.stage1EndAutoRange
            );
        }

        if (settings.improvementRound != null) {
            result.topXStage2DurationMinutes = validateNumber(
                dateTimeUtils.parseTimeSpan(settings.improvementRound).totalMinutes,
                constants.topX.stage2DurationRange
            );
        }

        const stagesDuration = (
            dateTimeUtils.parseTimeSpan(settings.automaticStage1Deadline).totalMinutes + dateTimeUtils.parseTimeSpan(settings.improvementRound).totalMinutes);
        if (stagesDuration > 0 && bwicDuration < stagesDuration && !values(result).some(r => r)) {
            result.topXStage1AutomaticMinutes = result.topXStage2DurationMinutes =
                `Total of first and second stages should not be greater than ${bwicDuration}`;
        }

    }

    return result;
}

function validateBestFootForward(template, bwicDuration) {
    const result = {};

    if (template.type === BwicProcessType.BestFootForward) {
        const settings = template.bff ?? {};

        if (settings.stage1Deadline != null) {
            result.bestFootForwardDurationMinutes = validateNumber(
                dateTimeUtils.parseTimeSpan(settings.stage1Deadline).totalMinutes,
                constants.bestFootForward.stage1DeadlineRange
            );
        }

        if (dateTimeUtils.parseTimeSpan(settings.stage1Deadline).totalMinutes > 0 &&
            bwicDuration < dateTimeUtils.parseTimeSpan(settings.stage1Deadline).totalMinutes &&
            !values(result).some(r => r)) {
            result.bestFootForwardDurationMinutes = `Bidding period should not be greater than ${bwicDuration}`;
        }
    }

    return result;
}

function validateNumber(value, range, lessThanErrorMessage = '', greaterThenErrorMessage = '') {
    if (!value || !String(value).trim()) {
        return errorMessages.empty;
    } else if (!numericUtils.isNumber(value)) {
        return errorMessages.invalidValue;
    } else if (+value < range.min && lessThanErrorMessage) {
        return lessThanErrorMessage;
    } else if (+value > range.max && greaterThenErrorMessage) {
        return greaterThenErrorMessage;
    } else if (+value < range.min || +value > range.max) {
        return errorMessages.invalidRange(range.min, range.max);
    }
    return '';
}

function validateMinBidIncrement(minBidIncrement) {
    return validateNumber(minBidIncrement, { min: constants.openBiddingMinBidIncrement, max: constants.openBiddingMaxBidIncrement });
}

function addTemplates(templates) {
    return {
        type: actionTypes.ADD_TEMPLATES,
        templates: templates.map(t => ({ // add defaults
            ...t,
            cutOffReminder: dateTimeUtils.timeSpan(0, constants.cutOffReminderRangeDefaultMinutes),
            autoFeedbackDelay: dateTimeUtils.timeSpan(0, constants.autoFeedbackDelayDefaultMinutes),

            //Live Bidding
            liveBidding: {
                levelSpecificationType: t.levelSpecificationType || LevelSpecificationType.best,
                openBiddingImproverCount: t.openBiddingImproverCount || OpenBiddingImproverCount.top3,
                stage1Deadline: t.stage1Deadline || constants.openBiddingDefaultStage1Deadline,
                improvementRound: t.improvementRound || constants.openBiddingMinStage2Duration,
                minBidIncrement: formatUtils.formatBid(t.minBidIncrement || constants.defaultMinBidIncrement),
            },

            // JumpBall
            jumpBall: {
                improverCount: JumpBallStage2Participants.Top3,
                improvementRound: dateTimeUtils.timeSpan(0, constants.jumpBall.stage2DurationDefaultMinutes),
                automaticStage1Deadline: dateTimeUtils.timeSpan(0, constants.jumpBall.stage1EndAutoDefaultMinutes),
            },

            // TopX
            topX: {
                improverCount: TopXStage2Participants.Top3,
                improvementRound: dateTimeUtils.timeSpan(0, constants.topX.stage2DurationDefaultMinutes),
                automaticStage1Deadline: dateTimeUtils.timeSpan(0, constants.topX.stage1EndAutoDefaultMinutes),
            },
            // BestFootForward
            bff: {
                stage1Deadline: dateTimeUtils.timeSpan(0, constants.bestFootForward.stage1DeadlineDefaultMinutes),
            }
        }))
    };
}

function selectTemplate(index) {
    return (dispatch, getState) => {
        const { isValid } = getState().process;

        dispatch({
            type: actionTypes.SELECT_TEMPLATE,
            index
        });

        if (!isValid) {
            dispatch(validate());
        }
    };
}

function reserveLevelsApplyEnabled(enabled) {
    return dispatch => {
        dispatch({ type: actionTypes.RESERVE_LEVELS_APPLY_ENABLED, enabled });
        dispatch(updateTemplateDirty());
    };
}

function earlyTradesEnabled(enabled) {
    return dispatch => {
        dispatch({ type: actionTypes.EARLY_TRADES_ENABLED, enabled });
        dispatch(updateTemplateDirty());
    };
}

function rulesText(text) {
    return dispatch => {
        dispatch({ type: actionTypes.RULES_TEXT, text });
        dispatch(updateTemplateDirty());
    }
}

function setStage1Deadline(value) {
    return (dispatch, getState) => {
        const {
            cutOffReminder,
            stage1Deadline
        } = getState().process.errors.templates;

        dispatch({ type: actionTypes.SET_STAGE1_DEADLINE, payload: { value } });
        dispatch(updateTemplateDirty());

        if (cutOffReminder || stage1Deadline) {
            dispatch(validate());
        }
    }
}

function setImprovementRound(value) {
    return dispatch => {
        dispatch({ type: actionTypes.SET_IMPROVEMENT_ROUND, payload: { value } });
        dispatch(updateTemplateDirty());
    }
}

function setMinBidIncrement(value) {
    return dispatch => {
        dispatch({ type: actionTypes.SET_MIN_BID_INCREMENT, payload: { value } });
        dispatch(updateTemplateDirty());
    }
}

function setLevelSpecificationType(value) {
    return dispatch => {
        dispatch({ type: actionTypes.SET_LEVEL_SPECIFICATION_TYPE, payload: { value } });
        dispatch(updateTemplateDirty());
    }
}

function setOpenBiddingImproverCount(value) {
    return dispatch => {
        dispatch({ type: actionTypes.SET_OPEN_BIDDING_IMPROVER_COUNT, payload: { value } });
        dispatch(updateTemplateDirty());
    }
}

function resetStage1DeadlineError() {
    return (dispatch, getState) => {
        const { errors = {} } = getState().process;
        if (errors.templates.stage1Deadline) {
            dispatch(validate());
        }

    };
}

function resetImprovementRoundError() {
    return (dispatch, getState) => {
        const { errors = {} } = getState().process;
        if (errors.templates.improvementRound) {
            dispatch(validate());
        }

    };
}

function resetMinBidIncrementError() {
    return (dispatch, getState) => {
        const { errors = {} } = getState().process;
        if (errors.templates.minBidIncrement) {
            dispatch(validate());
        }
    };
}

function resetJumpBallStage2DurationMinutes() {
    return (dispatch, getState) => {
        const { errors = {} } = getState().process;
        if (errors.templates.jumpBallStage2DurationMinutes) {
            dispatch(validate());
        }

    };
}

function resetTopXStage1AutomaticMinutes() {
    return (dispatch, getState) => {
        const { errors = {} } = getState().process;
        if (errors.templates.topXStage1AutomaticMinutes) {
            dispatch(validate());
        }

    };
}

function resetTopXStage2DurationMinutes() {
    return (dispatch, getState) => {
        const { errors = {} } = getState().process;
        if (errors.templates.topXStage2DurationMinutes) {
            dispatch(validate());
        }

    };
}

function resetJumpBallStage1AutomaticMinutes() {
    return (dispatch, getState) => {
        const { errors = {} } = getState().process;
        if (errors.templates.jumpBallStage1AutomaticMinutes) {
            dispatch(validate());
        }

    };
}

function updateTemplateDirty() {
    return (dispatch, getState) => {
        const { all, current } = getState().process.templates;
        const original = all.find(t => t.id === current.id);
        const dirty = !isEqual(original, current)

        dispatch({
            type: actionTypes.SET_TEMPLATE_DIRTY_STATE,
            dirty
        })
    }
}

function titleChange(title) {
    return { type: actionTypes.TITLE_CHANGE, title };
}

function descriptionChange(description) {
    return { type: actionTypes.DESCRIPTION_CHANGE, description };
}

function resetChanges() {
    return dispatch => {
        dispatch({ type: actionTypes.RESET_CHANGES });
        dispatch(validate());
    }
}

function reset() {
    return { type: actionTypes.RESET };
}

function jumpBallStage1EndManual() {
    return dispatch => {
        dispatch({ type: actionTypes.JUMP_BALL_STAGE_1_END_MANUAL });
        dispatch({ type: actionTypes.RESET_CUT_OFF_REMINDER_MINS });
        dispatch(updateTemplateDirty());
        dispatch(validate());
    }
}

function autoFeedbackDelayChange(minutes) {
    return (dispatch, getState) => {
        const { autoFeedbackDelay } = getState().process.errors.templates;

        dispatch({ type: actionTypes.AUTO_FEEDBACK_DELAY_MINS, payload: { minutes } });
        dispatch(updateTemplateDirty());

        if (autoFeedbackDelay) {
            dispatch(validate());
        }
    };
}

function minBidIncrementChange(value) {
    return (dispatch, getState) => {
        const { minBidIncrement } = getState().process.errors.templates;

        dispatch({ type: actionTypes.MIN_BID_INCREMENT_CHANGED, payload: { value } });
        dispatch(updateTemplateDirty());

        if (minBidIncrement) {
            dispatch(validate());
        }
    };
}

function autoFeedbackDelayEnabled() {
    return dispatch => {
        dispatch({ type: actionTypes.AUTO_FEEDBACK_DELAY_ENABLED });
        dispatch(updateTemplateDirty());
    }

}

function autoFeedbackDelayDisabled() {
    return dispatch => {
        dispatch({ type: actionTypes.AUTO_FEEDBACK_DELAY_DISABLED });
        dispatch(resetAutoFeedbackDelay());
        dispatch(updateTemplateDirty());
    }
}

function minBidIncrementEnabled() {
    return dispatch => {
        dispatch({ type: actionTypes.MIN_BID_INCREMENT_ENABLED });
        dispatch(updateTemplateDirty());
    }

}

function minBidIncrementDisabled() {
    return dispatch => {
        dispatch({ type: actionTypes.MIN_BID_INCREMENT_DISABLED });
        dispatch(resetMinimumBidIncrement());
        dispatch(updateTemplateDirty());
    }
}

function cutOffReminderChange(minutes) {
    return (dispatch, getState) => {
        const {
            cutOffReminder,
            jumpBallStage1AutomaticMinutes,
            topXStage1AutomaticMinutes,
            bestFootForwardDurationMinutes
        } = getState().process.errors.templates;

        dispatch({ type: actionTypes.CUT_OFF_REMINDER_MINS, payload: { minutes } });
        dispatch(updateTemplateDirty());

        if (cutOffReminder ||
            jumpBallStage1AutomaticMinutes ||
            topXStage1AutomaticMinutes ||
            bestFootForwardDurationMinutes) {
            dispatch(validate());
        }
    };
}

function jumpBallStage1EndAuto() {
    return dispatch => {
        dispatch({ type: actionTypes.JUMP_BALL_STAGE_1_END_AUTO });
        dispatch(updateTemplateDirty());
    }
}


function jumpBallStage1EndAutomaticMinutesChange(minutes) {
    return (dispatch, getState) => {
        const { jumpBallStage1AutomaticMinutes, cutOffReminder, jumpBallStage2DurationMinutes } = getState().process.errors.templates;
        dispatch({ type: actionTypes.JUMP_BALL_STAGE_1_END_AUTO_MINS, payload: { minutes } });
        dispatch(updateTemplateDirty());

        if (jumpBallStage1AutomaticMinutes || cutOffReminder || jumpBallStage2DurationMinutes) {
            dispatch(validate());
        }
    }
}

function jumpBallStage2ParticipantsChange(participants) {
    return dispatch => {
        dispatch({ type: actionTypes.JUMP_BALL_STAGE_2_PARTICIPANTS_CHANGE, payload: { participants } });
        dispatch(updateTemplateDirty());
    }
}

function jumpBallStage2DurationUnspecified() {
    return dispatch => {
        dispatch({ type: actionTypes.JUMP_BALL_STAGE_2_DURATION_UNSPECIFIED });
        dispatch(updateTemplateDirty());
    }
}

function jumpBallStage2DurationPreset() {
    return dispatch => {
        dispatch({ type: actionTypes.JUMP_BALL_STAGE_2_DURATION_PRESET });
        dispatch(updateTemplateDirty());
    }
}

function jumpBallStage2DurationMinutesChange(minutes) {
    return (dispatch, getState) => {
        const { jumpBallStage2DurationMinutes, jumpBallStage1AutomaticMinutes } = getState().process.errors.templates;

        dispatch({ type: actionTypes.JUMP_BALL_STAGE_2_DURATION_MINS, payload: { minutes } });
        dispatch(updateTemplateDirty());

        if (jumpBallStage2DurationMinutes || jumpBallStage1AutomaticMinutes) {
            dispatch(validate());
        }
    }
}

function resetAutoFeedbackDelay() {
    return (dispatch, getState) => {
        const { errors = {} } = getState().process;
        if (errors.templates.autoFeedbackDelay) {
            dispatch(validate());
        }

    };
}

function resetMinimumBidIncrement() {
    return (dispatch, getState) => {
        const { errors = {} } = getState().process;
        if (errors.templates.minBidIncrement) {
            dispatch(validate());
        }

    };
}


function topXStage1EndManual() {
    return dispatch => {
        dispatch({ type: actionTypes.TOP_X_STAGE_1_END_MANUAL });
        dispatch({ type: actionTypes.RESET_CUT_OFF_REMINDER_MINS });
        dispatch(updateTemplateDirty());
        dispatch(validate());
    }
}

function topXStage1EndAuto() {
    return dispatch => {
        dispatch({ type: actionTypes.TOP_X_STAGE_1_END_AUTO });
        dispatch(updateTemplateDirty());
    }
}

function topXStage1EndAutomaticMinutesChange(minutes) {
    return (dispatch, getState) => {
        const { topXStage1AutomaticMinutes, cutOffReminder, topXStage2DurationMinutes } = getState().process.errors.templates;
        dispatch({ type: actionTypes.TOP_X_STAGE_1_END_AUTO_MINS, payload: { minutes } });
        dispatch(updateTemplateDirty());

        if (topXStage1AutomaticMinutes || cutOffReminder || topXStage2DurationMinutes) {
            dispatch(validate());
        }
    }
}

function topXStage2ParticipantsChange(participants) {
    return dispatch => {
        dispatch({ type: actionTypes.TOP_X_STAGE_2_PARTICIPANTS_CHANGE, payload: { participants } });
        dispatch(updateTemplateDirty());
    }
}

function topXStage2DurationUnspecified() {
    return dispatch => {
        dispatch({ type: actionTypes.TOP_X_STAGE_2_DURATION_UNSPECIFIED });
        dispatch(updateTemplateDirty());
    }
}

function topXStage2DurationPreset() {
    return dispatch => {
        dispatch({ type: actionTypes.TOP_X_STAGE_2_DURATION_PRESET });
        dispatch(updateTemplateDirty());
    }
}

function topXStage2DurationMinutesChange(minutes) {
    return (dispatch, getState) => {
        const { topXStage2DurationMinutes, topXStage1AutomaticMinutes } = getState().process.errors.templates;

        dispatch({ type: actionTypes.TOP_X_STAGE_2_DURATION_MINS, payload: { minutes } });
        dispatch(updateTemplateDirty());

        if (topXStage2DurationMinutes || topXStage1AutomaticMinutes) {
            dispatch(validate());
        }
    }
}



function bestFootForwardDurationMinutesChange(minutes) {
    return (dispatch, getState) => {
        const { cutOffReminder, bestFootForwardDurationMinutes } = getState().process.errors.templates;

        dispatch({ type: actionTypes.BEST_FOOT_FORWARD_DURATION_MINS, payload: { minutes } });
        dispatch(updateTemplateDirty());

        if (cutOffReminder || bestFootForwardDurationMinutes) {
            dispatch(validate());
        }
    }


}
function resetBestFootForwardDurationMinutes() {
    return (dispatch, getState) => {
        const { errors = {} } = getState().process;
        if (errors.templates.bestFootForwardDurationMinutes) {
            dispatch(validate());
        }

    };
}

function setAllToAllProtocol(isAllToAll) {
    return dispatch => {
        dispatch({ type: actionTypes.SET_ALL_TO_ALL_PROTOCOL, payload: isAllToAll });
    }
}

