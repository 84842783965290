import { store } from '../store';
import {
    gridActions as actionTypes,
    uploadStatus,
    constants,
    errorMessages,
} from "../constants";
import { arrayUtils, numericUtils, moneyUtils, formatUtils, stringUtils } from '../utils';
import { gridColumns as columns } from '../constants';
import * as compareUtils from '../utils/compare.utils';
import { changeColorActions, changePxTalkActions, errorActions } from './';
import { Ratings } from '../types/enums/Rating';
import { requestCancelationController } from '../services/request-cancelation-controller';
import { history } from '../history';

export const gridActions = {
    init,
    setColumns,
    addColumn,
    removeColumn,
    moveColumnData,
    clearCell,
    setFocus,
    edit,
    cancelEdit,
    setInitialPosition,
    applyEdit,
    applyOrCancelEdit,
    orderBy,
    deleteRow,
    deleteDataItemsWithErrors,
    deleteFlaggedDataItems,
    editing,
    addDataItems,
    replaceDataItem,
    addFileToDataItem,
    insertDataItems,
    processKeyCode,
    selectRow,
    showHelp,
    hideHelp,
    validate,
    setUploadState,
    reset,
    blockInput,
    dataProcessing,
    parseValue,
    addRow,
    moveRowUp,
    moveRowDown,
    clear,
    updateDataItemDraftStatus,
    updateRowsLimit
}

function init(dataItems, headers, rowsLimit = constants.gridRowsLimit, minDrafts, autoFocus = true) {
    return dispatch => {
        dispatch(setMountedKey());
        dispatch(setColumns(headers));
        dispatch(updateRowsLimit(rowsLimit, minDrafts));

        if (dataItems && dataItems.length) {
            dispatch(insertDataItems(dataItems));
        }

        dispatch(appendDrafts());

        if (autoFocus) {
            dispatch(setInitialPosition(dataItems && dataItems.length));
        }

        dispatch(showHelp());
    }
}

function updateRowsLimit(rowsLimit, minDrafts) {
    return {
        type: actionTypes.ROWS_LIMIT,
        rowsLimit,
        minDrafts
    };
}

function setMountedKey() {
    return {
        type: actionTypes.GRID_SET_MOUNTED_KEY,
        payload: { key: new Date().getTime() }
    }
}

function setColumns(headers) {
    return {
        type: actionTypes.SETUP_HEADERS,
        headers
    };
}

function addColumn(column, positionIndex) {
    return {
        type: actionTypes.ADD_COLUMN,
        column,
        positionIndex
    };
}

function removeColumn(name) {
    return {
        type: actionTypes.REMOVE_COLUMN,
        name
    }
}

function replaceDataItem(dataItem, index) {
    return {
        type: actionTypes.REPLACE_DATA_ITEM,
        payload: {
            dataItem,
            index
        }
    };
};

function dataItemsLimitError(rowsLimit) {
    return errorActions.error(
        null,
        errorMessages.gridDataItemsLimitExceeded(rowsLimit || constants.gridRowsLimit),
        errorMessages.gridDataItemsLimitExceededTitle
    );
}

function addDataItems(newItems) {
    return (dispatch, getState) => {
        const { dataItems = [], rowsLimit } = getState().grid;
        const currentItems = dataItems.filter(item => !item.draft);

        if (currentItems.length + newItems.length > rowsLimit) {
            return dispatch(dataItemsLimitError(rowsLimit));
        }

        dispatch({
            type: actionTypes.ADD_DATA_ITEMS,
            payload: {
                currentItems,
                newItems
            }
        })
    };
}

function moveColumnData(fromColumnName, toColumnName) {
    return dispatch => {
        dispatch(applyOrCancelEdit());
        dispatch({
            type: actionTypes.MOVE_COLUMN_DATA,
            fromColumnName,
            toColumnName
        });
    }
}

function insertDataItems(newItems, index = 0) {
    return (dispatch, getState) => {
        const { dataItems, rowsLimit } = getState().grid;
        const currentItems = dataItems.filter(item => !item.draft);

        if ((currentItems.length + newItems.length) > rowsLimit) {
            return dispatch(dataItemsLimitError(rowsLimit));
        }

        dispatch({
            type: actionTypes.INSERT_DATA_ITEMS,
            payload: {
                newItems,
                index
            }
        });
    };
}

function appendDataItems(dataItems) {
    return dispatch => {
        dispatch(hideHelp());
        dispatch({ type: actionTypes.APPEND_DATA_ITEMS, dataItems });
    }
}

function addRow(index) {
    return (dispatch, getState) => {
        const { headers } = getState().grid;
        const empty = createDraftDataItem(headers);

        dispatch(applyOrCancelEdit());
        dispatch(insertDataItems([empty], index));
    };
}

function moveRowUp(index) {
    return dispatch => {
        const newIndex = index - 1;

        if (newIndex >= 0) {
            dispatch(applyOrCancelEdit());

            dispatch({
                type: actionTypes.CHANGE_ROW_ORDER,
                payload: {
                    index,
                    newIndex
                }
            });
        }
    };
}

function moveRowDown(index) {
    return (dispatch, getState) => {
        const newIndex = index + 1;
        if (newIndex < getState().grid.rowsLimit) {
            dispatch(applyOrCancelEdit());

            dispatch({
                type: actionTypes.CHANGE_ROW_ORDER,
                payload: { index, newIndex }
            });
        }
    };
}

function clearCell() {
    return (dispatch, getState) => {
        const { position, headers, dataItems } = getState().grid;
        const header = headers.find(h => h.name === position.columnName);
        const dataItem = dataItems[position.index];

        if (canEdit(header, dataItem)) {
            if (header.type === 'file') {
                dispatch(addFileToDataItem(null, position));
                requestCancelationController.abort(history.location.pathname);
            }

            dispatch({
                type: actionTypes.POSITION_CHANGE,
                position: { ...position, editing: true, editingValue: header.initialValue || '' }
            });
        }
    };
}

function setFocus(index, columnName) {
    return (dispatch, getState) => {
        const { position } = getState().grid;

        if (index !== position.index || columnName !== position.columnName) {
            if (position.editing) {
                dispatch(applyOrCancelEdit());
            }

            dispatch(hideHelp());
            dispatch({
                type: actionTypes.POSITION_CHANGE,
                position: { index, columnName }
            });
        }
    }
}

function edit() {
    return (dispatch, getState) => {
        const { position, dataItems, headers } = getState().grid;
        const header = headers.find(h => h.name === position.columnName);
        const dataItem = dataItems[position.index];

        if (canEdit(header, dataItem)) {
            const value = dataItem[position.columnName];
            const error = dataItem.errors && dataItem.errors.find(e => e.columnName === position.columnName);

            dispatch({
                type: actionTypes.POSITION_CHANGE,
                position: {
                    ...position,
                    editing: true,
                    editingValue: value,
                    editingError: error && error.message
                }
            });

            if (header.pattern) {
                const normalized = parseValue(String(value), header);
                if (header.pattern && !header.pattern.test(normalized)) {
                    dispatch(editing(header.defaultValue || ''));
                }
            }

            if (position.columnName === columns.pxTalks.name) {
                dispatch(blockInput(true));
                dispatch(changePxTalkActions.init('', dataItem.id, dataItem[columns.ticker.name], value));
            } else if (position.columnName === columns.color.name) {
                dispatch(blockInput(true));
                dispatch(changeColorActions.init(dataItem[columns.ticker.name], value));
            }
        }
    };
}

function canEdit(header, dataItem) {
    if (typeof header.readonlyCallback === 'function') {
        return !header.readonlyCallback(dataItem);
    }

    return !header.readonly;
}

function moveUp() {
    return (dispatch, getState) => {
        const { position } = getState().grid;
        const index = position.index;

        if (index > 0) {
            dispatch({
                type: actionTypes.POSITION_CHANGE,
                position: { index: index - 1, columnName: position.columnName }
            });
        }
    };
}

function moveDown() {
    return (dispatch, getState) => {
        const { dataItems, position } = getState().grid;
        const index = position.index;

        if (index < dataItems.length - 1) {
            dispatch({
                type: actionTypes.POSITION_CHANGE,
                position: { index: index + 1, columnName: position.columnName }
            });
        }
    };
}

function moveLeft(moveNextRow) {
    return (dispatch, getState) => {
        let { headers, position } = getState().grid;
        const index = headers.findIndex(h => h.name === position.columnName);

        if (index > 0) {
            dispatch({
                type: actionTypes.POSITION_CHANGE,
                position: { index: position.index, columnName: headers[index - 1].name }
            });
        } else if (moveNextRow && position.index > 0) {
            dispatch(moveUp());
            let { headers, position } = getState().grid;
            dispatch({
                type: actionTypes.POSITION_CHANGE,
                position: { index: position.index, columnName: headers[headers.length - 1].name }
            });
        }
    };
}

function moveRight(moveNextRow) {
    return (dispatch, getState) => {
        let { headers, position } = getState().grid;
        const index = headers.findIndex(h => h.name === position.columnName);

        if (index < headers.length - 1) {
            dispatch({
                type: actionTypes.POSITION_CHANGE,
                position: { index: position.index, columnName: headers[index + 1].name }
            });
        } else if (moveNextRow) {
            dispatch(moveDown());
            let { headers, position } = getState().grid;
            dispatch({
                type: actionTypes.POSITION_CHANGE,
                position: { index: position.index, columnName: headers[0].name }
            });

        }
    };
}

function cancelEdit() {
    return (dispatch, getState) => {
        const { position } = getState().grid;

        dispatch({
            type: actionTypes.POSITION_CHANGE,
            position: { index: position.index, columnName: position.columnName }
        });
    }
}

function setInitialPosition(rowIndex = 0, headerIndex = 0) {
    return (dispatch, getState) => {
        const { headers } = getState().grid;

        dispatch({
            type: actionTypes.POSITION_CHANGE,
            position: { index: rowIndex, columnName: headers[headerIndex].name }
        });
    };
}

function clear() {
    return dispatch => {
        dispatch(applyOrCancelEdit());
        dispatch({ type: actionTypes.CLEAR });
        dispatch(appendDrafts());
        dispatch(setInitialPosition());
        dispatch(validate());
    };
}

function updateDataItemDraftStatus(rowIndex) {
    return (dispatch, getState) => {
        const { dataItems, headers } = getState().grid;
        const dataItem = dataItems[rowIndex];

        if (dataItem) {
            const draft = headers
                .every(h => {
                    const value = dataItem[h.name];

                    if (Array.isArray(h.initialValue)) {
                        return Array.isArray(value) && value.length === 0;
                    }

                    return (value ?? '') === (h.initialValue ?? '');
                });

            dispatch({
                type: actionTypes.UPDATE_DRAFT,
                rowIndex,
                draft
            });
        }
    }

}

function applyEdit() {
    return (dispatch, getState) => {
        const { dataItems, position, headers } = getState().grid;

        if (!position || !position.editing) {
            return;
        }

        const editingHeader = headers.find(h => h.name === position.columnName);
        const dataItem = dataItems[position.index];
        const editingValue = parseValue(editingHeader.type === 'file' ? dataItem[editingHeader.name] : position.editingValue, editingHeader);
        let errors = (dataItem.errors && dataItem.errors.filter(e => e.columnName !== position.columnName)) || [];

        if (position.editingError) {
            errors.push({
                columnName: position.columnName,
                message: position.editingError
            });
        }

        dispatch({ type: actionTypes.APPLY_EDIT, editingValue, errors });
        dispatch(updateDataItemDraftStatus(position.index));
        dispatch(appendDrafts());

        const hasColumnDependencies = typeof editingHeader.updateDependencyColumnsCallback === 'function';
        if (hasColumnDependencies) {
            dispatch(editingHeader.updateDependencyColumnsCallback(position.index));
        }

        if (((editingHeader.unique || hasColumnDependencies) &&
            dataItem.errors?.find(e => e.columnName === position.columnName)) || editingHeader.headerError) {
            dispatch(validate());
        }
    }
}

function applyOrCancelEdit() {
    return (dispatch, getState) => {
        const { position, headers } = getState().grid;
        const header = headers.find(c => c.name === position.columnName);

        if (position.editing) {
            if (header.cancelOnLeave) {
                dispatch(cancelEdit());
            } else {
                dispatch(applyEdit());
            }
        }
    };
}

function parseValue(value, column) {
    if (typeof column.parse === 'function') {
        return column.parse(value) || value;
    } else if (column.type === 'number' || column.type === 'integer') {
        if (value === '') {
            return undefined;
        }
        return numericUtils.numberOrDefault(value, value === undefined ? null : value);
    } else if (column.type === 'money') {
        return moneyUtils.parse(String(value)) || value;
    }

    return typeof value === 'string'
        ? value.trim()
        : value;
}

function orderBy(targetColumnName) {
    return (dispatch, getState) => {
        if (getState().grid.position.editing) {
            dispatch(applyOrCancelEdit());
        }

        const { dataItems, orderBy, position, headers } = getState().grid;

        const orderByUpdate = {
            columnName: targetColumnName,
            direction: orderBy && orderBy.columnName === targetColumnName
                ? !orderBy.direction || orderBy.direction === 'asc' ? 'desc' : 'asc'
                : 'asc'
        };

        const { columnName, direction } = orderByUpdate;

        const column = headers.find(x => x.name === columnName);

        const isNumber = column.type === 'number' || column.type === 'integer';
        const isMoney = column.type === 'money';

        const nonDrafts = dataItems.filter(i => !i.draft);
        const drafts = dataItems.filter(i => i.draft);
        const allSorted = [...getSortedDataItems(nonDrafts), ...drafts];

        dispatch({
            type: actionTypes.ORDER_BY,
            ordering: { orderBy: orderByUpdate, dataItems: allSorted }
        });

        dispatch({
            type: actionTypes.POSITION_CHANGE,
            position: { ...position, index: 0, columnName: headers[0].name }
        });

        function getSortedDataItems(dataItems) {
            if (isNumber || isMoney) {
                return dataItems.sort(compareNumbers)
            } else if (column.name === columns.pxTalks.name) {
                return dataItems.sort(comparePxTalks);
            } else if (column.name === columns.color.name) {
                return dataItems.sort(compareColors);
            } else if (column.name === columns.rating.name) {
                return dataItems.sort(compareRatings)
            }
            return dataItems.sort(compareStrings);
        }

        function compareNumbers(a, b) {
            const valueA = a[columnName];
            const valueB = b[columnName];

            return direction === 'asc'
                ? compareUtils.compareNumbersWithNullAcs(valueA, valueB)
                : compareUtils.compareNumbersWithNullAcs(valueB, valueA);
        }

        function compareStrings(a, b) {
            const valueA = a[columnName];
            const valueB = b[columnName];

            return direction === 'asc'
                ? compareUtils.compareStrings(valueA, valueB)
                : compareUtils.compareStrings(valueB, valueA);
        }

        function comparePxTalks(a, b) {
            const valueA = a[columnName];
            const valueB = b[columnName];

            if (direction === 'asc') {
                const numberA = valueA && valueA.length ? Math.min(...valueA.map(p => p.normalizedLevel)) : Number.MAX_VALUE;
                const numberB = valueB && valueB.length ? Math.min(...valueB.map(p => p.normalizedLevel)) : Number.MAX_VALUE;

                return numberA - numberB;
            } else {
                const numberA = valueA && valueA.length ? Math.max(...valueA.map(p => p.normalizedLevel)) : -1;
                const numberB = valueB && valueB.length ? Math.max(...valueB.map(p => p.normalizedLevel)) : -1;

                return numberB - numberA;
            }
        }

        function compareColors(a, b) {
            const valueA = a[columnName] ? formatUtils.formatColor(a[columnName]) : '';
            const valueB = b[columnName] ? formatUtils.formatColor(b[columnName]) : '';

            if (direction === 'asc') {
                if (valueA && valueB) {
                    return valueA.localeCompare(valueB);
                } else if (valueA && !valueB) {
                    return Number.MAX_VALUE;
                } else {
                    return Number.MIN_VALUE;
                }
            }

            return valueB.localeCompare(valueA);
        }

        function compareRatings(a, b) {
            const valueA = a[columnName];
            const valueB = b[columnName];

            if (!Ratings.includes(valueA)) {
                return 1;
            }
            if (!Ratings.includes(valueB)) {
                return -1;
            }
            if (direction === 'asc') {
                return compareUtils.compareRating(valueA, valueB);
            }
            return compareUtils.compareRating(valueB, valueA);
        }
    }
}

function deleteRow(index) {
    return (dispatch, getState) => {

        const { position, dataItems, headers } = getState().grid;
        const dataItem = dataItems[index];

        if (position &&
            position.index > index &&
            position.editing) {
            dispatch({
                type: actionTypes.POSITION_CHANGE,
                position: { ...position, index: position.index - 1 }
            });
        }

        dispatch({
            type: actionTypes.DELETE_ROW,
            index
        });

        const empty = createDraftDataItem(headers);
        dispatch(appendDataItems([empty]));

        if (dataItem.errors &&
            dataItem.errors.some(e => headers.some(h => h.unique && h.name === e.columnName))) {
            dispatch(validate());
        }
    };
}

function addFileToDataItem(file, position) {
    return (dispatch, getState) => {
        const { dataItems } = getState().grid;

        const editingDataItem = dataItems[position.index];

        dispatch(replaceDataItem({...editingDataItem, [position.columnName]: file}, position.index));
        dispatch(applyEdit());
    }
}


function editing(value) {
    return (dispatch, getState) => {
        const { position, dataItems, headers } = getState().grid;

        const editingDataItem = dataItems[position.index];
        const error = editingDataItem.errors && editingDataItem.errors.find(e => e.columnName === position.columnName);

        let errorMessage = '';
        if (error) { //invalidated
            const column = headers.find(h => h.name === position.columnName);
            errorMessage =
                value === editingDataItem[position.columnName]
                    ? error.message
                    : validatePropertyValue(value, column, editingDataItem, dataItems).title;
        }

        dispatch({
            type: actionTypes.EDITING,
            editing: { value, errorMessage }
        });
    }
}

function createDraftDataItem(headers) {
    const draft = { draft: true };
    for (const header of headers) {
        draft[header.name] = header.initialValue;
    }

    return draft;
}

function processKeyCode(keyCode, ctrl, meta, shift, deleteDisabled) {
    return (dispatch, getState) => {
        const { position, selection, dataItems, inputBlocked, headers } = getState().grid;

        if (inputBlocked) {
            return;
        }

        const isMovement = !shift && ((keyCode >= 37 && keyCode <= 40) || keyCode === 9);
        const moveUpOrDown = isMovement && (keyCode === 38 || keyCode === 40);
        const isEnteringValue = !ctrl && !meta && keyCode >= 48 && keyCode <= 90; //0-9, A-Z
        dispatch(hideHelp());

        const column = headers.find(h => h.name === position.columnName);
        const dataItem = position?.index == null ? undefined : dataItems[position.index];
        const readonly = column.readonly || dataItem?.readonly;

        if (!(position && column) && isMovement) {
            dispatch(setInitialPosition());
        } else {
            if ((isMovement || shift) && position.editing) {
                if (column.type === 'select' || moveUpOrDown || keyCode === 9) {
                    dispatch(applyOrCancelEdit());
                } else {
                    return;
                }
            } else if (isEnteringValue && !position.editing && !readonly) {
                dispatch(clearCell());
            }

            switch (keyCode) {
                case 13: position.editing
                    ? !column.cancelOnLeave && dispatch(applyEdit())
                    : !readonly && dispatch(edit());
                    break;
                case 27: dispatch(cancelEdit()); break;
                case 37: dispatch(moveLeft()); break;
                case 38: shift
                    ? dispatch(selectTop(selection, position))
                    : dispatch(moveUp()); break;
                case 9: shift
                    ? dispatch(moveLeft(true))
                    : dispatch(moveRight(true));
                    break;
                case 39: dispatch(moveRight()); break;
                case 40: shift
                    ? dispatch(selectBottom(selection, position, dataItems.length))
                    : dispatch(moveDown()); break;
                case 8:
                case 46: {
                    if (selection.length && !deleteDisabled) {
                        dispatch(deleteSelected());
                    } else if (!readonly && position && !position.editing) {
                        dispatch(clearCell());
                    }
                    break;
                }
                default:
                    dispatch({ type: actionTypes.SAME_POSITION });
            };
        }
    }
}

function selectRow(index, ctrl, shift) {
    const { selection, position } = store.getState().grid;
    if (ctrl) {
        const selectedIndex = selection.indexOf(index);

        return selectedIndex >= 0
            ? setSelection(arrayUtils.removeAt(selection, selectedIndex))
            : setSelection([...new Set([...selection, index])]);
    } else if (shift) {
        const start = Math.min(index, position.index);
        const end = Math.max(index, position.index);
        const indexes = arrayUtils.arrayFromRange(start, end);

        return setSelection([...arrayUtils.removeMany(selection, indexes), ...indexes]);
    }

    return setSelection([index]);
}

function selectTop(selection, position) {
    if (selection.length > 0) {
        const lastSelectedIndex = selection[selection.length - 1];
        if (lastSelectedIndex > 0) {
            const nextIndex = lastSelectedIndex - 1;
            const reverse =
                selection.indexOf(nextIndex) >= 0 &&
                selection.indexOf(lastSelectedIndex + 1) < 0;

            return reverse
                ? setSelection(arrayUtils.removeRight(selection)) // reset selection of last selected row
                : setSelection([...selection.filter(i => i !== nextIndex), nextIndex]);
        }
    } else if (position && position.index) {
        return setSelection([position.index]);
    }

    return { type: actionTypes.SAME_POSITION };
}

function selectBottom(selection, position, limit) {
    if (selection.length > 0) {
        const lastSelectedIndex = selection[selection.length - 1];
        if (lastSelectedIndex < limit - 1) {
            const nextIndex = lastSelectedIndex + 1;
            const reverse =
                selection.indexOf(nextIndex) >= 0 &&
                selection.indexOf(lastSelectedIndex - 1) < 0;

            return reverse
                ? setSelection(arrayUtils.removeRight(selection)) // reset selection of last selected row
                : setSelection([...selection.filter(i => i !== nextIndex), nextIndex]);
        }
    } else if (position) {
        return setSelection([position.index]);
    }

    return { type: actionTypes.SAME_POSITION };
}

function setSelection(selection) {
    return (dispatch, getState) => {
        const { headers, position } = getState().grid;

        if (position.editing) {
            dispatch(applyOrCancelEdit());
        }

        dispatch({
            type: actionTypes.POSITION_CHANGE,
            position: {
                index: selection.length ? selection[selection.length - 1] : 0,
                columnName: headers[0].name
            }
        });

        dispatch({
            type: actionTypes.SET_SELECTION,
            selection
        });
    }
}

function appendDrafts() {
    return (dispatch, getState) => {
        const { dataItems, headers, rowsLimit, minDrafts } = getState().grid;

        if (dataItems.length < rowsLimit) {
            const draftCountToAdd = minDrafts ? calculateMinDraftToAdd(dataItems, minDrafts) : calculateFixedGridDraftToAdd(dataItems, rowsLimit);

            if (draftCountToAdd > 0) {
                const draft = createDraftDataItem(headers);
                const drafts = Array(draftCountToAdd).fill(draft);
                dispatch(appendDataItems(drafts));
            }
        }
    }

    function calculateMinDraftToAdd(dataItems, minDrafts) {
        const nonDraftCount = dataItems.filter((i) => !i.draft).length;

        return minDrafts - nonDraftCount;
    }

    function calculateFixedGridDraftToAdd(dataItems, rowsLimit) {
        return rowsLimit - dataItems.length;
    }
}

function deleteSelected() {
    return dispatch => {
        dispatch({ type: actionTypes.DELETE_SELECTED });
        dispatch(setInitialPosition());
        dispatch(validate());
    }
}

function deleteDataItemsWithErrors() {
    return dispatch => {
        dispatch(validate());
        dispatch({ type: actionTypes.DELETE_INVALID });
        dispatch(appendDrafts());
        dispatch(validate());
    }
}

function deleteFlaggedDataItems() {
    return dispatch => {
        dispatch({ type: actionTypes.DELETE_FLAGGED });
        dispatch(appendDrafts());
        dispatch(validate());
    }
}

function validate() {
    return (dispatch, getState) => {
        const { position } = getState().grid;

        if (position.editing) {
            dispatch(applyOrCancelEdit());
        }

        const { dataItems, headers } = getState().grid;

        const drafts = dataItems.filter(s => s.draft);
        const dirty = dataItems.filter(s => !s.draft);

        const validationResult = dirty.map(s =>
            validateDataItem(
                headers,
                { ...s },
                dirty)
        );

        const validatedHeaders = validateHeaders(headers, dataItems);

        const isValid =
            validatedHeaders.every(h => !h.headerError) &&
            validationResult.length > 0 &&
            validationResult.every(s => s.errors.length === 0);

        validationResult.push(...drafts);

        dispatch({
            type: actionTypes.VALIDATE,
            validation: {
                headers: validatedHeaders,
                dataItems: validationResult,
                isValid
            }
        });
    };
}

function validateHeaders(headers, dataItems) {
    return headers.map(column => {
        if (typeof column.validateHeader === 'function') {
            return { ...column, headerError: column.validateHeader(dataItems) }
        }
        return column;
    })
}

function validateDataItem(headers, dataItem, dataItems) {
    dataItem.errors = headers
        .map(h => {
            const message = validatePropertyValue(dataItem[h.name], h, dataItem, dataItems);
            return message.title ? { columnName: h.name, message: message.title, isDuplicated: message.isDuplicated } : null;
        })
        .filter(e => e);

    return dataItem;
}

function validatePropertyValue(value, column, dataItem, dataItems) {
    const title = column.titleForError || column.title;
    if (column.required && (value == null || value.length === 0)) {
        const { required = '' } = column.errorMessages || {};
        return { title: required || `${title} is required` };
    }

    if (typeof column.validate === 'function') {
        const error = column.validate(value, dataItem, dataItems);
        if (error) {
            return { title: error };
        }
    }

    if (!stringUtils.isNullOrEmpty(value)) {
        if (column.type === 'number' || column.type === 'money' || column.type === 'integer') {
            const number = parseValue(value, column);
            if (!numericUtils.isNumber(number)) {
                return { title: `${title} should be a number` };
            }

            if (column.type === 'integer' && !/^[0-9]*$/.test(number)) {
                return { title: `${title} should be an integer number` };
            }

            const min = typeof column.min === 'function' ? column.min(dataItem) : column.min;
            const max = typeof column.max === 'function' ? column.max(dataItem) : column.max;

            if (min != null && max != null) {
                if (number < min || number > max) {
                    return { title: `${title} should be in the range of ${column.format(min)} to ${column.format(max)}` };
                }
            } else if (min != null && number < min) {
                return { title: `${title} should be greater than or equal to ${column.format(min)}` };
            } else if (max != null && number > max) {
                return { title: `${title} should be less than or equal to ${column.format(max)}` };
            }
        }

        if (column.length && value.length > column.length) {
            return { title: `${title} can be ${column.length} characters max` };
        }

        if (column.pattern && !column.pattern.test(String(parseValue(value, column)))) {
            return { title: `${title} is invalid` };
        }

        if (column.unique) {
            const duplicates =
                dataItems.filter(item =>
                    String(value).localeCompare(
                        item[column.name] || '',
                        undefined,
                        { sensitivity: 'accent' }) === 0);

            if (duplicates.length > 1) {
                return { title: `${title} is not unique`, isDuplicated: true };
            }
        }

        let source = column.selectSourceItemsCallback ? column.selectSourceItemsCallback() : [];
        const { contactsSave } = store.getState();
        if (column.name === columns.company.name) {
            source = contactsSave.companies.map(c => c.name);
        } else if (column.name === columns.ticker.name) {
            if (value.length > columns.ticker.length || !isTickerValid(value)) {
                return { title: `${title} is invalid` };
            }
        }

        if (source.length) {
            const errorMessage = validateRange(column.name, value, source);
            if (errorMessage) {
                return { title: errorMessage };
            }
        }
    }

    return { title: '' };
}

function isTickerValid(ticker) {
    const index = ticker.lastIndexOf(' ');
    return index > 0 && index < ticker.length - 1;
}

function validateRange(columnName, value, allowedValues) {
    const isValid = allowedValues.some(a =>
        String(a.key ?? a).localeCompare(value, undefined, { sensitivity: 'accent' }) === 0);

    if (!isValid) {
        return `${columnName} is not valid`;
    }
}

function showHelp() {
    return { type: actionTypes.SHOW_HELP };
}

function hideHelp() {
    return (dispatch, getState) => {
        const { showHelp } = getState().grid;

        if (showHelp) {
            dispatch({ type: actionTypes.HIDE_HELP });
        }
    }
}

function setUploadState(status, progress = 0, filename) {
    return (dispatch, getState) => {
        const { upload } = getState().grid;

        if (upload.status !== uploadStatus.uploading ||
            upload.progress === 100 ||
            status === uploadStatus.uploading) {
            dispatch({
                type: actionTypes.DATA_ITEMS_UPLOAD_STATE,
                state: { status, progress: Math.floor(progress), filename }
            });
        }
    };
}

function reset() {
    return { type: actionTypes.RESET };
}

function blockInput(block) {
    return (dispatch, getState) => {
        const { inputBlocked } = getState().grid;

        if (inputBlocked !== block) {
            dispatch({
                type: actionTypes.BLOCK_INPUT,
                block
            })
        }
    };
}

function dataProcessing(isDataProcessing) {
    return {
        type: actionTypes.DATA_PROCESSING,
        isDataProcessing
    };
}
