import { ActionType, createAction } from "typesafe-actions";
import { News } from "../types/news/News";
import { Company } from "../types/amr-pipeline/models/Company";

const init = createAction(
    "editNews/init/REQUEST",
    (resolve) => (initialReferenceName?: string) =>
        resolve({ initialReferenceName })
);

const getInitResult = createAction(
    "editNews/init/REQUEST_RESULT",
    (resolve) =>
        (newsList: News[], managers: Company[], arrangers: Company[]) =>
            resolve({ newsList, managers, arrangers })
);

const getTotalCountResult = createAction(
    "editNews/getTotalCount/REQUEST_RESULT",
    (resolve) => (totalCount: number) => resolve({ totalCount })
);

const loadMoreNewsList = createAction(
    "editNews/getList/REQUEST",
    (resolve) => (searchTerm?: string) => resolve({ searchTerm })
);

const loadMoreNewsListResult = createAction(
    "editNews/getList/REQUEST_RESULT",
    (resolve) => (newsList: News[]) => resolve({ newsList })
);

const searchNewsList = createAction(
    "editNews/searchList/REQUEST",
    (resolve) => (searchTerm: string) => resolve({ searchTerm })
);

const searchNewsListResult = createAction(
    "editNews/searchList/REQUEST_RESULT",
    (resolve) => (newsList: News[]) => resolve({ newsList })
);

const setInitialNews = createAction(
    "editNews/setInitial/STATE",
    (resolve) => (initialNews?: News) => resolve({ initialNews })
);

const updateNewsList = createAction(
    "editNews/updateList/STATE",
    (resolve) => (newsList: News[]) => resolve({ newsList })
);

const setSearchTerm = createAction(
    "editNews/setSearchTerm/STATE",
    (resolve) => (searchTerm: string) => resolve({ searchTerm })
);

const getNewsDetails = createAction(
    "editNews/getOne/REQUEST",
    (resolve) => (referenceName: string) => resolve({ referenceName })
);

const getNewsDetailsResult = createAction(
    "editNews/getOne/REQUEST_RESULT",
    (resolve) => (newsData: News) => resolve({ newsData })
);

const createOrUpdateNews = createAction(
    "editNews/createOrUpdate/REQUEST",
    (resolve) => (newsData: News) => resolve({ newsData })
);

const deleteNews = createAction(
    "editNews/delete/REQUEST",
    (resolve) => (referenceName: string) => resolve({ referenceName })
);

const resetNewsDetails = createAction("editNews/newsDetails/RESET");

const reset = createAction("editNews/RESET");

export type TEditNewsActions = ActionType<
    (typeof editNewsActions)[keyof typeof editNewsActions]
>;

export const editNewsActions = {
    init,
    getInitResult,
    getTotalCountResult,
    loadMoreNewsList,
    loadMoreNewsListResult,
    searchNewsList,
    searchNewsListResult,
    getNewsDetails,
    getNewsDetailsResult,
    createOrUpdateNews,
    setInitialNews,
    updateNewsList,
    setSearchTerm,
    deleteNews,
    resetNewsDetails,
    reset,
};
