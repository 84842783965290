import { keys } from 'lodash';
import {
    brokerDealerBiddingActions as actionTypes,
    bwicStatuses
} from '../constants';
import { biddingService } from '../services';
import { brokerDealerAccessState } from '../constants/bidding';
import { errorActions, bidPlacementActions } from '.';
import { createAction } from 'typesafe-actions';
import { bidConfirmationActions } from './bid-confirmation.actions';


const submitBids = createAction('brokerDealerBidding/SUBMIT_CONFIRMED',
    resolve => (positionId, bids) => resolve({ positionId, bids })
);

export const brokerDealerBiddingActions = {
    init,
    reset,
    sendInviteRequest,
    bidAll,
    bidSingle,
    submitBids
}

function init(bwic) {
    return async dispatch => {
        try {
            if (!bwic.isParsed) {
                const accessState = await biddingService.getAccessState(bwic.referenceName);
                dispatch(storeAccessState(accessState));
            }

            if (bwic.status === bwicStatuses.scheduled.key) {
                dispatch(initScheduled(bwic.securities));
            } else {
                dispatch(storeSecurities([...bwic.securities ]));
            }
        } catch (e) {
            dispatch(errorActions.criticalError(e));
        }
    };
}

function storeSecurities(securities) {
    return {
        type: actionTypes.BROKER_DEALER_BIDDING_STORE_SECURITIES,
        securities
    };
}

function initScheduled(securities) {
    return storeSecurities(securities);
}

function reset() {
    return { type: actionTypes.BROKER_DEALER_BIDDING_RESET };
}

function storeAccessState(accessState) {
    return {
        type: actionTypes.BROKER_DEALER_ACCESS_STATE,
        accessState
    };
}

function sendInviteRequest(comment = '') {
    return (dispatch, getState) => {
        const { bwic } = getState().bidding;

        biddingService
            .requestAccess(bwic.referenceName, comment.trim())
            .then(() => dispatch(storeAccessState(brokerDealerAccessState.accessRequested)))
            .catch(e => dispatch(errorActions.unexpectedError(e)));
    };
}

function bidSingle(positionId) {
    return (dispatch, getState) => {
        dispatch(bidPlacementActions.validateSingle(positionId));

        const { securities, editBid } = getState().brokerDealerBidding;
        const position =
            securities &&
            securities.length &&
            securities.find(s => s.id === positionId);

        if (position && !hasBidErrors([positionId], editBid)) {
            dispatch(bidConfirmationActions.confirmation([position.id]));
        }
    };
}

function bidAll() {
    return (dispatch, getState) => {
        dispatch(bidPlacementActions.validate());

        const { securities, editBid } = getState().brokerDealerBidding;
        const biddingSecurities = securities
            .filter(s => editBid[s.id] && editBid[s.id].canSubmit)
            .map(s => s.id);

        if (!hasBidErrors(biddingSecurities, editBid)) {
            dispatch(bidConfirmationActions.confirmation(biddingSecurities));
        }
    };
}

function hasBidErrors(securities, editState) {
    return securities.some(id => {
        const edit = editState[id];
        return edit && edit.errors && keys(edit.errors).length
    });
}
