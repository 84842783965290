import { createAction } from 'typesafe-actions';
import { SubscriptionBillingHistoryFilterState } from '../types/state/SubscriptionsState';
import { BillingHistory } from '../types/billing/BillingHistory';
import { FilterOption } from '../types/filters/FilterOption';
import { RequestState } from '../constants/request-state';

const fetchBillingHistory = createAction(
    'subscriptionBillingHistory/FETCH_HISTORY',
    resolve =>
        (requestState: RequestState, billingHistory?: BillingHistory[]) =>
            resolve({ requestState, billingHistory})
)

const updateFilter = createAction(
    'subscriptionBillingHistory/UPDATE_FILTER',
    resolve =>
        (filterField: keyof SubscriptionBillingHistoryFilterState, filterOption: FilterOption) =>
            resolve({ filterField, filterOption })
);

const applyFilter = createAction('subscriptionBillingHistory/APPLY_FILTER');
const setApplyFilter = createAction('subscriptionBillingHistory/SET_APPLY_FILTER');
const applyReset = createAction('subscriptionBillingHistory/RESET');
const resetFilter = createAction('subscriptionBillingHistory/RESET_FILTER');


export const billingHistoryActions = {
    updateFilter,
    applyFilter,
    resetFilter,
    setApplyFilter,
    fetchBillingHistory,
    applyReset
};
