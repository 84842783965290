import { rulesActions as actionTypes } from '../constants';
import { controlPanelActions } from './control-panel.actions';

export const rulesActions = {
    show,
    hide
}

function show(positionId, separatePanel) {
    return dispatch => {
        dispatch(controlPanelActions.hide());
        dispatch({ type: actionTypes.RULES_SHOW, positionId, separatePanel });
    };
}

function hide() {
    return (dispatch, getState) => {
        if (getState().rules.show) {
            dispatch({ type: actionTypes.RULES_HIDE });
        }
    };
}
