import { pxTalkListActions as actionTypes } from '../constants';
import { controlPanelActions } from '.';

export const pxTalkListActions = {
    show,
    hide
}

function show(positionId) {
    return dispatch => {
        dispatch(controlPanelActions.hide());
        dispatch({
            type: actionTypes.PX_TALK_LIST_VISIBLE,
            visible: true,
            positionId
        });
    };
}

function hide() {
    return (dispatch, getState) => {
        if (getState().pxTalkList.visible) {
            dispatch({
                type: actionTypes.PX_TALK_LIST_VISIBLE,
                visible: false
            })
        }
    };
}
