import { createAction } from 'typesafe-actions';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { NotificationType } from '../types/notifications/NotificationType';
import { Notification } from '../types/notifications/Notification';
import { NotificationAttributes } from '../types/notifications/NotificationAttributes';
import { StatusMessageSectionType } from '../types/state/NotificationState';
import { ModalNotification } from '../types/notifications/ModalNotification';

const notificationReset = createAction('notification/RESET');
const notificationPushReceived = createAction('notification/PUSH_RECEIVED',
    resolve => (notification: Notification) => resolve({ notification, type: NotificationType.PushNotification })
);
const notificationAddInfo = createAction('notification/ADD_INFO',
    resolve => (title: string, message: string, date: Date | null = null) => resolve({
        notification: createNotificationModel(
            uuidv4(),
            false,
            title || '',
            message || '',
            date || moment.utc().toDate(),
        ),
        type: NotificationType.Info
    })
);
const notificationAddInfoMessage = createAction('notification/ADD_INFO_MESSAGE',
    resolve => (message: string) => resolve({
        notification: createNotificationModel(
            uuidv4(),
            false,
            'Notification',
            message || '',
            moment.utc().toDate(),
        ),
        type: NotificationType.Info
    })
);
const notificationAddError = createAction('notification/ADD_ERROR',
    resolve => (title: string, message: string, date: Date) => resolve({
        notification: createNotificationModel(
            uuidv4(),
            false,
            title,
            message || '',
            date || moment.utc().toDate(),
        ),
        type: NotificationType.Error
    })
);
const notificationAddErrorMessage = createAction('notification/ADD_ERROR_MESSAGE',
    resolve => (message: string, title?: string) => resolve({
        notification: createNotificationModel(
            uuidv4(),
            false,
            title || 'Error',
            message || '',
            moment.utc().toDate(),
        ),
        type: NotificationType.Error
    })
);
const notificationAddErrorMessageModal = createAction('notification/ADD_ERROR_MESSAGE_MODAL',
    resolve => (message: string, title: string, refreshButtonVisible?: boolean) =>
        resolve({ notification: createModalNotificationModel(title, message, StatusMessageSectionType.Error, refreshButtonVisible, undefined, undefined, undefined) })
);
const infoModal = createAction('notification/INFO_MODAL',
    resolve => (message: string, title: string, blinkBrowserTabText?: string[], popupClassName?: string) =>
        resolve({ notification: createModalNotificationModel(title, message, StatusMessageSectionType.Info, false, blinkBrowserTabText, popupClassName) })
);
const infoModalReplace = createAction('notification/INFO_MODAL',
    resolve => (modalKey: string, message: string, title: string, blinkBrowserTabText?: string[], popupClassName?: string) =>
        resolve({ notification: createModalNotificationModel(title, message, StatusMessageSectionType.Info, false, blinkBrowserTabText, popupClassName, modalKey) })
);
const warningModal = createAction('notification/WARN_MODAL',
    resolve => (title: string, message: string) =>
        resolve({ notification: createModalNotificationModel(title, message, StatusMessageSectionType.Alert) })
);
const notificationListHideToast = createAction('notification/LIST_REMOVE_ITEM',
    resolve => (referenceName: string) => resolve({ referenceName })
);
const notificationListRemoveModalItem = createAction('notification/LIST_REMOVE_MODAL_ITEM',
    resolve => (index: number) => resolve({ index })
);
const notificationListToastExpired = createAction('notification/LIST_SHIFT',
    resolve => (referenceName: string) => resolve({ referenceName })
);
const modalNotificationListReset = createAction('notification/RESET_MODAL');
const notificationServiceConnect = createAction('notification/SERVICE_CONNECT');
const notificationServiceConnected = createAction('notification/SERVICE_CONNECTED');
const notificationServiceDisconnected = createAction('notification/SERVICE_DISCONNECTED');
const notificationServiceReconnecting = createAction('notification/SERVICE_RECONNECTING');
const notificationServiceReconnected = createAction('notification/SERVICE_RECONNECTED');
const notificationGetList = createAction('notification/GET_LIST');
const appendNotifications = createAction('notification/SET_LIST',
    resolve =>
        (notificationList: Notification[], notificationAttributes: NotificationAttributes) =>
            resolve({ notificationList, notificationAttributes })
);
const notificationGetListFailure = createAction('notification/GET_LIST_FAILURE');
const notificationsGetCountUnread = createAction('notification/GET_COUNT_UNREAD');
const notificationsGetCountUnreadFailure = createAction('notification/GET_COUNT_UNREAD_FAILURE');
const notificationsSetCountUnread = createAction('notification/SET_COUNT_UNREAD',
    resolve => (count: number) => resolve({ count })
);
const notificationSetReadRequest = createAction('notification/SET_READ_REQUEST',
    resolve => (referenceName: string) => resolve({ referenceName })
);
const notificationSetReadSuccess = createAction('notification/SET_READ_SUCCESS',
    resolve => (referenceName: string) => resolve({ referenceName })
);
const notificationSetReadFailure = createAction('notification/SET_READ_SUCCESS',
    resolve => (referenceName: string) => resolve({ referenceName })
);
const notificationsReadAllRequest = createAction('notification/READ_ALL_REQUEST');
const notificationsReadAllSuccess = createAction('notification/READ_ALL_SUCCESS');
const notificationsReadAllFailure = createAction('notification/READ_ALL_FAILURE');
const notificationRead = createAction('notification/PUSH_READ',
    resolve => (referenceName: string) => resolve({ referenceName })
);
const notificationReadAll = createAction('notification/PUSH_READ_ALL');

export const notificationActions = {
    notificationReset,
    notificationPushReceived,
    notificationRead,
    notificationReadAll,
    notificationAddInfo,
    notificationAddInfoMessage,
    notificationAddError,
    notificationAddErrorMessage,
    notificationAddErrorMessageModal,
    infoModal,
    infoModalReplace,
    warningModal,
    notificationListHideToast,
    notificationListRemoveModalItem,
    notificationListToastExpired,
    modalNotificationListReset,
    notificationServiceConnect,
    notificationServiceConnected,
    notificationServiceDisconnected,
    notificationServiceReconnecting,
    notificationServiceReconnected,
    notificationGetList,
    appendNotifications,
    notificationGetListFailure,
    notificationsGetCountUnread,
    notificationsGetCountUnreadFailure,
    notificationsSetCountUnread,
    notificationSetReadRequest,
    notificationSetReadSuccess,
    notificationSetReadFailure,
    notificationsReadAllRequest,
    notificationsReadAllSuccess,
    notificationsReadAllFailure
};

const createNotificationModel = (
    referenceName: string,
    isReadByUser: boolean,
    subject: string,
    message: string,
    sentDateUtc: Date,
): Notification => (
    { isReadByUser, subject, message, sentDateUtc, referenceName }
);

const createModalNotificationModel = (
    title: string,
    message: string,
    type: StatusMessageSectionType = StatusMessageSectionType.Error,
    refreshButtonVisible: boolean = false,
    blinkBrowserTabText?: string[],
    popupClassName?: string,
    modalKey?: string

): ModalNotification => ({
    title,
    message,
    type,
    refreshButtonVisible,
    blinkBrowserTabText,
    popupClassName,
    modalKey
})