import { createAction } from "typesafe-actions";
import { BidCompanyIdentifier } from "../types/bidding/BidCompanyIdentifier";
import { BidderCompanySlim } from "../types/company/BidderCompanySlim";

const show = createAction('dealerListPanel/SHOW');
const hide = createAction('dealerListPanel/HIDE');
const toggleCompanyVisible = createAction(
    'dealerListPanel/TOGGLE_COMPANY_VISIBLE',
    resolve => (identifier: BidCompanyIdentifier, setLastSelectedCompanyIdentifier?: boolean) => resolve({ identifier, setLastSelectedCompanyIdentifier })
);
const toggleAllFavoriteCompaniesVisible = createAction(
    'dealerListPanel/TOGGLE_ALL_FAVORITE_COMPANIES_VISIBLE',
    resolve => (companies: BidderCompanySlim[]) => resolve({ companies })
);
const toggleAllPlatformVisible = createAction(
    'dealerListPanel/TOGGLE_ALL_PLATFORM_VISIBLE',
    resolve => (companies: BidderCompanySlim[], visible?: boolean) => resolve({ companies, visible })
);
const toggleAllOffPlatformVisible = createAction(
    'dealerListPanel/TOGGLE_ALL_OFF_PLATFORM_VISIBLE',
    resolve => (companies: BidderCompanySlim[], visible?: boolean) => resolve({ companies, visible })
);
const toggleAllSettlmentsClientsVisible = createAction(
    'dealerListPanel/TOGGLE_ALL_SETTLMENTS_CLIENTS_VISIBLE',
    resolve => (companies: BidderCompanySlim[], visible?: boolean) => resolve({ companies, visible })
);
const toggleAllFavoriteVisibleFlagOnly = createAction('dealerListPanel/TOGGLE_ALL_FAVORITE_VISIBLE_FLAG_ONLY');
const toggleAllPlatformVisibleFlagOnly = createAction('dealerListPanel/TOGGLE_ALL_PLATFORM_VISIBLE_FLAG_ONLY');
const toggleAllSettlmentsClientsFlagOnly = createAction('dealerListPanel/TOGGLE_ALL_SETTLMENTS_CLIENTS_FLAG_ONLY');
const toggleAllOffPlatformVisibleFlagOnly = createAction('dealerListPanel/TOGGLE_ALL_OFF_PLATFORM_VISIBLE_FLAG_ONLY');
const setDefaultVisibility = createAction(
    'dealerListPanel/SET_DEFAULTS',
    resolve => (companiesToHide: BidderCompanySlim[]) => resolve({ companiesToHide })
);
const setDisabledCompanies = createAction(
    'dealerListPanel/SET_DISABLED_COMPANIES',
    resolve => (disabledCompanies: BidderCompanySlim[]) => resolve({ disabledCompanies })
);
const setBidOnlyFlag = createAction(
    'dealerListPanel/SET_BID_ONLY_FLAG',
    resolve => (bidOnlyFlag: boolean) => resolve({ bidOnlyFlag })
);
const stage2ParticipantsFlagChange = createAction('dealerListPanel/STAGE_2_PARTICIPANTS_FLAG_CHANGE',
    resolve => (isStage2participantsOnly: boolean, showAllEnabled?: boolean) => resolve({ isStage2participantsOnly, showAllEnabled })
);
const showBwicParticipantsOnly = createAction('dealerListPanel/SHOW_BWIC_PARTICIPANTS_ONLY')
const reset = createAction('dealerListPanel/RESET');

export const dealerListPanelActions = {
    show,
    hide,
    toggleCompanyVisible,
    toggleAllFavoriteCompaniesVisible,
    toggleAllPlatformVisible,
    toggleAllOffPlatformVisible,
    toggleAllFavoriteVisibleFlagOnly,
    toggleAllPlatformVisibleFlagOnly,
    toggleAllSettlmentsClientsFlagOnly,
    toggleAllOffPlatformVisibleFlagOnly,
    toggleAllSettlmentsClientsVisible,
    setDefaultVisibility,
    setDisabledCompanies,
    reset,
    setBidOnlyFlag,
    stage2ParticipantsFlagChange,
    showBwicParticipantsOnly
};
