import { createAction } from 'typesafe-actions';
import { SellerAdminTradingLimitValuesState } from '../types/seller-admin/SellerAdminTradingLimitValuesState';

const setEditModeStatus = createAction(
    'sellerAdminLimit/SET_EDIT_MODE_STATUS',
    resolve => (status: boolean) => resolve({ status })
);

const getTradeLimitsRequest = createAction('sellerAdminLimit/GET_TRADING_LIMITS_REQUEST');
const getTradeLimitsSuccess = createAction(
    'sellerAdminLimit/GET_TRADING_LIMITS_SUCCESS',
        resolve => (limits: { currency: string, type: number, size: number | null }[]) => resolve({ limits})
);

const updateTradeLimitsRequest = createAction(
    'sellerAdminLimit/UPDATE_TRADE_LIMITS_REQUEST',
    resolve => (limits: SellerAdminTradingLimitValuesState) => resolve({ limits })
);
const updateTradeLimitsSuccess = createAction('sellerAdminLimit/UPDATE_TRADE_LIMITS_SUCCESS');
const updateTradeLimitsFailure = createAction('sellerAdminLimit/UPDATE_TRADE_LIMITS_FAILURE');

const reset = createAction('sellerAdminLimit/RESET');

export const sellerAdminLimitActions = {
    setEditModeStatus,
    getTradeLimitsRequest,
    getTradeLimitsSuccess,
    updateTradeLimitsRequest,
    updateTradeLimitsSuccess,
    updateTradeLimitsFailure,
    reset
};
