import { createAction } from 'typesafe-actions';
import { DateFilterOption } from '../types/filters/DateFilterOption';
import { DateRange } from '../types/filters/DateRange';

const toogleFilterRegBy = createAction('manage-members/MEMBER_FILTER_TOOGLE_REG_BY',
    resolve => (regBy: number[]) => resolve({ regBy })
)

const setFilterRegBy = createAction('manage-members/MEMBER_FILTER_SET_REG_BY',
    resolve => (regBy: number[]) => resolve({ regBy })
)

const toogleFilterCompanies = createAction('manage-members/MEMBER_FILTER_TOOGLE_COMPANIES',
    resolve => (companies: number[]) => resolve({ companies })
)

const setFilterCompanies = createAction('manage-members/MEMBER_FILTER_SET_COMPANIES',
    resolve => (companies: number[]) => resolve({ companies })
)

const toogleFilterStatusActive = createAction('manage-members/MEMBER_FILTER_TOOGLE_STATUS_ACTIVE');
const toogleFilterStatusBlocked = createAction('manage-members/MEMBER_FILTER_TOOGLE_STATUS_BLOCKED');
const toogleFilterStatusInvited = createAction('manage-members/MEMBER_FILTER_TOOGLE_STATUS_INVITED');
const toogleFilterStatusNotInvited = createAction('manage-members/MEMBER_FILTER_TOOGLE_STATUS_NOT_INVITED');
const toogleFilterStatusPendingApproval = createAction('manage-members/MEMBER_FILTER_TOOGLE_STATUS_PENDING_APPROVAL');

const toogleFilterRoleAdministrator = createAction('manage-members/MEMBER_FILTER_TOOGLE_ROLE_ADMINISTRATOR');
const toggleFilterRoleArrangersClient = createAction('manage-members/MEMBER_FILTER_TOGGLE_ROLE_ARRANGERSCLIENT')
const toogleFilterRoleBrokerDealerTrader = createAction('manage-members/MEMBER_FILTER_TOOGLE_ROLE_BROKER_DEALER_TRADER');
const toogleFilterRoleBrokerDealerViewer = createAction('manage-members/MEMBER_FILTER_TOOGLE_ROLE_BROKER_DEALER_VIEWER');
const toogleFilterRolesDataEntry = createAction('manage-members/MEMBER_FILTER_TOOGLE_ROLE_DATA_ENTRY')
const toogleFilterRoleSellerAdministrator = createAction('manage-members/MEMBER_FILTER_TOOGLE_ROLE_SELLER_AMINISTARTOR');
const toogleFilterRoleSellerTrader = createAction('manage-members/MEMBER_FILTER_TOOGLE_ROLE_SELLER_TRADER');
const toogleFilterRoleSellerViewer = createAction('manage-members/MEMBER_FILTER_TOOGLE_ROLE_SELLER_VIEWER');
const toogleFilterRolesSubscriptionManager = createAction('manage-members/MEMBER_FILTER_TOOGLE_ROLE_SUBSCRIPTION_MANAGER')
const toogleFilterRoleViewer = createAction('manage-members/MEMBER_FILTER_TOOGLE_ROLE_VIEWER');
const toogleFilterCollateralManager = createAction('manage-members/MEMBER_FILTER_TOOGLE_ROLE_COLLATERAL_MANAGER');
const toogleFilterRoleMedia = createAction('manage-members/MEMBER_FILTER_TOOGLE_ROLE_MEDIA');

const setDateFilterChange = createAction('manage-members/MEMBER_FILTER_DATE_CHANGED',
    resolve => (dateOption: DateFilterOption) => resolve({ dateOption })
)

const setDateFilterRangeChange = createAction('manage-members/MEMBER_FILTER_DATE_RANGE_CHANGED',
    resolve => (dateRange: DateRange) => resolve({ dateRange })
)

export const manageMembersFiltersActions = {
    toogleFilterRegBy,
    setFilterRegBy,
    toogleFilterCompanies,
    setFilterCompanies,
    toogleFilterStatusActive,
    toogleFilterStatusBlocked,
    toogleFilterStatusInvited,
    toogleFilterStatusNotInvited,
    toogleFilterStatusPendingApproval,
    toogleFilterRoleAdministrator,
    toggleFilterRoleArrangersClient,
    toogleFilterRoleBrokerDealerTrader,
    toogleFilterRoleBrokerDealerViewer,
    toogleFilterRolesDataEntry,
    toogleFilterRoleSellerAdministrator,
    toogleFilterRoleSellerTrader,
    toogleFilterRoleSellerViewer,
    toogleFilterRolesSubscriptionManager,
    toogleFilterRoleViewer,
    toogleFilterCollateralManager,
    toogleFilterRoleMedia,
    setDateFilterChange,
    setDateFilterRangeChange
}
