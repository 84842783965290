import { ActionType, createAction } from 'typesafe-actions';
import { RequestState } from '../../constants/request-state';
import { AwaitingTrade } from '../../types/trades/AwaitingTrade';
import { TradeSettlementStatus } from '../../types/settlement/TradeSettlementStatus';

const requestTrades = createAction('entities/awaitingTrades/REQUEST');
const requestTradesResult = createAction(
    'entities/awaitingTrades/REQUEST_RESULT',
    resolve => (requestStatus: RequestState, awaitingTrades: AwaitingTrade[] = []) => resolve({ requestStatus, awaitingTrades })
);
const trade = createAction(
    'entities/awaitingTrades/TRADE',
    resolve => (trade: AwaitingTrade) => resolve({ trade })
);
const tradeAllocationCreated = createAction(
    'entities/awaitingTrades/TRADE_ALLOCATION_CREATED',
    resolve =>
        (tradeId: string, buyerSettlementStatus: TradeSettlementStatus, sellerSettlementStatus: TradeSettlementStatus) =>
            resolve({ tradeId, buyerSettlementStatus, sellerSettlementStatus })
);

export type AwaitingTradesActionTypes =
    ActionType<typeof requestTrades |
        typeof requestTradesResult |
        typeof trade |
        typeof tradeAllocationCreated>

export const awaitingTradesActions = {
    requestTrades,
    requestTradesResult,
    trade,
    tradeAllocationCreated,
}
