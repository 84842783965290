import { Dispatch } from "redux";
import { Deal } from "../types/amr-pipeline/models/Deal";
import { AppState } from "../types/state/AppState";
import { AmrClass } from "../types/amr-pipeline/models/AmrClass";
import { amrPipelineService  } from "../services/amr-pipeline.service";
import { constants, imDetailsDealsActions as actionTypes, roles } from '../constants';
import { errorActions } from "./error.actions";
import { AmrDocument } from "../types/amr-pipeline/models/AmrDocument";
import moment from "moment";
import { dealsService } from "../services/deals.service";
import { user } from "../user";

export const imDetailsDealsActions = {
    loadDealDetails,
    loadTrancheDetails,
    documentsSearchTermChange,
    resetDocumentsSearch
}

function storeDeals(deals: Deal[]) {
    return {
        type: actionTypes.DEALS_STORE_DEALS,
        deals
    };
}

function storeDocumentsSearchResults(
    filteredDocuments: AmrDocument[],
    dealReferenceName: string
) {
    return {
        type: actionTypes.DEALS_STORE_DOCUMENTS_SEARCH_RESULTS,
        filteredDocuments,
        dealReferenceName
    };
}

function resetDocumentsSearch() {
    return {
        type: actionTypes.DEALS_RESET_DOCUMENTS_SEARCH,
    };
}


function documentsSearchTermChange(
    searchTerm: string,
    dealReferenceName: string
) {
    return async (dispatch: Dispatch, getState: () => AppState) => {
        const searchTermLowerCase = searchTerm.toLowerCase();
        const { deals } = getState().imDetailsDeals;
        if (deals) {
            const selectedDeal = deals?.find(
                (d) => d.referenceName === dealReferenceName
            );
            if (selectedDeal && selectedDeal.documents) {
                const {
                    documents,
                } = selectedDeal;
                const filteredDocuments = documents?.filter(
                    (d) => {
                        const date = d.executionDate ?? d.uploadTime;
                        const dateText = moment(date).format(constants.dateFormat);

                        return d.name.toLowerCase().includes(searchTermLowerCase) || dateText.toLowerCase().includes(searchTermLowerCase)
                    }
                );
                dispatch(storeDocumentsSearchResults(filteredDocuments, dealReferenceName));
            }
        }
    };
}

function loadDealDetails(dealReferenceName: string, transactionReferenceName: string) {
    return async (dispatch: Dispatch, getState: () => AppState) => {
        const { deals, loadedDealReferenceNames } = getState().imDetailsDeals;
        const currentDeal = deals?.find(d => d.referenceName === dealReferenceName);

        if (!loadedDealReferenceNames.some((referenceName: string) => referenceName === dealReferenceName)) {
            dispatch(dealDetailsRequesting(true));

            try {
                const isMedia = user.hasRoles(roles.Media);

                const actualAuctionsPromise = dealsService.getLatestDealAuctions(dealReferenceName);
                const amrDocumentsPromise = isMedia ? null : amrPipelineService.getAmrDocuments(dealReferenceName, transactionReferenceName);
                const dealDocumentsPromise = dealsService.getDealDocuments(dealReferenceName);

                if (!currentDeal) {
                    const deal = await dealsService.getDeal(dealReferenceName);
                    const loadedDeals = deals || [];
                    dispatch(storeDeals([...loadedDeals, deal]));
                }

                const [amrDocuments, dealDocuments, actualAuctions] = await Promise.all([
                    amrDocumentsPromise,
                    dealDocumentsPromise,
                    actualAuctionsPromise,
                ]);

                dispatch(storeDocuments(dealReferenceName, dealDocuments, amrDocuments));
                dispatch(storeActualAmrClasses(dealReferenceName, actualAuctions));
            } catch (e) {
                dispatch(errorActions.criticalError(e));
            } finally {
                dispatch(dealDetailsRequesting(false));
            }
        }
    };
}

function loadTrancheDetails(dealReferenceName: string, classReferenceName: string) {
    return async (dispatch: Dispatch, getState: () => AppState) => {
        const { deals } = getState().imDetailsDeals;
        const selectedDeal = deals?.find(d => d.referenceName === dealReferenceName);
        const selectedTranche = selectedDeal?.classes.find(c => c.referenceName === classReferenceName);

        if (selectedDeal && selectedTranche && selectedTranche.auctions == null) {
            dispatch(trancheDetailsRequesting(true));

            try {
                const auctions = await dealsService.getClassAuctions(dealReferenceName, selectedTranche.referenceName);
                dispatch(storeAmrClasses(dealReferenceName, classReferenceName, auctions));
            } catch (e) {
                dispatch(errorActions.criticalError(e));
            } finally {
                dispatch(trancheDetailsRequesting(false));
            }
        }
    };
}

function storeActualAmrClasses(dealReferenceName: string, amrClasses: AmrClass[]) {
    return {
        type: actionTypes.DEALS_STORE_ACTUAL_AMR_CLASSES,
        dealReferenceName,
        amrClasses
    };
}

function storeAmrClasses(dealReferenceName: string, classReferenceName: string, amrClasses: AmrClass[]) {
    return {
        type: actionTypes.DEALS_STORE_AMR_CLASSES,
        dealReferenceName,
        classReferenceName,
        amrClasses
    };
}

function storeDocuments(dealReferenceName: string, dealDocuments: Document[], amrDocuments: Document[]) {
    return {
        type: actionTypes.DEALS_STORE_DOCUMENTS,
        dealReferenceName,
        documents: dealDocuments.concat(amrDocuments)
    };
}

function dealDetailsRequesting(isRequesting: boolean) {
    return {
        type: actionTypes.DEAL_DETAILS_REQUESTING_STATE,
        isRequesting
    };
}

function trancheDetailsRequesting(isRequesting: boolean) {
    return {
        type: actionTypes.DEALS_TRANCHE_DETAILS_REQUESTUNG_STATE,
        isRequesting
    };
}
