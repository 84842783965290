import {
    companyRoles,
    constants,
    editCompanyActions as actionTypes,
    errorMessages,
    RegistrationType,
} from '../constants';
import { errorActions } from '.';
import { companiesService, locationService, salesRepresentativesService } from '../services';

export const editCompanyActions = {
    init,
    reset,
    save,
    loadCities,
};

function init(referenceName) {
    return async dispatch => {
        try {
            dispatch(setLoadingCompanyStatus(true));
            const companyPromise = referenceName != null
                ? companiesService.getCompany(referenceName)
                : new Promise(resolve => resolve(undefined));
            const countriesPromise = locationService.getCountries();
            const statesPromise = locationService.getStates(constants.USCountryCode);
            const salesRepresentativesPromise = salesRepresentativesService.getList();

            const company = await companyPromise;
            const countries = await countriesPromise;
            const states = await statesPromise;
            const salesRepresentatives = await salesRepresentativesPromise;

            dispatch(loadCountries(countries));
            dispatch(loadStates(states));
            dispatch(storeSalesRepresentatives(salesRepresentatives.map(s => (
                { ...s, selected: company && company.salesRepresentatives.some(с => с.id === s.id) })))
            );

            if (referenceName != null && company) {
                if (!company.id) {
                    throw new Error({ status: 404, message: 'Company not found' });
                }
                if (company.countryCode === constants.USCountryCode) {
                    dispatch(loadCities(company.stateId));
                }
                company.registrationType = company.manualRegistration ? RegistrationType.manual : RegistrationType.sendLink;
                if (company.mpid) {
                    company.mpid = company.mpid.trim();
                }
                if (company.headOfTrading == null) {
                    company.headOfTrading = {}
                }
            }
            dispatch(loadCompany(company));
        }
        catch (e) {
            dispatch(errorActions.criticalError(e));
        }
        finally {
            dispatch(setLoadingCompanyStatus(false));
        }
    };
}

function storeSalesRepresentatives(salesRepresentatives) {
    return {
        type: actionTypes.SALES_REPRESENTATIVES,
        salesRepresentatives
    }
}

function loadCities(stateId) {
    return dispatch => {
        if (stateId) {
            locationService
                .getCities(stateId)
                .then(
                    cities => dispatch({ type: actionTypes.CITIES, cities }),
                    e => dispatch(errorActions.error(e))
                );
        } else {
            dispatch({ type: actionTypes.CITIES, cities: [] });
        }
    };
}

function loadStates(states) {
    return {
        type: actionTypes.STATES,
        states
    };
}

function loadCountries(countries) {
    return {
        type: actionTypes.COUNTRIES,
        countries
    };
}

function loadCompany(company) {
    return { type: actionTypes.COMPANY, company };
}

function save(values) {
    return async (dispatch, getState) => {
        dispatch(saving(true));

        const { salesRepresentatives, company } = getState().editCompany;
        const isBrokerDealer = values.role === companyRoles.BrokerDealer;
        const model = {
            id: values.id,
            name: values.name.trim(),
            phone: values.phone ? values.phone.trim() : null,
            website: values.website ? values.website.trim() : null,
            countryCode: values.countryCode,
            stateId: values.stateId,
            state: values.stateId ? null : values.state.trim(),
            cityId: values.cityId,
            city: values.cityId ? null : values.city.trim(),
            zipCode: values.zipCode ? values.zipCode.trim() : '',
            address: values.address.trim(),
            role: values.role,
            code: values.code ? values.code.trim().toUpperCase() : '',
            distributionList: isBrokerDealer && values.distributionList ? values.distributionList.trim() : null,
            mpid: isBrokerDealer && values.mpid ? values.mpid.trim().toUpperCase() : null,
            manualRegistration: company ? company.registrationType === RegistrationType.manual : true,
            headOfTrading: {
                firstName: values.headOfTradingFirstName,
                lastName: values.headOfTradingLastName,
                email: values.headOfTradingEmail,
                office: values.headOfTradingOffice ? values.headOfTradingOffice.trim() : null,
                mobile: values.headOfTradingMobile ? values.headOfTradingMobile.trim() : null,
            }
        };

        const salesRepresentativesToAssign = salesRepresentatives
            .filter(s => values.salesRepresentatives.some(v => v === s.id) && (company ? !company.salesRepresentatives.some(c => c.id === s.id) : true))
            .map(s => s.id);
        const salesRepresentativesToUnassign = salesRepresentatives
            .filter(s => !values.salesRepresentatives.some(v => v === s.id) && company && company.salesRepresentatives.some(c => c.id === s.id))
            .map(s => s.id);

        try {
            const companyId = await companiesService.save(model);

            const assignPromise = salesRepresentativesToAssign.length
                ? salesRepresentativesService.assign({ companyId, representativeUserIdList: salesRepresentativesToAssign })
                : Promise.resolve();

            const unassignPromise = salesRepresentativesToUnassign.length
                ? salesRepresentativesService.unassign({ companyId, representativeUserIdList: salesRepresentativesToUnassign })
                : Promise.resolve();

            await assignPromise;
            await unassignPromise;

            dispatch(saved());
        } catch (e) {
            dispatch(errorActions.error(
                e,
                errorMessages.unexpectedError,
                errorMessages.companyInformationCanNotBeChanged
            ));
        } finally {
            dispatch(saving(false))
        }
    };
}

function setLoadingCompanyStatus(status) {
    return {
        type: actionTypes.EDIT_COMPANY_LOADING_STATUS,
        payload: { status }
    }
}

function saving(isSaving) {
    return {
        type: actionTypes.SAVING,
        isSaving
    };
}

function saved() {
    return {
        type: actionTypes.SAVED,
    };
}

function reset() {
    return dispatch => {
        dispatch({ type: actionTypes.RESET });
        dispatch(errorActions.resetError());
    };
}
