import { changePxTalkActions as actionTypes, errorMessages } from '../constants';
import { pxTalkService } from '../services';
import { jsonUtils, wait, pxTalkUtils, numericUtils, stringUtils } from '../utils';
import { formsActions } from '.';

export const changePxTalkActions = {
    init,
    reset,
    add,
    talkChange,
    normalizeTalk,
    levelTypeChange,
    deletePxTalk,
    validate,
    waitNormalizing
}

function init(bwicReferenceName, positionId, ticker, pxTalks) {
    return dispatch => {
        dispatch({
            type: actionTypes.STORE_PXTALKS,
            bwicReferenceName,
            positionId,
            ticker,
            pxTalks: pxTalks || []
        });
    }
}

function reset(resetCompanies = false) {
    return {
        type: actionTypes.RESET,
        resetCompanies
    };
}

function add(pxTalk) {
    return dispatch => {
        dispatch({
            type: actionTypes.ADD_PXTALK,
            pxTalk
        });
        dispatch(formsActions.reset());

    };
}

function levelTypeChange(companyId, levelType) {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.LEVEL_TYPE_CHANGE,
            change: { companyId, levelType }
        });

        const { pxTalks } = getState().changePxTalk;
        const pxTalk = pxTalks.find(p => p.company.id === companyId);

        dispatch(normalizeTalk(companyId, pxTalk.talk, levelType));
    };
}

function deletePxTalk(index) {
    return {
        type: actionTypes.DELETE_PX_TALK,
        index
    };
}

function talkChange(companyId, talk) {
    return {
        type: actionTypes.TALK_CHANGE,
        change: { companyId, talk: pxTalkUtils.normalizeDecimalPlaces(talk) }
    };
}

function normalizeTalk(companyId, talk, levelType) {
    return (dispatch, getState) => {
        if (stringUtils.isNullOrWhiteSpace(talk)) {
            return dispatch(setPxTalkError(companyId, errorMessages.empty));
        } if (talk.match(',')) {
            return dispatch(setPxTalkError(companyId, errorMessages.invalidValue));
        }

        dispatch(normalizing(true));

        pxTalkService
            .normalize(talk, levelType)
            .then(setNormalizedLevel)
            .catch(setNormalizeError)
            .finally(() => dispatch(normalizing(false)));

        function setNormalizedLevel(normalized) {
            const { pxTalks } = getState().changePxTalk;
            if (pxTalks && pxTalks.length) {
                dispatch(normalizedLevelChange(companyId, normalized));
            }
        }

        function setNormalizeError(error) {
            const responseMessage = error && error.message && jsonUtils.tryParse(error.message);
            const errorMessage = responseMessage ? responseMessage.error : errorMessages.invalidValue;

            dispatch(setPxTalkError(companyId, errorMessage));
        }
    };
}

function normalizedLevelChange(companyId, normalizedLevel = {}) {
    return (dispatch, getState) => {
        if (!getState().changePxTalk.pxTalks) return;

        const updateValues = { companyId, normalizedLevel: '', talk: '' };
        if (normalizedLevel !== null && numericUtils.isNumber(normalizedLevel.parsedLevel)) {
            updateValues.normalizedLevel = normalizedLevel.parsedLevel.toFixed(2);
            updateValues.talk = normalizedLevel.level
        }

        dispatch({
            type: actionTypes.NORMALIZED_LEVEL_CHANGE,
            change: updateValues
        });

        const { pxTalks } = getState().changePxTalk;
        const pxTalk = pxTalks.find(p => p.company.id === companyId);

        if (pxTalk.error) {
            const error = validatePxTalk(pxTalk);
            if (pxTalk.error !== error) {
                dispatch(setPxTalkError(companyId, error));
            }
        }
    }
}

function validate() {
    return (dispatch, getState) => {
        if (!getState().changePxTalk.pxTalks) return;

        const pxTalks = getState().changePxTalk.pxTalks.map(p => {
            const error = validatePxTalk(p);
            return p.error ? p : { ...p, error }
        });

        dispatch({ type: actionTypes.VALIDATION_RESULT, pxTalks });
    };
}

function validatePxTalk(pxTalk) {
    let error;

    if (pxTalk.talk === '' || pxTalk.talk == null) {
        error = errorMessages.empty;
    } else if (!pxTalk.normalizedLevel || pxTalk.talk.match(',')) {
        error = errorMessages.invalidValue;
    }

    return error;
}

function setPxTalkError(companyId, errorMessage) {
    return {
        type: actionTypes.TALK_ERROR,
        error: { companyId, errorMessage }
    };
}

function normalizing(isNormalizing) {
    return {
        type: actionTypes.NORMALIZING,
        isNormalizing
    };
}

function waitNormalizing(normalizedCallback) {
    return async (dispatch, getState) => {
        await wait(() => !getState().changePxTalk.isNormalizing);
        dispatch(validate());
        normalizedCallback();
    };
}
