import { createAsyncAction, ActionType, createAction } from 'typesafe-actions';
import { HelpResourceCategory } from '../types/help-resources/HelpResourceCategory';
import { HelpResourceArticle } from '../types/help-resources/HelpResourceArticle';
import { HelpResourceSearchItem } from '../types/help-resources/HelpResourceSearch';

export type TResourcesActions = ActionType<
    typeof fetchCategories |
    typeof fetchSection |
    typeof fetchBySearch |
    typeof loadMoreSearch |
    typeof resetSection |
    typeof resetSearch
>;

interface SuccessSectionDetails {
    categoryName: string;
    sectionName: string;
    articles: HelpResourceArticle[];
}

const fetchSection = createAsyncAction(
    'help-resources/REQUEST_SECTION_DETAILS',
    'help-resources/SUCCESS_SECTION_DETAILS',
    'help-resources/FAILURE_SECTION_DETAILS'
)<{ sectionId: number }, SuccessSectionDetails, Error>()

const fetchCategories = createAsyncAction(
    'help-resources/REQUEST_CATEGORIES',
    'help-resources/SUCCESS_CATEGORIES',
    'help-resources/FAILURE_CATEGORIES'
)<undefined, HelpResourceCategory[], Error>()

const fetchBySearch = createAsyncAction(
    'help-resources/REQUEST_SEARCH',
    'help-resources/SUCCESS_SEARCH',
    'help-resources/FAILURE_SEARCH'
)<{ search: string, categoryId: number | null }, { results: HelpResourceSearchItem[], totalRecordNumber: number }, Error>()

const loadMoreSearch = createAsyncAction(
    'help-resources/REQUEST_LOAD_MORE_SEARCH',
    'help-resources/SUCCESS_LOAD_MORE_SEARCH',
    'help-resources/FAILURE_LOAD_MORE_SEARCH'
)<{ search: string, categoryId: number | null, page: number }, { results: HelpResourceSearchItem[], totalRecordNumber: number }, Error>()

const resetSection = createAction('help-resources/RESET_SECTION');
const resetSearch = createAction('help-resources/RESET_SEARCH');

export const helpResourcesActions = {
    fetchCategories,
    fetchSection,
    fetchBySearch,
    loadMoreSearch,
    resetSection,
    resetSearch
}