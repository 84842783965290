import { createAction } from 'typesafe-actions';

export const onBoardingTooltipNav = createAction(
    'ON_BOARDING_TOOLTIP_NAV',
    resolve => (page, next) => resolve({ page, next })
);

export const onBoardingTooltipClose = createAction(
    'ON_BOARDING_TOOLTIP_CLOSE',
    resolve => (page) => resolve({ page })
);

export const onBoardingTooltipGotIt = createAction(
    'ON_BOARDING_TOOLTIP_GOT_IT',
    resolve => (page, doNotShowSupportPopup) => resolve({ page, doNotShowSupportPopup })
);

export const onBoardingTooltipSetVisible = createAction(
    'ON_BOARDING_TOOLTIP_SET_VISIBLE',
    resolve => (status) => resolve({ status })
);

export const onBoardingTooltipSetCountRequest = createAction(
    'ON_BOARDING_TOOLTIP_SET_COUNT_REQUEST',
    resolve => (data) => resolve({ data })
);

export const onBoardingTooltipSetCountSuccess = createAction(
    'ON_BOARDING_TOOLTIP_SET_COUNT_SUCCESS',
    resolve => () => resolve()
);

export const onBoardingTooltipSetCountFailure = createAction(
    'ON_BOARDING_TOOLTIP_SET_COUNT_FAILURE',
    resolve => () => resolve()
);

export const onBoardingTooltipSetVisibleSupportPopup = createAction(
    'ON_BOARDING_TOOLTIP_SET_VISIBLE_SUPPORT_POPUP',
    resolve => (status) => resolve({ status })
);

export const onBoardingReset = createAction(
    'ON_BOARDING_TOOLTIP_RESET'
);
