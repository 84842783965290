import { ActionType, createAsyncAction } from 'typesafe-actions';
import { CompanyMember } from '../types/company/CompanyMember';

const fetchCompanyMembers = createAsyncAction(
    'company-members/COMPANY_MEMBERS_REQUEST',
    'company-members/COMPANY_MEMBERS_SUCCESS',
    'company-members/COMPANY_MEMBERS_FAILURE'
)<{ companyId: number }, { companyId: number, members: CompanyMember[] }, { companyId: number, error: Error }>()

export type TCompanyMembersActions = ActionType<typeof companyMembersActions[keyof typeof companyMembersActions]>

export const companyMembersActions = {
    fetchCompanyMembers
}
