import { createAction } from 'typesafe-actions';
import { SettlementAgentClientAgreement } from '../types/bid-as-dealer/SettlementAgentClientAgreement';
import { UpdateSettlementAgentAgreementStatus } from '../types/bid-as-dealer/UpdateSettlementAgentAgreementStatus';
import { RequestState } from '../constants/request-state';
import { CurrencyRate } from '../types/currency/CurrencyRate';

const fetchListRequest = createAction(
    'clientCompanies/FETCH_LIST_REQUEST',
);

const fetchListSuccess = createAction(
    'clientCompanies/FETCH_LIST_SUCCESS',
    resolve => (data: SettlementAgentClientAgreement[]) => resolve({ data })
);

const fetchListFailure = createAction(
    'clientCompanies/FETCH_LIST_FAILURE'
);

const setSelectedAgreement = createAction(
    'clientCompanies/ON_SELECTED_COMPANY_FOR_AFFIRM',
    resolve => (companyId: number) => resolve({ companyId })
);

const resetSelectedAgreement = createAction(
    'clientCompanies/ON_UN_SELECT_COMPANY_FOR_AFFIRM'
);

const updateAgreementRequest = createAction(
    'clientCompanies/UPDATE_REQUEST_STATUS_REQUEST',
    resolve => (requestData: UpdateSettlementAgentAgreementStatus) => resolve({ requestData })
);

const updateAgreementSuccess = createAction(
    'clientCompanies/UPDATE_REQUEST_STATUS_SUCCESS',
    resolve => (assetManagerId: number) => resolve( { assetManagerId })
);

const updateAgreementFailure = createAction(
    'clientCompanies/UPDATE_REQUEST_STATUS_FAILURE',
    resolve => (assetManagerId: number) => resolve( { assetManagerId })
);

const setSearchString = createAction(
    'clientCompanies/SET_SEARCH_STRING',
    resolve => (searchString: string) => resolve({ searchString })
);

const fetchPendingAgreementCountRequest = createAction(
    'clientCompanies/FETCH_PENDING_REQUESTS_COUNT_REQUEST'
);

const fetchPendingAgreementsCountSuccess = createAction(
    'clientCompanies/FETCH_PENDING_REQUESTS_COUNT_SUCCESS',
    resolve => (count: number) => resolve({ count })
);

const fetchPendingAgreementsCountFailure = createAction('clientCompanies/FETCH_PENDING_REQUESTS_COUNT_FAILURE');

const hideActivePanels = createAction('clientCompanies/HIDE_ACTIVE_PANELS');

const setVisibleUpdateRatePopup = createAction(
    'clientCompanies/SET_VISIBLE_UPDATE_RATE_POPUP',
    resolve => (visible: boolean) => resolve({ visible })
);
const getCurrencyRate = createAction('clientCompanies/GET_CURRENCY_RATE');
const setResultGetCurrencyRate = createAction(
    'clientCompanies/SET_RESULT_GET_CURRENCY_RATE',
    resolve => (requestState: RequestState, rate: CurrencyRate[]) => resolve({ requestState, rate })
);

const updateCurrencyRate = createAction(
    'clientCompanies/UPDATE_CURRENCY_RATE',
    resolve => (rate: CurrencyRate) => resolve({ rate })
);
const setResultUpdateCurrencyRate = createAction(
    'clientCompanies/SET_RESULT_UPDATE_CURRENCY_RATE',
    resolve => (requestState: RequestState, rate?: CurrencyRate) => resolve({ requestState, rate })
);

const reset = createAction(
    'clientCompanies/RESET'
);

export const clientsCompaniesActions = {
    fetchListRequest,
    fetchListSuccess,
    fetchListFailure,
    setSelectedAgreement,
    resetSelectedAgreement,
    updateAgreementRequest,
    updateAgreementSuccess,
    updateAgreementFailure,
    setSearchString,
    fetchPendingAgreementCountRequest,
    fetchPendingAgreementsCountSuccess,
    fetchPendingAgreementsCountFailure,
    hideActivePanels,
    setVisibleUpdateRatePopup,
    getCurrencyRate,
    setResultGetCurrencyRate,
    updateCurrencyRate,
    setResultUpdateCurrencyRate,
    reset,
};
