import { ActionType, createAction } from "typesafe-actions"
import { RequestState } from "../../constants/request-state";
import { TransactionSlim } from "../../types/amr-pipeline/models/TransactionSlim";

const request = createAction('entities/latestTransaction/REQUEST',
    resolve => (tickers: string[]) => resolve({ tickers })
);
const response = createAction('entities/latestTransaction/RESPONSE',
    resolve => (requestState: RequestState, transactions: TransactionSlim[] = []) =>
        resolve({ requestState, transactions })
);
const reset = createAction('entities/latestTransaction/RESET');

export const latestTransactionActions = {
    request,
    response,
    reset
}

export type TLatestTransactionActionType =
    ActionType<typeof latestTransactionActions[keyof typeof latestTransactionActions]>
