import { searchSecuritiesActions as actionTypes, constants } from '../constants';
import { errorActions } from '../actions';
import { bwicService } from '../services';

export const searchSecuritiesActions = {
    searchTermChange,
    resetSearchLookup,
    applyLookupItem,
    removeSearchItem,
    moveBack,
    moveForward,
    removeCurrentItem,
    addSearchItem,
    reset
};

let loadSearchLookupTimeout = null;

function searchTermChange(searchTerm = '', showLookup = true) {
    return (dispatch, getState) => {
        dispatch({ type: actionTypes.SEARCH_TERM_CHANGE, search: { searchTerm } });

        const trimmedSearchTerm = searchTerm.trim();

        if (trimmedSearchTerm && trimmedSearchTerm.length > constants.searchTermAcceptedLength) {
            const { searchTermCache, lookupCache } = getState().searchSecurities;
            const searchTermLowerCase = trimmedSearchTerm.toLowerCase();

            if (searchTermCache &&
                searchTermLowerCase.startsWith(searchTermCache.toLowerCase())) {
                dispatch(searchInLookupCache(searchTermLowerCase, lookupCache));
            } else if (showLookup) {
                if (loadSearchLookupTimeout) {
                    clearTimeout(loadSearchLookupTimeout)
                }
                loadSearchLookupTimeout = setTimeout(() => dispatch(loadSearchLookup(trimmedSearchTerm)), 400);
            }
        } else {
            dispatch(resetSearchLookup());
        }
    };
}

function searchInLookupCache(searchTerm, lookupCache) {
    return {
        type: actionTypes.SEARCH_LOOKUP,
        lookupData: lookupCache.filter(l =>
            (l.isin && l.isin.toLowerCase().startsWith(searchTerm)) ||
            (l.cusip && l.cusip.toLowerCase().startsWith(searchTerm)) ||
            (l.ticker && l.ticker.toLowerCase().startsWith(searchTerm))
        )
    };
}

function loadSearchLookup(searchTerm) {
    return dispatch => {
        bwicService
            .searchSecurities(searchTerm, true)
            .then(success)
            .catch(e => dispatch(errorActions.error(e)));

        function success(securities) {
            dispatch({
                type: actionTypes.SEARCH_LOOKUP_CACHE,
                lookupData: securities.map(s => ({
                    id: s.id,
                    isin: s.isin,
                    cusip: s.cusip,
                    isinCusip: s.isinCusip,
                    ticker: s.ticker
                })),
                searchTerm
            });
        }
    };
}

function applyLookupItem(lookupItem) {
    return (dispatch, getState) => {
        const { searchSecurities } = getState();
        let searchTerm;
        if (typeof lookupItem === 'object') {
            const stateSearchTerm = searchSecurities.searchTerm.toLowerCase();
            if (lookupItem.isin && lookupItem.isin.toLowerCase().startsWith(stateSearchTerm)) {
                searchTerm = lookupItem.isin;
            } else if (lookupItem.cusip && lookupItem.cusip.toLowerCase().startsWith(stateSearchTerm)) {
                searchTerm = lookupItem.cusip;
            } else {
                searchTerm = lookupItem.ticker;
            }
        } else {
            searchTerm = lookupItem.trim();
        }

        if (searchTerm && !searchSecurities.searchTermItems.includes(searchTerm)) {
            dispatch(addSearchItem(searchTerm));
        }
        dispatch(searchTermChange(''));
        dispatch(resetSearchLookup());
    };
}

function moveBack() {
    return {
        type: actionTypes.SECURITY_SEARCH_MOVE_BACK,
    };
}

function moveForward() {
    return {
        type: actionTypes.SECURITY_SEARCH_MOVE_FORWARD,
    };
}
function addSearchItem(item) {
    return {
        type: actionTypes.SECURITY_SEARCH_ITEM_ADD,
        payload: { item }
    };
}

function removeSearchItem(index) {
    return {
        type: actionTypes.SECURITY_SEARCH_ITEM_REMOVE,
        payload: { index }
    };
}

function removeCurrentItem() {
    return {
        type: actionTypes.SECURITY_SEARCH_REMOVE_CURRENT_ITEM,
    };
}

function resetSearchLookup() {
    return {
        type: actionTypes.SEARCH_LOOKUP,
        lookupData: []
    };
}

function reset() {
    return {
        type: actionTypes.RESET
    };
}
