import { createAction } from 'typesafe-actions';
import { BrokerDealerCompanySlim } from '../types/company/BrokerDealerCompanySlim';
import { SettlementAgentAgreement } from '../types/bid-as-dealer/SettlementAgentAgreement';
import { DealerListFilterOption } from '../types/state/DealerListState';

const selectDealers = createAction(
    'newBwicDealers/SET_SELECTED_DEALER',
    resolve => (selectedDealers: { [dealerId: number]: boolean }) => resolve({ selectedDealers })
);

const selectDealer = createAction(
    'newBwicDealers/SET_DEALER_SELECTED',
    resolve => (dealerId: number) => resolve({ dealerId })
);

const selectDealersByDefault = createAction(
    'newBwicDealers/SELECT_DEALERS_BY_DEFAULT',
    resolve => (dealers: BrokerDealerCompanySlim[]) => resolve({ dealers })
);

const resetSelectedByDefaultFlag = createAction('newBwicDealers/SELECT_DEALERS_BY_DEFAULT_RESET');

const sellerBidAsDealerAgreementsPushDataReceived = createAction(
    'newBwicDealers/BID_AS_DEALER_AGREEMENT_PUSH_DATA_RECEIVED',
    resolve => (agreement: SettlementAgentAgreement) => resolve({ agreement })
);

const updateFilterOptions = createAction(
    'newBwicDealers/UPDATE_FILTER_OPTIONS',
    resolve => (filterOption: DealerListFilterOption) => resolve({ filterOption })
);

const clearFilterOptions = createAction('newBwicDealers/CLEAR_FILTER_OPTIONS');

const setSearchTerm = createAction(
    'newBwicDealers/SET_SEARCH_TERM',
    resolve => (searchTerm: string) => resolve({ searchTerm })
);

const reset = createAction('newBwicDealers/RESET');

const setSelectPlatformDealersPopupVisible = createAction(
    'newBwicDealers/SET_SELECT_PLATFORM_DEALERS_POPUP_VISIBLE',
    resolve => (visible: boolean) => resolve({ visible })
);

export const newBwicDealersActions = {
    selectDealersByDefault,
    selectDealers,
    selectDealer,
    sellerBidAsDealerAgreementsPushDataReceived,
    updateFilterOptions,
    clearFilterOptions,
    setSearchTerm,
    resetSelectedByDefaultFlag,
    reset,
    setSelectPlatformDealersPopupVisible
};
