import { createAction } from "typesafe-actions";
import { Contact } from '../types/company/Contact';
import { SaveContactModel } from '../types/company/Contact';

const setDealerExpanded = createAction(
    'dealer-list/SET_DEALER_EXPANDED',
    resolve => (dealerId: number) => resolve({ dealerId })
);
const expandDealers = createAction(
    'dealer-list/SET_DEALER_EXPANDED_ALL',
    resolve => (dealers: { [key: number]: boolean }, expandedAll: boolean) => resolve({ dealers, expandedAll })
);
const toggleExpandedAllDealers = createAction('dealer-list/TOGGLE_EXPAND_ALL_DEALERS');
const setExpandAllDealers = createAction('dealer-list/SET_EXPAND_ALL_DEALERS',
    resolve => (expandedAll: boolean) => resolve(expandedAll)
);
const setEditableContact = createAction(
    'dealer-list/SET_EDITABLE_CONTACT',
    resolve => (contact: Contact, dealerName?: string) => resolve({ contact, dealerName })
);
const editableContactUpdateDealer = createAction(
    'dealer-list/EDITABLE_CONTACT_UPDATE_DEALER',
    resolve => (dealerName: string) => resolve({ dealerName })
);
const editableContactSave = createAction(
    'dealer-list/EDITABLE_CONTACT_SAVE',
    resolve => (contact: SaveContactModel) => resolve({ contact })
);
const editableContactSetRequestStatus = createAction(
    'dealer-list/EDITABLE_CONTACT_SET_REQUEST_STATUS',
    resolve => (requestStatus: boolean) => resolve({ requestStatus })
);
const deleteContact = createAction(
    'dealer-list/DELETE_CONTACT',
    resolve => (contactId: number) => resolve({ contactId })
);
const contactInviteToJoin = createAction(
    'dealer-list/CONTACT_INVITE_TO_JOIN',
    resolve => (contactId: number) => resolve({ contactId })
);
const distributionListInviteToJoin = createAction(
    'dealer-list/DISTRIBUTION_LIST_INVITE_TO_JOIN',
    resolve => (dealerId: number) => resolve({ dealerId })
);
const headOfTradingInviteToJoin = createAction(
    'dealer-list/HEAD_OF_TRADING_INVITE_TO_JOIN',
    resolve => (dealerId: number) => resolve({ dealerId })
);
const contactInviteToJoinSetRequestStatus = createAction(
    'dealer-list/CONTACT_INVITE_TO_JOIN_SET_REQUEST_STATUS',
    resolve => (contactId: number, requestStatus: boolean) => resolve({ contactId, requestStatus })
);
const resetEditableContact = createAction('dealer-list/RESET_EDITABLE_CONTACT');
const reset = createAction('dealer-list/RESET');

export const dealerListActions = {
    setDealerExpanded,
    expandDealers,
    toggleExpandedAllDealers,
    setExpandAllDealers,
    setEditableContact,
    editableContactUpdateDealer,
    editableContactSave,
    editableContactSetRequestStatus,
    resetEditableContact,
    deleteContact,
    contactInviteToJoin,
    distributionListInviteToJoin,
    headOfTradingInviteToJoin,
    contactInviteToJoinSetRequestStatus,
    reset
};
