import { ActionType, createAction } from "typesafe-actions";
import { RequestState } from "../../constants/request-state";
import { PartiesCompanies } from "../../types/state/entities/PartiesState";

const request = createAction("entities/parties/REQUEST");
const requestResult = createAction(
    "entities/parties/REQUEST_RESULT",
    (resolve) => (requestState: RequestState, items?: PartiesCompanies) =>
        resolve({
            requestState,
            items,
        })
);

export type TPartiesActionType = ActionType<
    (typeof partiesActions)[keyof typeof partiesActions]
>;

export const partiesActions = {
    request,
    requestResult,
};

