import {
    changeColorActions as actionTypes,
    errorMessages,
    pxTalkLevelTypes,
    levelSpecifications,
    colorTypes
} from '../constants';
import { pxTalkService } from '../services';
import { jsonUtils, wait } from '../utils';


export const changeColorActions = {
    init,
    reset,
    setDm,
    tradedChange,
    levelSpecificationChange,
    levelChange,
    normalizeLevel,
    levelTypeChange,
    validate,
    waitNormalizing
}

function init(ticker, color, dm) {
    return {
        type: actionTypes.SET_COLOR,
        data: {
            ticker,
            color: color
                ? {
                    ...color,
                    levelSpecificationType: color.levelSpecificationType || levelSpecifications.cover.key
                }
                : getEmptyColor(),
            dm
        }
    };
}

function setDm(dm) {
    return {
        type: actionTypes.CHANGE_COLOR_SET_DM,
        payload: { dm }
    };
}

function getEmptyColor() {
    return {
        isNew: true,
        levelType: pxTalkLevelTypes.price.key,
        levelSpecificationType: levelSpecifications.cover.key,
        traded: true,
        colorType: colorTypes.none.key
    };
}

function reset() {
    return {
        type: actionTypes.RESET
    };
}

function tradedChange(traded) {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.TRADED_CHANGE,
            traded
        });

        const { errors } = getState().changeColor;

        if (errors.color) {
            dispatch(setColorError());
        }
    };
}

function levelSpecificationChange(levelSpecification) {
    return (dispatch, getState) => {
        const { errors } = getState().changeColor;

        dispatch({
            type: actionTypes.LEVEL_SPECIFICATION_CHANGE,
            levelSpecification
        });

        if (errors.color) {
            dispatch(setColorError());
        }
    };
}

function levelChange(level) {
    return {
        type: actionTypes.LEVEL_CHANGE,
        level
    };
}

function normalizeLevel(level, levelType) {
    return (dispatch, getState) => {
        if (level !== '' && level != null) {
            dispatch(normalizing(true));
            pxTalkService
                .normalize(level, levelType)
                .then(setNormalizedLevel)
                .then(() => dispatch(normalizing(false)))
                .catch((e = {}) => {
                    setNormalizedLevel(null)
                    const errorMessage = jsonUtils.tryParse(e.message);
                    if (errorMessage && errorMessage.error) {
                        dispatch(setLevelError(errorMessage.error));
                    }
                });
        } else {
            setNormalizedLevel(null);
        }

        function setNormalizedLevel(normalized) {
            const { color } = getState().changeColor;
            if (color) { //change color state is not reset
                dispatch(normalizedLevelChange(normalized));
            }
        }
    };
}

function normalizedLevelChange(normalizedLevel) {
    return (dispatch, getState) => {
        const updateColor = { normalizedLevel: '', level: '' };
        if (normalizedLevel !== null) {
            updateColor.normalizedLevel = normalizedLevel.parsedLevel;
            updateColor.level = normalizedLevel.level
        }
        dispatch({
            type: actionTypes.NORMALIZED_LEVEL_CHANGE,
            ...updateColor
        });

        const { color, errors } = getState().changeColor;

        if (errors.level) {
            const error = validateLevel(color);
            if (error !== errors.level) {
                dispatch(setLevelError(error));
            }
        }
    };
}

function validateLevel(color) {
    let error;
    if (color.level === '' || color.level == null) {
        error = null;
    } else if (!color.normalizedLevel) {
        error = errorMessages.invalidValue;
    }

    return error;
}

function validateColor(color) {
    const tradedBest = color.traded && color.levelSpecificationType === levelSpecifications.best.key;
    const dntCover = !color.traded && color.levelSpecificationType === levelSpecifications.cover.key;

    if (tradedBest || dntCover) {
        return errorMessages.invalidColor;
    }

    return null;
}

function setLevelError(error) {
    return {
        type: actionTypes.SET_LEVEL_ERROR,
        error
    }
}

function setColorError() {
    return (dispatch, getState) => {
        const { color } = getState().changeColor;
        const colorError = validateColor(color);

        if (color.error !== colorError) {
            dispatch({
                type: actionTypes.SET_COLOR_ERROR,
                error: colorError
            });
        }
    };
}

function levelTypeChange(levelType) {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.LEVEL_TYPE_CHANGE,
            levelType
        });

        const { color } = getState().changeColor;
        dispatch(normalizeLevel(color.level, levelType));
    };
}

function validate() {
    return (dispatch, getState) => {
        const { color } = getState().changeColor;
        const levelError = validateLevel(color);

        dispatch(setLevelError(levelError));

        if (!levelError) {
            dispatch(setColorError());
        }
    };
}

function normalizing(isNormalizing) {
    return {
        type: actionTypes.NORMALIZING,
        isNormalizing
    };
}

function waitNormalizing(normalizedCallback) {
    return async (dispatch, getState) => {
        await wait(() => !getState().changeColor.isNormalizing);

        const { color } = getState().changeColor;
        if (color) {
            dispatch(validate());
            normalizedCallback();
        }
    };
}
