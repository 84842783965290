import { submitActions as actionTypes } from '../constants';
import { errorActions } from '.';
import { companiesService } from '../services';

export const submitActions = {
    submitState,
    init,
    reset
}

function init() {
    return async (dispatch, getState) => {
        const { userCompany } = getState().submit;

        if (userCompany) return;

        try {
            const company = await companiesService.getCurrentUserCompany();

            dispatch({
                type: actionTypes.STORE_USER_COMPANY,
                company
            });
        }
        catch (e) {
            dispatch(errorActions.criticalError(e));
        }
    };
}

function submitState(state, bwicReferenceName) {
    return { type: actionTypes.SUBMIT_STATE, state, bwicReferenceName };
}

function reset() {
    return { type: actionTypes.RESET };
}
