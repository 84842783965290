import { createAction } from "typesafe-actions";
import { BwicPosition } from "../types/bwic/BwicPosition";
import { CompanySlim } from "../types/company/CompanySlim";
import { TimeSpan } from "../types/primitives/TimeSpan";
import { BlastMessageRecipientLevelState, BlastMessageRecipientsState } from "../types/state/BlastMessageState";

const show = createAction('blastMessage/SHOW_PANEL');
const hide = createAction('blastMessage/HIDE_PANEL');
const messageTypeChange = createAction('blastMessage/MESSAGE_TYPE_CHANGE',
    resolve => (messageType: number) => resolve({ messageType })
);
const recipientLevelChange = createAction('blastMessage/RECIPIENT_LEVEL_CHANGE',
    resolve => (recipientLevel: BlastMessageRecipientLevelState) => resolve({ recipientLevel })
);

const editMinutesAboutToEnd = createAction('blastMessage/EDIT_MINUTES_ABOUT_TO_END',
    resolve => (stage1AboutToEndTimeout: TimeSpan) => resolve({ stage1AboutToEndTimeout })
);

const setError = createAction('blastMessage/SET_ERROR',
    resolve => (error: string) => resolve({ error })
);
const storeRecipients = createAction('blastMessage/STORE_RECIPIENTS',
    resolve => (recipients: BlastMessageRecipientsState) => resolve({ recipients })
);
const recipientSelectedChange = createAction('blastMessage/RECIPIENT_SELECTED_CHANGE',
    resolve => (companyIdentifier: string) => resolve({ companyIdentifier })
);
const send = createAction('blastMessage/SEND',
    resolve => (
        blastMessageRecipients: BlastMessageRecipientsState,
        bwicParticipants: CompanySlim[],
        securities: BwicPosition[],
    ) => resolve({ blastMessageRecipients, bwicParticipants, securities })
);
const sending = createAction('blastMessage/SEND_STATE',
    resolve => (isSending: boolean) => resolve({ isSending })
);
const selectAllRecipientsChange = createAction('blastMessage/SELECT_ALL_RECIPIENTS',
    resolve => (isSelectAllRecipients: boolean) => resolve({ isSelectAllRecipients })
);
const toggleSelectAllParticipantsFlag = createAction('blastMessage/TPGGLE_SELECT_ALL_FLAG');

export const blastMessageActions = {
    show,
    hide,
    messageTypeChange,
    recipientLevelChange,
    storeRecipients,
    recipientSelectedChange,
    send,
    sending,
    selectAllRecipientsChange,
    toggleSelectAllParticipantsFlag,
    editMinutesAboutToEnd,
    setError
};
