import { createAction } from 'typesafe-actions';
import { Company } from '../types/management/Company';

const show = createAction('changeSubscriptionActions/SHOW', resolve => (company: Company) => resolve({ company }));
const hide = createAction('changeSubscriptionActions/HIDE');
const setSubscriptionSavingFlag = createAction(
    'changeSubscriptionActions/SAVING_FLAG',
    resolve => (isSubscriptionSavingFlag: boolean) => resolve({ isSubscriptionSavingFlag }),
);
const saveSubscription = createAction(
    'changeSubscriptionActions/SAVE_SUBSCRIPTION',
    resolve => (companyId: number, subscriptionType: string, subscriptionExpiration?: Date) =>
        resolve({ companyId, subscriptionType, subscriptionExpiration }),
);

export const changeSubscriptionActions = {
    show,
    hide,
    setSubscriptionSavingFlag,
    saveSubscription,
};
