import { inviteToJoinActionTypes } from '../constants';
import { contactsService } from '../services';

function setVisiblePopup(visible) {
    return dispatch => {
        dispatch({
            type: inviteToJoinActionTypes.INVITE_TO_JOIN_TOGGLE_VISIBLE_POPUP,
            payload: { visible }
        })
    }
}

function sendInviteToJoin(message) {
    return dispatch => {
        dispatch({ type: inviteToJoinActionTypes.INVITE_TO_JOIN_INVITE_ALL_REQUEST });
        contactsService.inviteAllToJoin(message)
            .then(() => dispatch({ type: inviteToJoinActionTypes.INVITE_TO_JOIN_INVITE_ALL_SUCCESS }))
            .catch((e) => {
                dispatch({ type: inviteToJoinActionTypes.INVITE_TO_JOIN_INVITE_ALL_FAILURE, payload: e })
            })
    }
}

export const inviteToJoinActions = {
    setVisiblePopup,
    sendInviteToJoin
};
