import { createAction } from 'typesafe-actions';

export const blotterInit = createAction('BLOTTER_INIT');
export const blotterReset = createAction('BLOTTER_RESET');
export const blotterFiltersReset = createAction('BLOTTER_FILTERS_RESET');
export const blotterSetFilterDefaults = createAction('blotter/DEFAULT_FILTER');

export const blotterToggleConfirmed = createAction('BLOTTER_TOGGLE_CONFIRMED');
export const blotterTogglePending = createAction('BLOTTER_TOGGLE_PENDING');
export const blotterToggleRejected = createAction('BLOTTER_TOGGLE_REJECTED');

export const blotterToggleAllocationStatusUnsettled = createAction('blotter/TOGGLE_UNSETTLED');
export const blotterToggleAllocationStatusPendingSettlement = createAction('blotter/TOGGLE_PENDING_SETTLEMENT');
export const blotterToggleAllocationStatusPendingAllocation = createAction('blotter/TOGGLE_PENDING_ALLOCATION');
export const blotterToggleAllocationStatusPendingProceeds = createAction('blotter/TOGGLE_PENDING_PROCEEDS');
export const blotterToggleAllocationStatusSettled = createAction('blotter/TOGGLE_SETTLED');

export const blotterSetCurrency = createAction(
    'BLOTTER_SET_CURRENCY',
    resolve => currency => resolve({ currency })
);
export const blotterResetCurrency = createAction('blotter/CURRENCY_FILTER_RESET');
export const blotterSelectAllCurrencies = createAction('blotter/CURRENCY_FILTER_SELECT_ALL');

export const blotterDateFilterChange = createAction(
    'BLOTTER_DATE_RANGE_FILTER_CHANGED',
    resolve => (dateOption) => resolve({ dateOption })
);
export const blotterCustomDateFilterChange = createAction(
    'BLOTTER_CUSTOM_DATE_RANGE_FILTER_CHANGED',
    resolve => (dateRange) => resolve({ dateRange })
);
export const blotterSettlementDateFilterChange = createAction(
    'blotter/SETTLEMENT_DATE_RANGE_FILTER_CHANGED',
    resolve => (dateOption) => resolve({ dateOption })
);
export const blotterSettlementCustomDateFilterChange = createAction(
    'blotter/SETTLEMENT_CUSTOM_DATE_RANGE_FILTER_CHANGED',
    resolve => (dateRange) => resolve({ dateRange })
);
export const blotterToggleFilterBuy = createAction('BLOTTER_TOGGLE_FILTER_BUY');
export const blotterToggleFilterSell = createAction('BLOTTER_TOGGLE_FILTER_SELL');
export const blotterToggleFilterClient = createAction('BLOTTER_TOGGLE_FILTER_CLIENT');
export const blotterToggleFilterDesc = createAction('BLOTTER_TOGGLE_FILTER_DESC');
export const blotterSearchRequest = createAction('BLOTTER_SEARCH_REQUEST');
export const blotterSearchRequestNextPage = createAction('blotter/REQUEST_NEXT_PAGE',
    resolve => page => resolve({ page })
);
export const blotterSearchSuccess = createAction(
    'BLOTTER_SEARCH_SUCCESS',
    resolve => ({ result, totalRecordNumber }) => resolve({ result, totalRecordNumber })
);
export const blotterSearchFailure = createAction(
    'BLOTTER_SEARCH_FAILURE',
    resolve => error => resolve({ error })
);
export const blotterOrderByChange = createAction('blotter/ORDER_BY_CHANGE',
    resolve => orderByColumn => resolve({ orderByColumn })
);
export const blotterHasTradesRequest = createAction('BLOTTER_HAS_TRADES_REQUEST');
export const blotterHasTradesSuccess = createAction(
    'BLOTTER_HAS_TRADES_SUCCESS',
    resolve => hasTrades => resolve({ hasTrades })
);
export const blotterHasTradesFailure = createAction(
    'BLOTTER_HAS_TRADES_FAILURE',
    resolve => error => resolve({ error })
);
export const blotterExportRequest = createAction('BLOTTER_EXPORT_REQUEST');
export const blotterExportSuccess = createAction('BLOTTER_EXPORT_SUCCESS');
export const blotterExportFailure = createAction('BLOTTER_EXPORT_FAILURE');
export const blotterToggleVcon = createAction(
    'BLOTTER_TOGGLE_VCON',
    resolve => (tradeId, isBuyTrade, value) => resolve({ tradeId, isBuyTrade, value })
);
export const blotterSetVconRequest = createAction(
    'BLOTTER_SET_VCON_REQUEST',
    resolve => (tradeId, isBuyTrade) => resolve({ tradeId, isBuyTrade })
);
export const blotterSetVconSuccess = createAction(
    'BLOTTER_SET_VCON_SUCCESS',
    resolve => (tradeId, isBuyTrade, value) => resolve({ tradeId, isBuyTrade, value })
);
export const blotterSetVconFailure = createAction(
    'BLOTTER_SET_VCON_FAILURE',
    resolve => (tradeId, isBuyTrade) => resolve({ tradeId, isBuyTrade })
);
export const blotterToggleBooked = createAction(
    'BLOTTER_TOGGLE_BOOKED',
    resolve => (tradeId, isBuyTrade, value) => resolve({ tradeId, isBuyTrade, value })
);
export const blotterSetBookedRequest = createAction(
    'BLOTTER_SET_BOOKED_REQUEST',
    resolve => (tradeId, isBuyTrade) => resolve({ tradeId, isBuyTrade })
);
export const blotterSetBookedSuccess = createAction(
    'BLOTTER_SET_BOOKED_SUCCESS',
    resolve => (tradeId, isBuyTrade, value) => resolve({ tradeId, isBuyTrade, value })
);
export const blotterSetBookedFailure = createAction(
    'BLOTTER_SET_BOOKED_FAILURE',
    resolve => (tradeId, isBuyTrade) => resolve({ tradeId, isBuyTrade })
);
