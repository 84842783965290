import { colorDistributionActions as actionTypes, colorDistributionTypes} from '../constants';
import { bwicService } from '../services';
import { errorActions } from './error.actions';
import { TradeStatus } from '../types/trades/TradeStatus';

export const colorDistributionActions = {
    setBwicColorDistribution,
    cancelColorDistribution,
    completeBwic,
    tradedTypeChange,
    dntTypeChange,
    positionColorTypeChange
}

function setBwicColorDistribution(isColorDistribution) {
    return {
        type: actionTypes.COLOR_DISTRIBUTION_SET_BWIC_COLOR_DISTRIBUTION,
        isColorDistribution
    };
}

function setCompleteInProgress(inProgress) {
    return {
        type: actionTypes.COLOR_DISTRIBUTION_COMPLETE_IN_PROGRESS,
        inProgress
    };
}

function cancelColorDistribution(bwicReferenceName) {
    return (dispatch, getState) => {
        const { isCompleteInProgress } = getState().sellerBidding;

        if (!isCompleteInProgress) {
            bwicService
                .setColorDistribution(bwicReferenceName, false)
                .then(success, e => dispatch(errorActions.unexpectedError(e)));
        }

        function success() {
            dispatch(setBwicColorDistribution(false))
            dispatch(reset());
        }
    };
}

function tradedTypeChange(tradedType) {
    return {
        type: actionTypes.COLOR_DISTRIBUTION_TRADED_TYPE_CHANGE,
        tradedType
    };
}

function dntTypeChange(dntType) {
    return {
        type: actionTypes.COLOR_DISTRIBUTION_DNT_TYPE_CHANGE,
        dntType
    };
}

function positionColorTypeChange(positionId, colorType) {
    return {
        type: actionTypes.COLOR_DISTRIBUTION_POSITION_COLOR_TYPE_CHANGE,
        positionId,
        colorType
    };
}

function completeBwic() {
    return (dispatch, getState) => {
        const { bwic } = getState().bidding;
        const { securities, colorDistribution, isCompleteInProgress } = getState().sellerBidding;

        if (isCompleteInProgress) return;

        dispatch(setCompleteInProgress(true));

        const colorDistributionModel =
            securities
                .map(s => ({
                    positionId: s.id,
                    colorDistributionType:
                        s.colorDistribution ||
                        (s.trade && (s.trade.status === TradeStatus.pending || s.trade.status === TradeStatus.affirmed)
                            ? colorDistribution.forTradedType
                            : s?.bids?.length ? colorDistribution.forDNTType: colorDistributionTypes.status.key)
                }));

        bwicService
            .completeBwic(bwic.referenceName, colorDistributionModel)
            .catch(e => dispatch(errorActions.unexpectedError(e)))
            .then(() => dispatch(setCompleteInProgress(false)));
    };
}

function reset() {
    return {
        type: actionTypes.COLOR_DISTRIBUTION_RESET
    };
}
