import {
    contactsSaveActions as actionTypes,
    gridColumns,
    routes,
    contactsSaveViews,
    errorMessages,
    uploadStatus,
    contactConflictTypes,
    constants
} from '../constants';
import { errorActions, gridActions } from '.';
import { companiesService, contactsService } from '../services';
import { nameUtils } from '../utils';
import { history } from '../history';


export const contactsSaveActions = {
    init,
    reset,
    loadCompanies,
    selectCompany,
    resetCompaniesLookup,
    filterCompanies,
    uploadContacts,
    handleClipboardData,
    save,
    saveResolved,
    switchView,
    deleteContact,
    deleteDuplicate,
    backToContactsEdit,
    setCompanyNameByEmailDomain,
}

function init(isEdit) {
    return async dispatch => {
        dispatch(loading(true));
        try {
            const companiesPromise = companiesService.getBrokerDealers();
            const companyDomainsPromise = companiesService.getCompanyDomains();

            const companies = await companiesPromise;
            const companyDomains = await companyDomainsPromise;

            dispatch(loadCompanies(companies));
            dispatch(loadCompanyDomains(companyDomains));

            const columns = gridColumns.contacts().map(c =>
                c.name === gridColumns.email.name
                    ? { ...c, updateDependencyColumnsCallback: setCompanyNameByEmailDomain }
                    : c
            );

            if (isEdit) {
                const allContacts = await contactsService.getContacts();
                const contacts = allContacts.filter(c => !c.readonly && companies.some(company => company.id === c.companyId));

                contacts.forEach(c => c.company = companies.find(company => company.id === c.companyId).name)

                dispatch(storeInitialContacts(contacts));
                dispatch(gridActions.init(contacts, columns));
            } else {
                dispatch(gridActions.init([], columns));
            }

            dispatch(setEditMode(isEdit));
        }
        catch (e) {
            dispatch(errorActions.criticalError(e));
        } finally {
            dispatch(loading(false));
        }
    };
}

function loading(isLoading) {
    return {
        type: actionTypes.CONTACT_SAVE_LOADING,
        isLoading
    };
}

function storeInitialContacts(contacts) {
    return {
        type: actionTypes.INITIAL_CONTACTS,
        contacts
    }
}

function setEditMode(isEdit) {
    return {
        type: actionTypes.EDIT_MODE,
        isEdit
    }
}

function reset() {
    return dispatch => {
        dispatch({ type: actionTypes.RESET })
        dispatch(gridActions.reset());
        dispatch(errorActions.resetError());
    };
}

function loadCompanies(companies) {
    return {
        type: actionTypes.LOAD_COMPANIES,
        companies
    };
}

function loadCompanyDomains(companyDomains) {
    return {
        type: actionTypes.LOAD_COMPANY_DOMAINS,
        companyDomains
    };
}

function selectCompany(company) {
    return dispatch => {
        dispatch(gridActions.editing(company.name));
        dispatch(gridActions.applyEdit());
        dispatch(resetCompaniesLookup());
    };
}

function resetCompaniesLookup() {
    return {
        type: actionTypes.COMPANIES_LOOKUP,
        lookup: {
            companies: [],
            searchTerm: ''
        }
    };
}

function filterCompanies(searchTerm) {
    return (dispatch, getState) => {
        const { companies, companiesLookup } = getState().contactsSave;

        if (searchTerm.length) {
            const searchTermLowerCase = searchTerm.toLowerCase();
            dispatch({
                type: actionTypes.COMPANIES_LOOKUP,
                lookup: {
                    searchTerm,
                    companies: companies.filter(s =>
                        s.name.toLowerCase().startsWith(searchTermLowerCase))
                }
            });

        } else if (companiesLookup.searchTerm) {
            dispatch(resetCompaniesLookup());
        }
    };
}

function uploadContacts(file) {
    return (dispatch, getState) => {
        const { position, upload } = getState().grid;

        if (upload.status === uploadStatus.uploading) {
            return;
        }

        const extension = (file.name.split('.').pop() || '').toLowerCase();

        if (extension !== 'csv' && extension !== 'xlsx') {
            dispatch(errorActions.error(
                null,
                errorMessages.invalidFileType,
                'Invalid file type.'
            ));
            return;

        } else if (file.size > constants.gridFileUploadMaximumSize * 1024 * 1024) {
            dispatch(errorActions.error(
                null,
                errorMessages.fileSizeLimitExceeded(constants.gridFileUploadMaximumSize),
                'The file is too big.'
            ));
            return;
        }

        if (position.editing) {
            dispatch(gridActions.applyEdit());
        }

        contactsService
            .uploadContacts(file, progress)
            .then(success, e => failure(e));

        dispatch(gridActions.setUploadState(uploadStatus.uploading, 0, file.name));

        function success(contacts) {
            contacts.forEach(s => {
                s.company = s.company ? s.company.trim() : '';
                s.email = s.email ? s.email.trim() : '';
                s.isNew = true;
            });
            dispatch(gridActions.addDataItems(contacts));
            dispatch(gridActions.validate());
            setTimeout(
                () => dispatch(gridActions.setUploadState(uploadStatus.none)),
                500
            );
        }

        function failure(e) {
            dispatch(gridActions.setUploadState(uploadStatus.none));
            dispatch(errorActions.unexpectedError(e));
        }

        function progress(e) {
            if (e.lengthComputable) {
                var percentComplete = (e.loaded / e.total) * 100;
                dispatch(gridActions.setUploadState(uploadStatus.uploading, percentComplete, file.name));
            }
        }
    }
}

function handleClipboardData(clipboardText) {
    return (dispatch, getState) => {
        const { position = { index: 0 } } = getState().grid;

        dispatch(gridActions.dataProcessing(true));

        contactsService
            .parseContactsStirng(clipboardText)
            .then(success, e => dispatch(errorActions.unexpectedError(e)))
            .finally(() => dispatch(gridActions.dataProcessing(false)));

        function success(contacts) {
            contacts.forEach(s => s.isNew = true);
            dispatch(gridActions.insertDataItems(contacts, position.index));
            dispatch(gridActions.validate());
        }
    };
}

function save() {
    return async (dispatch, getState) => {
        dispatch(gridActions.validate());

        const { grid } = getState();
        const contacts = grid.dataItems.filter(i => !i.draft);
        if (!grid.isValid && contacts.length) {
            return;
        }

        const { deletedContacts } = getState().contactsSave;

        dispatch(loading(true));
        await dispatch(findConflicts(contacts, deletedContacts));

        const { conflicts } = getState().contactsSave;
        if (conflicts && conflicts.length) {
            dispatch(loading(false));
            return dispatch(switchView(contactsSaveViews.conflicts));
        }

        dispatch(doSave(contacts));
    };
}

function saveResolved() {
    return (dispatch, getState) => {
        const { grid } = getState();
        const contacts = grid.dataItems.filter(i => !i.draft);

        dispatch(loading(true));
        dispatch(doSave(contacts));
    };
}

function doSave(contacts) {
    return (dispatch, getState) => {
        const { deletedContacts, companies, conflicts } = getState().contactsSave;

        const contactIndexesToSkip = conflicts
            .map(c => c.duplicates.filter(d => d.isDeleted && !d.id))
            .flat()
            .map(d => d.contactIndex);

        const contactsToSave = contacts
            .filter((contact, index) =>
                !deletedContacts.some(id => contact.id === id) &&
                !contactIndexesToSkip.some(i => i === index))
            .map(c => {
                const company = companies.find(company =>
                    company.name.localeCompare(
                        c.company,
                        undefined,
                        { sensitivity: 'accent' }) === 0
                );

                return { ...c, companyId: company && company.id };
            });

        contactsService
            .bulkSave(contactsToSave, deletedContacts)
            .then(success)
            .catch(failed)
            .finally(() => dispatch(loading(false)))

        function success() {
            dispatch(storeConflicts(null));
            history.replace(routes.dealers);
        }

        function failed(e) {
            if (+e.status === 409) {
                dispatch(findConflicts(contacts));
            } else {
                dispatch(errorActions.unexpectedError(e));
            }
        }
    };
}

function switchView(view) {
    return {
        type: actionTypes.SWITCH_VIEW,
        view
    };
}

function findConflicts(contacts, deletedContacts) {
    return async dispatch => {
        let currentContacts;

        try {
            currentContacts = await
                contactsService.getContacts()
        }
        catch (e) {
            return dispatch(errorActions.unexpectedError(e));
        }

        currentContacts = currentContacts
            .filter(c => !deletedContacts.some(d => d === c.id))
            .filter(c => !contacts.some(cc => cc.id === c.id));

        const contactsIndexed = contacts.map((c, contactIndex) => ({ ...c, contactIndex }));

        const nameDuplicates = new Map();
        const emailDuplicates = new Map();

        contactsIndexed
            .forEach(c => {
                findNameDuplicates(c);
                findEmailDuplicates(c);
            });

        const nameConflicts = [...nameDuplicates.values()].map(d => ({
            conflictType: contactConflictTypes.duplicateName,
            duplicates: d,
            isResolved: false
        }));

        const emailConflicts = [...emailDuplicates.values()].map(d => ({
            conflictType: contactConflictTypes.duplicateEmail,
            duplicates: d,
            isResolved: false
        }));

        const conflicts = nameConflicts.concat(emailConflicts);
        dispatch(storeConflicts(conflicts));

        function findNameDuplicates(contact) {
            const fullName = nameUtils.getFullName(contact.firstName, contact.lastName, '');
            const nameEquals = ({ firstName, lastName }) => {
                const otherFullName = nameUtils.getFullName(firstName, lastName, '');
                return otherFullName && fullName.localeCompare(otherFullName, undefined, { sensitivity: 'accent' }) === 0;
            }

            if (fullName && !nameDuplicates.has(fullName)) {
                const currentDuplicates = currentContacts.filter(current => nameEquals(current));
                const localDuplicates = contactsIndexed.filter(c => nameEquals(c));
                const duplicates = currentDuplicates.concat(localDuplicates);

                if (duplicates.length > 1) {
                    nameDuplicates.set(fullName, duplicates);
                }
            }
        }

        function findEmailDuplicates(contact) {
            if (!emailDuplicates.has(contact.email)) {
                const duplicates = currentContacts.filter(current =>
                    current.email.localeCompare(contact.email, undefined, { sensitivity: 'accent' }) === 0);

                if (duplicates.length) {
                    duplicates.push(contact);
                    emailDuplicates.set(contact.email, duplicates);
                }
            }
        }
    };
}

function storeConflicts(conflicts) {
    return {
        type: actionTypes.STORE_CONFLICTS,
        conflicts
    };
}

function resetConflicts() {
    return {
        type: actionTypes.RESET_CONFLICTS
    };
}

function deleteContact(...contacts) {
    return dispatch => {
        const exstingContacts = contacts.filter(id => id);
        if (exstingContacts.length) {
            dispatch({
                type: actionTypes.DELETE_CONTACT,
                contacts: exstingContacts
            });
        }
    };
}

function deleteDuplicate(contact) {
    return dispatch => {
        dispatch({
            type: actionTypes.DELETE_DUPLICATE_CONTACT,
            contact
        });
        dispatch(deleteContact(contact.id));
        dispatch(ensureConflictsResolved());
    };
}

function ensureConflictsResolved() {
    return {
        type: actionTypes.ENSURE_CONFLICTS_RESOLVED
    }
}

function backToContactsEdit() {
    return dispatch => {
        dispatch(resetConflicts());
        dispatch(switchView(contactsSaveViews.grid));
    };
}

function setCompanyNameByEmailDomain(gridItemIndex) {
    return (dispatch, getState) => {
        const { dataItems } = getState().grid;
        const contact = dataItems[gridItemIndex];
        const domainName = contact.email.substring(contact.email.lastIndexOf("@") + 1);

        if (domainName) {
            const { companyDomains } = getState().contactsSave;
            const domains = companyDomains.find(c =>
                c.domainNames.some(d => d.localeCompare(
                    domainName,
                    undefined,
                    { sensitivity: 'accent' }) === 0));
            if (domains) {
                const { companies } = getState().contactsSave;
                const company = companies.find(c => c.id === domains.companyId);
                const dataItem = {
                    ...contact,
                    company: company.name
                };

                dispatch(gridActions.deleteRow(gridItemIndex));
                dispatch(gridActions.insertDataItems([dataItem], gridItemIndex));
            }
        }
    };
}
