import { AnyAction } from "redux";
import { entries, isUndefined, omitBy } from "lodash";
import { ThunkDispatch } from "redux-thunk";
import { createAction } from "typesafe-actions"
import { constants, errorMessages, uploadStatus } from "../constants";
import { inventoryService } from "../services/inventory.service";
import { InventoryFloatingIndex, inventoryFloatingIndexTitles } from '../types/inventory/InventoryFloatingIndex';
import { AppState } from "../types/state/AppState";
import { dateTimeUtils, formatUtils, moneyUtils, numericUtils, stringUtils } from "../utils";
import { gridActions, errorActions } from ".";
import { ParsedInventoryPosition } from '../types/inventory/ParsedInventoryPosition';
import { GridDataItem } from "../types/state/GridState";
import { InventoryPosition } from "../types/inventory/InventoryPosition";
import { EditingInventoryPosition } from "../types/state/InventoryEditState";
import { RequestState } from '../constants/request-state';

const setSavingState = createAction('inventoryEdit/IS_SAVING',
    resolve => (requestState: RequestState) => resolve({ requestState })
);
const save = createAction(
    'inventoryEdit/SAVE',
    resolve => (lockModifiedDate?: Date, companyId?: number) => resolve({ lockModifiedDate, companyId })
);
const setConflictsPopupVisible = createAction(
    'inventoryEdit/SET_VISIBLE_WARNING_POPUP',
    resolve => (visible: boolean) => resolve({ visible })
);
const setTradedStatus = createAction(
    'inventoryEdit/SET_TRADED_STATUS',
    resolve => (inventoryPositionId: number, lockModifiedDate: Date, tradeDate: Date) => resolve({ inventoryPositionId, lockModifiedDate, tradeDate })
);
const setTradedRequestStatus = createAction('inventoryEdit/SET_TRADED_REQUEST_STATUS',
    resolve => (inventoryId: number, status: boolean) => resolve({ inventoryId, status })
);
const reset = createAction('inventoryEdit/RESET');

const setFlaggedModal = createAction(
    'inventoryEdit/SET_FLAGGED_MODAL',
    resolve => (isFlaggedModalVisible: boolean) => resolve(isFlaggedModalVisible)
)

const updateCoupon = (rowIndex: number) => {
    // use thunk because gridActions.deleteRow && gridActions.insertDataItems cannot be dispatched from saga
    return (dispatch: ThunkDispatch<AppState, void, AnyAction>, getState: () => AppState) => {
        const dataItems: GridDataItem<EditingInventoryPosition>[] = getState().grid.dataItems;
        const dataItem = dataItems[rowIndex] && { ...dataItems[rowIndex] };

        if (dataItem) {
            dataItem.coupon = formatUtils.formatInventoryCoupon({
                floaterIndex: dataItem.floaterIndex ? dataItem.floaterIndex as unknown as InventoryFloatingIndex : undefined,
                spread: numericUtils.isNumber(dataItem.spread) ? Number(dataItem.spread) : undefined
            });

            dispatch(gridActions.deleteRow(rowIndex));
            dispatch(gridActions.insertDataItems([dataItem], rowIndex));
        }
    };
}

const uploadSecurities = (file: File) => {
    return (dispatch: ThunkDispatch<AppState, void, AnyAction>, getState: () => AppState) => {
        const { position, upload, mountedKey } = getState().grid;

        if (upload.status === uploadStatus.uploading) {
            return;
        }

        const extension = (file.name.split('.').pop() || '').toLowerCase();

        if (extension !== 'csv' && extension !== 'xlsx') {
            dispatch(errorActions.error(
                null,
                errorMessages.invalidFileType,
                'Invalid file type.'
            ));
            return;

        } else if (file.size > constants.gridFileUploadMaximumSize * 1024 * 1024) {
            dispatch(errorActions.error(
                null,
                errorMessages.fileSizeLimitExceeded(constants.gridFileUploadMaximumSize),
                'The file is too big.'
            ));
            return;
        }

        if (position?.editing) {
            dispatch(gridActions.applyOrCancelEdit());
        }

        inventoryService
            .uploadSecurities(file, progress)
            .then(success, failure)
            .finally(() => dispatch(gridActions.setUploadState(uploadStatus.none)));

        dispatch(gridActions.setUploadState(uploadStatus.uploading, 0, file.name));

        function success(positions: ParsedInventoryPosition[]) {
            const currentMountedKey = getState().grid.mountedKey;
            if (mountedKey === currentMountedKey) {
                const dataItems = getState().grid.dataItems.filter((i: GridDataItem<EditingInventoryPosition>) => !i.draft);
                const parsed: EditingInventoryPosition[] = positions.map(p => normalizeParsedInventoryPosition(p));
                const merged = mergeDataItems(dataItems, parsed);
                dispatch(gridActions.setUploadState(uploadStatus.ready))
                dispatch(gridActions.clear());
                dispatch(gridActions.addDataItems(merged));
                dispatch(ensureFlaggedModalShown(merged));
                dispatch(gridActions.validate());
            }
        }

        function failure(e: Error) {
            const currentMountedKey = getState().grid.mountedKey;
            if (mountedKey === currentMountedKey) {
                dispatch(errorActions.unexpectedError(e));
            }
            dispatch(gridActions.setUploadState(uploadStatus.ready))
        }

        function progress(e: ProgressEvent) {
            const currentMountedKey = getState().grid.mountedKey;
            if (e.lengthComputable && mountedKey === currentMountedKey) {
                const percentComplete = (e.loaded / e.total) * 100;
                dispatch(gridActions.setUploadState(uploadStatus.uploading, percentComplete, file.name));
            }
        }
    };
};

const handleClipboardData = (clipboardData: string) => {
    return (dispatch: ThunkDispatch<AppState, void, AnyAction>, getState: () => AppState) => {
        const { mountedKey } = getState().grid;
        dispatch(gridActions.dataProcessing(true));

        inventoryService
            .parseSecuritiesStirng(clipboardData)
            .then(success)
            .catch(e => dispatch(errorActions.unexpectedError(e)))
            .finally(() => dispatch(gridActions.dataProcessing(false)));

        function success(securities: ParsedInventoryPosition[]) {
            const currentMountedKey = getState().grid.mountedKey;
            if (mountedKey === currentMountedKey) {
                const dataItems = getState().grid.dataItems.filter((i: GridDataItem<InventoryPosition>) => !i.draft);
                const parsed: any[] = securities.map(p => normalizeParsedInventoryPosition(p));
                const merged = mergeDataItems(dataItems, parsed);
                dispatch(gridActions.clear());
                dispatch(gridActions.addDataItems(merged));
                dispatch(ensureFlaggedModalShown(merged));
                dispatch(gridActions.validate());
            }
        }
    };
};

const ensureFlaggedModalShown = (merged: GridDataItem<EditingInventoryPosition>[]) => {
    return (dispatch: ThunkDispatch<AppState, void, AnyAction>) => {
        const isFlaggedExists = merged.some((item => item.isFlagged));
        if (isFlaggedExists) {
            dispatch(setFlaggedModal(true));
        }
    }
}

const excludeComputedProps = (source: EditingInventoryPosition) => {
    const withoutComputedProps = {
        ...source,
        coupon: undefined,
        cusip: undefined,
        draft: undefined,
        errors: undefined,
        isin: undefined,
        securityId: undefined,
        currency: undefined,
        ncEndMonth: undefined,
        ncEndYear: undefined,
        riEndMonth: undefined,
        riEndYear: undefined,
        size: source.size ? Number(source.size) : undefined,
        offer: source.offer ? Number(source.offer) : undefined,
        floaterIndex: source.floaterIndex ? Number(source.floaterIndex) : undefined
    };
    return omitBy(withoutComputedProps, isUndefined);
}

function mergeDataItems(
    actualItems: GridDataItem<EditingInventoryPosition>[],
    updatedItems: EditingInventoryPosition[]) {
    if (!actualItems.length) {
        return updatedItems.map(i => ({ ...i, isNew: true }));
    }

    const actualItemsCopy: GridDataItem<EditingInventoryPosition>[] = [...actualItems].map(el => ({ ...el, isFlagged: true }));
    const newItems: GridDataItem<EditingInventoryPosition>[] = [];

    updatedItems.forEach(u => {
        const actualIndex = actualItems.findIndex(a => a.securityId && a.securityId === u.securityId);
        if (actualIndex < 0) {
            newItems.push({ ...u, isNew: true });
        } else if (!isEqual(excludeComputedProps(actualItems[actualIndex]), excludeComputedProps(u))) {
            actualItemsCopy[actualIndex] = { ...u, isUpdate: true };
        } else {
            actualItemsCopy[actualIndex] = { ...actualItems[actualIndex], isUpdate: false, isNew: false, isFlagged: false };
        }
    });

    return actualItemsCopy.concat(newItems);
}

function isEqual(actual: GridDataItem<EditingInventoryPosition>, update: GridDataItem<EditingInventoryPosition>) {
    const valueOrEmpty = <T>(value?: T | null) => value ?? '';

    return (
        actual.securityId === update.securityId &&
        actual.isinCusip === update.isinCusip &&
        actual.ticker === update.ticker &&
        valueOrEmpty(actual.rating) === valueOrEmpty(update.rating) &&
        valueOrEmpty(actual.offer) === valueOrEmpty(update.offer) &&
        valueOrEmpty(actual.size) === valueOrEmpty(update.size) &&
        valueOrEmpty(actual.offerDmBps) === valueOrEmpty(update.offerDmBps) &&
        valueOrEmpty(actual.offerYield) === valueOrEmpty(update.offerYield) &&
        valueOrEmpty(actual.spread) === valueOrEmpty(update.spread) &&
        valueOrEmpty(actual.walYears) === valueOrEmpty(update.walYears) &&
        valueOrEmpty(actual.mvoc) === valueOrEmpty(update.mvoc) &&
        valueOrEmpty(actual.manager) === valueOrEmpty(update.manager) &&
        valueOrEmpty(actual.description) === valueOrEmpty(update.description) &&
        valueOrEmpty(actual.ncEnd) === valueOrEmpty(update.ncEnd) &&
        valueOrEmpty(actual.riEnd) === valueOrEmpty(update.riEnd) &&
        valueOrEmpty(actual.floaterIndex) === valueOrEmpty(update.floaterIndex) &&
        valueOrEmpty(actual.creditEnhancement) === valueOrEmpty(update.creditEnhancement)
    );
}

function normalizeParsedInventoryPosition(position: ParsedInventoryPosition): EditingInventoryPosition {
    const dataItem: EditingInventoryPosition = {};

    if (position.floaterIndex) {
        const key = entries(inventoryFloatingIndexTitles)
            .find(([, title]) => title.localeCompare(position.floaterIndex || '', undefined, { sensitivity: 'accent' }) === 0)
            ?.[0];

        const floaterIndexEnumKey = InventoryFloatingIndex[key as keyof typeof InventoryFloatingIndex];

        dataItem.floaterIndex = floaterIndexEnumKey ? InventoryFloatingIndex[floaterIndexEnumKey] : undefined;
    }

    dataItem.securityId = position.securityId;
    dataItem.isinCusip = position.isinCusip;
    dataItem.ticker = position.ticker;
    dataItem.rating = position.rating;
    dataItem.spread = normalizeNumeric(position.spread);
    dataItem.coupon = formatUtils.formatInventoryCoupon({
        floaterIndex: dataItem.floaterIndex ? dataItem.floaterIndex as unknown as InventoryFloatingIndex : undefined,
        spread: numericUtils.isNumber(dataItem.spread) ? Number(dataItem.spread) : undefined
    });
    dataItem.bid = position.bid ? normalizeNumeric(position.bid): undefined;
    dataItem.offer = normalizeNumeric(position.offer);    
    dataItem.bidSize = numericUtils.isNumber(position.bidSize) ? moneyUtils.amountToMM(+position.bidSize) : position.bidSize; 
    dataItem.size = numericUtils.isNumber(position.size) ? moneyUtils.amountToMM(+position.size) : position.size;
    dataItem.bidDmBps = position.bidDmBps ? normalizeNumeric(position.bidDmBps) : undefined;
    dataItem.offerDmBps = normalizeNumeric(position.offerDmBps);
    dataItem.offerYield = normalizeNumeric(position.offerYield);
    dataItem.walYears = normalizeNumeric(position.walYears);
    dataItem.mvoc = normalizeNumeric(position.mvoc);
    dataItem.ncEnd = stringUtils.isNullOrWhiteSpace(position.ncEnd) ? undefined : formatUtils.formatMonthAndYear(position.ncEnd, position.ncEnd);
    dataItem.riEnd = stringUtils.isNullOrWhiteSpace(position.riEnd) ? undefined : formatUtils.formatMonthAndYear(position.riEnd, position.riEnd);
    const ncEndParseResult = dateTimeUtils.parseMonthAndYearStamp(position.ncEnd);
    const riEndParseResult = dateTimeUtils.parseMonthAndYearStamp(position.riEnd);
    if (ncEndParseResult.isMonthValid && ncEndParseResult.isYearValid) {
        dataItem.ncEndYear = ncEndParseResult.year;
        dataItem.ncEndMonth = ncEndParseResult.month;
    }
    if (riEndParseResult.isMonthValid && riEndParseResult.isYearValid) {
        dataItem.riEndYear = riEndParseResult.year;
        dataItem.riEndMonth = riEndParseResult.month;
    }
    dataItem.manager = stringUtils.isNullOrWhiteSpace(position.manager) ? undefined : position.manager.trim();
    dataItem.description = stringUtils.isNullOrWhiteSpace(position.description) ? undefined : position.description.trim();
    dataItem.creditEnhancement = normalizeNumeric(position.creditEnhancement);
    return dataItem;
}

function normalizeNumeric(raw?: string | null, defaultValue?: number): number | string | undefined {
    if (raw == null || stringUtils.isNullOrWhiteSpace(raw)) {
        return defaultValue;
    }

    const trimmed = raw.trim();

    return numericUtils.isNumber(trimmed) ? +trimmed : raw;
}

export const inventoryEditActions = {
    save,
    setSavingState,
    updateCoupon,
    uploadSecurities,
    handleClipboardData,
    setConflictsPopupVisible,
    setTradedStatus,
    setTradedRequestStatus,
    reset,
    setFlaggedModal,
};
