import { uploadDocumentsActions, errorActions } from ".";
import { portfolioService } from "../services";

export const portfolioDocumentsActions = {
    init,
    reset
};

function init() {
    return async dispatch => {
        try {
            const portfolios = await portfolioService.getPortfolios();
            const securityIdList = getSecurityIdList(portfolios);

            dispatch(uploadDocumentsActions.init(securityIdList));
        }
        catch (e) {
            dispatch(errorActions.criticalError(e));
        }
    };
}

function getSecurityIdList(portfolios) {
    return portfolios
        .map(p => p.securities || [])
        .flat()
        .map(s => s.securityId);
}

function reset() {
    return uploadDocumentsActions.reset();
}
