import { createAction } from 'typesafe-actions';
import { BrokerDealerCompanySlim } from '../types/company/BrokerDealerCompanySlim';

const setEditModeStatus = createAction(
    'sellerAdminDealers/SET_EDIT_MODE_STATUS',
        resolve => (status: boolean) => resolve({ status })
);
const getExcludedDealersRequest = createAction('sellerAdminDealers/GET_EXCLUDED_REQUEST');
const getExcludedDealersSuccess = createAction(
    'sellerAdminDealers/GET_EXCLUDED_SUCCESS',
    resolve => (excludeNewCompany: boolean, dealers: BrokerDealerCompanySlim[]) => resolve({ excludeNewCompany, dealers })
);
const getExcludedDealersFailure = createAction('sellerAdminDealers/GET_EXCLUDED_FAILURE');

const saveExcludedDealersRequest = createAction(
    'sellerAdminDealers/SAVE_EXCLUDED_REQUEST',
    resolve => (dealerIds: number[], excludeNewCompany: boolean) => resolve({ dealerIds, excludeNewCompany })
);
const saveExcludedDealersSuccess = createAction('sellerAdminDealers/SAVE_EXCLUDED_SUCCESS');
const saveExcludedDealersFailure = createAction('sellerAdminDealers/SAVE_EXCLUDED_FAILURE');
const reset = createAction('sellerAdminDealers/RESET');

export const sellerAdminDealerActions = {
    setEditModeStatus,
    getExcludedDealersRequest,
    getExcludedDealersSuccess,
    getExcludedDealersFailure,
    saveExcludedDealersRequest,
    saveExcludedDealersSuccess,
    saveExcludedDealersFailure,
    reset
};
