import * as moment from 'moment';
import { constants, errorMessages, gridColumns, securitiesActions as actionTypes, uploadStatus } from '../constants';
import { bwicService, daysService } from '../services';
import { changeColorActions, changePxTalkActions, errorActions, gridActions } from '.';
import { processActions } from './process.actions';
import { dateTimeUtils } from '../utils';
import { history } from '../history';
import { appConfig } from '../app-config';

export const securitiesActions = {
    init,
    reset,
    uploadSecurities,
    insertSelectedSecurity,
    handleClipboardData,
    savePxTalks,
    saveColor,
    validateTradeLimits,
    setFlaggedModal,
    insertSecurityFromQueryParams
};

function init(positions, headers, rowsLimit = constants.gridRowsLimit, loadBwicDates = false, queryParams = '') {
    return async (dispatch, getState) => {
        const { securities, grid } = getState();

        if (securities.isInitializing || grid.headers) {
            return;
        }

        try {
            dispatch(initializing(true));
            if (loadBwicDates) {
                const day = await daysService.getNextBusinessDate();
                const year = new Date(day).getFullYear();

                const currentYearHolidaysPromise = appConfig.canCreateBwicOnUSFederalHolidays
                    ? Promise.resolve([])
                    : daysService.getHolidays(year);
                const nextYearHolidaysPromise = appConfig.canCreateBwicOnUSFederalHolidays
                    ? Promise.resolve([])
                    : daysService.getHolidays(year + 1);

                const todayIsBusinessDayPromise = daysService.isBusinessDay(new Date());

                const currentYearHolidays = await currentYearHolidaysPromise;
                const nextYearHolidays = await nextYearHolidaysPromise;
                const todayIsBusinessDay = await todayIsBusinessDayPromise;

                const holidays = [...currentYearHolidays, ...nextYearHolidays];

                const minDate = todayIsBusinessDay
                    ? moment().startOf('day').toDate()
                    : moment.utc(day).startOf('day').toDate();

                dispatch(processActions.setMinDate(minDate));
                dispatch(processActions.setHolidays(holidays));

                dispatch(processActions.storeNextBusiessDay(day));
                dispatch(processActions.changeDate(day));
                dispatch(processActions.changeBidsDueTime(dateTimeUtils.timestampToUtcString(constants.bidDueDefaultTimestamp)));
                dispatch(processActions.changeGoodUntilTime(dateTimeUtils.timestampToUtcString(constants.goodUntilDefaultTimestamp)));

                const { state = {} } = history.location;
                const { dateTime } = state;

                if (dateTime) {
                    const date = moment(dateTime, constants.dateTimeFormatUtc).format('YYYY-MM-DD[T]00:00:00');
                    const time = moment(dateTime, constants.formatTimeStringWithoutTimeZone).format('hh A');
                    const dueTime = dateTimeUtils.timestampToUtcString(time);
                    dispatch(processActions.changeDate(date));
                    dispatch(processActions.changeBidsDueTime(dueTime));
                }
            }

            const columns = (headers || gridColumns.securitiesWithoutPxTalkAndColor())
                .map(c => {
                    if (c.name === gridColumns.isinCusip.name) {
                        return {
                            ...c,
                            type: 'lookup',
                            lookup: {
                                compareCallback: (searchTerm, lookupItem) => {
                                    const security = lookupItem.payload;
                                    return (
                                        security.isin?.toLowerCase().startsWith(searchTerm) ||
                                        security.cusip?.toLowerCase().startsWith(searchTerm)
                                    );
                                },
                                fetchCallback: async (searchTerm, abortSignal) => {
                                    const securities = await bwicService.searchSecuritiesGlobal(searchTerm, false, abortSignal);
                                    return securities.map(s => ({
                                        text: s.isinCusip,
                                        description: s.ticker,
                                        payload: s
                                    }))
                                },
                                onLookupItemSelected: lookupItem => dispatch(insertSelectedSecurity(lookupItem.payload))
                            }
                        };
                    } else if (c.name === gridColumns.ticker.name) {
                        return {
                            ...c,
                            type: 'lookup',
                            lookup: {
                                fetchCallback: async (searchTerm, abortSignal) => {
                                    const securities = await bwicService.searchSecuritiesGlobal(searchTerm, true, abortSignal);
                                    return securities.map(s => ({
                                        text: s.ticker,
                                        description: s.isinCusip,
                                        payload: s
                                    }))
                                },
                                onLookupItemSelected: lookupItem => dispatch(insertSelectedSecurity(lookupItem.payload))
                            }
                        };
                    }

                    return c;
                })

            if (queryParams.toString().length > 0) {
                dispatch(securitiesActions.insertSecurityFromQueryParams(queryParams))
            }

            dispatch(gridActions.init(positions, columns, rowsLimit));
        } catch (e) {
            dispatch(errorActions.criticalError(e));
        } finally {
            dispatch(initializing(false));
        }
    }
}

function insertSecurityFromQueryParams(queryParams) {
    return dispatch => {
        const tickers = queryParams.get('tickers');
        const isins = queryParams.get('isins');
        const cusips = queryParams.get('cusips');

        const tickersQueryParams = tickers ? tickers.split(',') : [];
        const isinsQueryParams = isins ? isins.split(',') : [];
        const cusipsQueryParams = cusips ? cusips.split(',') : [];

        const securityFromQueryParams = [...tickersQueryParams, ...isinsQueryParams, ...cusipsQueryParams].join('\n');

        dispatch(securitiesActions.handleClipboardData(securityFromQueryParams))
    };
}

function initializing(isInitializing) {
    return {
        type: actionTypes.INITIALIZING,
        isInitializing
    };
}

function uploadSecurities(file, mergeWithPrevious) {
    return (dispatch, getState) => {
        const { position, upload, mountedKey } = getState().grid;

        if (upload.status === uploadStatus.uploading) {
            return;
        }

        const extension = (file.name.split('.').pop() || '').toLowerCase();

        if (extension !== 'csv' && extension !== 'xlsx') {
            dispatch(errorActions.error(
                null,
                errorMessages.invalidFileType,
                'Invalid file type.'
            ));
            return;

        } else if (file.size > constants.gridFileUploadMaximumSize * 1024 * 1024) {
            dispatch(errorActions.error(
                null,
                errorMessages.fileSizeLimitExceeded(constants.gridFileUploadMaximumSize),
                'The file is too big.'
            ));
            return;
        }

        if (position.editing) {
            dispatch(gridActions.applyOrCancelEdit());
        }

        bwicService
            .uploadSecurities(file, progress)
            .then(success, failure);

        dispatch(gridActions.setUploadState(uploadStatus.uploading, 0, file.name));

        function success(positions) {
            const currentMountedKey = getState().grid.mountedKey;
            if (mountedKey === currentMountedKey) {
                if (mergeWithPrevious) {
                    positions.forEach(s => normalizeParsedInventoryPosition(s));
                    const dataItems = getState().grid.dataItems.filter((i) => !i.draft);
                    const merged = mergeDataItems(dataItems, positions);
                    dispatch(gridActions.clear());
                    dispatch(gridActions.addDataItems(merged));
                    dispatch(ensureFlaggedModalShown(merged));
                } else {
                    positions.forEach(s => setSecurityNew(s));
                    dispatch(gridActions.addDataItems(positions));
                }
                dispatch(gridActions.validate());
                setTimeout(
                    () => dispatch(gridActions.setUploadState(uploadStatus.none)),
                    500
                );
            }
        }

        function failure(e) {
            dispatch(gridActions.setUploadState(uploadStatus.none));
            const currentMountedKey = getState().grid.mountedKey;
            if (mountedKey === currentMountedKey) {
                dispatch(errorActions.unexpectedError(e));
            }
        }

        function progress(e) {
            const currentMountedKey = getState().grid.mountedKey;
            if (e.lengthComputable && mountedKey === currentMountedKey) {
                var percentComplete = (e.loaded / e.total) * 100;
                dispatch(gridActions.setUploadState(uploadStatus.uploading, percentComplete, file.name));
            }
        }
    }
}

function reset() {
    return dispatch => {
        dispatch(gridActions.reset());
        dispatch({ type: actionTypes.RESET });
    };
}

function insertSelectedSecurity(security) {
    return (dispatch, getState) => {
        const { position, dataItems } = getState().grid;
        const { size, errors } = dataItems[position.index] || {};

        const dataItem = {
            ...security,
            id: undefined,
            securityId: security.id,
            size
        };

        dispatch(gridActions.cancelEdit());
        dispatch(gridActions.replaceDataItem(dataItem, position.index));

        if (errors && errors.length) {
            // trigger validation to resolve duplicate errors
            dispatch(gridActions.validate());
        }
    }
}

function handleClipboardData(clipboardData, mergeWithPrevious) {
    return (dispatch, getState) => {
        const { position = { index: 0 } } = getState().grid;

        dispatch(gridActions.dataProcessing(true));

        bwicService
            .parseSecuritiesStirng(clipboardData)
            .then(success, e => dispatch(errorActions.unexpectedError(e)))
            .finally(() => dispatch(gridActions.dataProcessing(false)));

        function success(securities) {
            if (mergeWithPrevious) {
                securities.forEach(s => normalizeParsedInventoryPosition(s));
                const dataItems = getState().grid.dataItems.filter((i) => !i.draft);
                const merged = mergeDataItems(dataItems, securities);
                dispatch(gridActions.clear());
                dispatch(gridActions.addDataItems(merged));
                dispatch(ensureFlaggedModalShown(merged));
            } else {
                securities.forEach(s => setSecurityNew(s));
                dispatch(gridActions.insertDataItems(securities, position.index));
            }
            dispatch(gridActions.validate());
        }
    };
}

function mergeDataItems(
    actualItems,
    updatedItems) {
    if (!actualItems.length) {
        return updatedItems.map(i => ({ ...i, isNew: true }));
    }

    const actualItemsCopy = [...actualItems].map(el => ({ ...el, isFlagged: true }));
    const newItems = [];

    updatedItems.forEach(u => {
        const actualIndex = actualItems.findIndex(a => a.securityId && a.securityId === u.securityId);
        if (actualIndex < 0) {
            newItems.push({ ...u, isNew: true });
        } else if (!isEqual(actualItems[actualIndex], u)) {
            actualItemsCopy[actualIndex] = { ...u, isUpdate: true };
        } else {
            actualItemsCopy[actualIndex] = { ...actualItems[actualIndex], isUpdate: false, isNew: false, isFlagged: false };
        }
    });

    return actualItemsCopy.concat(newItems);
}

function isEqual(actual, update) {
    const valueOrEmpty = (value) => value ?? '';

    return (
        actual.securityId === update.securityId &&
        actual.isinCusip === update.isinCusip &&
        valueOrEmpty(actual.size) === valueOrEmpty(update.size) &&
        actual.ticker === update.ticker &&
        valueOrEmpty(actual.currency) === valueOrEmpty(update.currency) &&
        valueOrEmpty(actual.rating) === valueOrEmpty(update.rating)
    );
}

function setSecurityNew(security) {
    security.isNew = true;
    security.securityId = security.id;
    security.id = undefined;
}

function normalizeParsedInventoryPosition(security) {
    security.securityId = security.id;
    security.id = undefined;
}

function setFlaggedModal(isFlaggedModalVisible) {
    return {
        type: actionTypes.SECURITIES_SET_FLAGGED_MODAL,
        isFlaggedModalVisible
    };
}

const ensureFlaggedModalShown = (merged) => {
    return (dispatch) => {
        const isFlaggedExists = merged.some((item => item.isFlagged));
        if (isFlaggedExists) {
            dispatch(setFlaggedModal(true));
        }
    }
}

function savePxTalks() {
    return (dispatch, getState) => {
        const { pxTalks } = getState().changePxTalk;
        if (pxTalks.every(p => !p.error)) {
            dispatch(gridActions.editing(pxTalks));
            dispatch(gridActions.applyEdit());
            dispatch(changePxTalkActions.reset());
            dispatch(gridActions.blockInput(false));
        }
    };
}

function saveColor() {
    return (dispatch, getState) => {
        const { color, errors } = getState().changeColor;
        if (errors.isValid) {
            dispatch(gridActions.editing(color));
            dispatch(gridActions.applyEdit());
            dispatch(changeColorActions.reset());
            dispatch(gridActions.blockInput(false));
        }
    };
}

function validateTradeLimits() {
    return async (dispatch, getState) => {
        const { process, grid } = getState();
        const positions = grid.dataItems.filter(item => !item.draft);

        if (!process.date && !positions.length) return;

        dispatch({ type: actionTypes.VALIDATE_TRADE_LIMITS_REQUEST });

        try {
            await bwicService.validateTradeLimits(process.date, process.bidsDue, process.timeZone, positions);
            dispatch({ type: actionTypes.VALIDATE_TRADE_LIMITS_SUCCESS });
        } catch (e) {
            dispatch(errorActions.unexpectedError(e));
            dispatch({ type: actionTypes.VALIDATE_TRADE_LIMITS_ERROR });
        }
    }

}
