import { ActionType, createAction } from 'typesafe-actions';
import { RequestState } from '../../constants/request-state';
import { Company } from '../../types/amr-pipeline/models/Company';

const request = createAction('entities/trustee/REQUEST');
const requestResult = createAction('entities/trustee/REQUEST_RESULT',
    resolve => (requestState: RequestState, trustee: Company[] = []) =>
        resolve({ requestState, trustee })
);

export type TTrusteeActionType = ActionType<
    typeof trusteeActions[keyof typeof trusteeActions]
>;

export const trusteeActions = {
    request,
    requestResult
}
