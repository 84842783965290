import { createAction } from 'typesafe-actions';
import { AgencyRatings } from '../types/models/rating/AgencyRatings';
import { RatingMap } from '../types/models/rating/RatingMap';
import { BwicPositionBase } from '../types/bwic/BwicPositionBase';
import { InventoryPosition } from '../types/inventory/InventoryPosition';

const securityDetailsReset = createAction('securityDetails/RESET');
const securityDetailsInitRequest = createAction('securityDetails/INIT_REQUEST',
    resolve => (security: BwicPositionBase | InventoryPosition, fetchAgencyRatings?: boolean) =>
        resolve({ security, fetchAgencyRatings })
);
const securityDetailsInitSuccess = createAction('securityDetails/INIT_SUCCESS',
    resolve => (ratings: AgencyRatings, mappings: RatingMap[]) =>
        resolve({ ratings, mappings })
);
const securityDetailsInitFailure = createAction('securityDetails/INIT_FAILURE',
    resolve => (error: string) => resolve({ error })
);

export const securityDetailsActions = {
    securityDetailsInitRequest,
    securityDetailsInitFailure,
    securityDetailsInitSuccess,
    securityDetailsReset
}
