import { createAction } from 'typesafe-actions';
import { SettlementAgentClientAgreement } from '../types/bid-as-dealer/SettlementAgentClientAgreement';
import { SettlementAgentAgreement } from '../types/bid-as-dealer/SettlementAgentAgreement';
import { BidRequest } from '../types/bid-as-dealer/BidRequest';

const settlementAgentAgreementPushReceived = createAction(
    'push/settlement-agent-agreement/AGREEMENT_DATA_RECEIVED',
    resolve => (data: SettlementAgentClientAgreement | SettlementAgentAgreement) => resolve({data})
);

const settlementAgentRequestPushReceived = createAction(
    'push/settlement-agent-agreement/REQUEST_DATA_RECEIVED',
    resolve => (data: BidRequest) => resolve({ data })
);


export const settlementAgentAgeementPushActions = {
    settlementAgentAgreementPushReceived,
    settlementAgentRequestPushReceived
};
