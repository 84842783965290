import moment from 'moment';
import { size } from 'lodash';
import { store } from '../store';
import {
    newBWICActions as actionTypes,
    newBWICSteps as steps,
    roles,
    routes
} from '../constants';
import { bwicService } from '../services/bwic.service';
import {
    securitiesActions,
    gridActions,
    processActions,
    documentsActions,
    submitActions,
    errorActions
} from './';
import { formsActions } from './forms.actions';
import { dealerListActions } from './dealer-list.actions';
import { newBwicDealersActions } from './new-bwic-dealers.actions';
import { isRequesting, isRequestSuccess } from '../utils/request-state.utils';
import { RequestState } from '../constants/request-state';
import { dateTimeUtils } from '../utils';
import { newBwicDealersSelector } from '../selectors/new-bwic-dealers.selector';
import { BwicProcessType } from '../types/models/Process';
import { CompanyStatus } from '../types/company/CompanyStatus';
import { user } from '../user';
import { history } from '../history';

export const newBWICActions = {
    init,
    nextStep,
    previousStep,
    submit,
    setSearchTerm,
    updateFilterOption,
    clearFilterOptions,
    reset
};

function init(referenceName) {
    return async dispatch => {
        if (referenceName) {
            try {
                const bwic = await bwicService.getByReferenceName(referenceName);
                dispatch({
                    type: actionTypes.EDIT_BWIC,
                    bwic
                });
            } catch (e) {
                dispatch(errorActions.criticalError(e));
            }
        }
        dispatch(changeStep(steps.securities));
    };
}

function reset() {
    return dispatch => {
        dispatch(securitiesActions.reset());
        dispatch(processActions.reset());
        dispatch(dealerListActions.reset());
        dispatch(newBwicDealersActions.reset());
        dispatch(documentsActions.reset());
        dispatch(submitActions.reset());
        dispatch({ type: actionTypes.RESET_WIZARD });
    }
}

function nextStep() {
    return async (dispatch, getState) => {
        let isValid = true;
        const { activeStep } = getState().newBWIC;

        if (activeStep === steps.securities) {
            dispatch(gridActions.validate());
            dispatch(processActions.validate());
            const { grid, process } = getState();
            isValid = grid.isValid && !process.errors.date && !process.errors.time;
            if (isValid) {
                await dispatch(securitiesActions.validateTradeLimits());
                isValid = isRequestSuccess(getState().securities.validateTradeLimitsRequestState);
            }

        } else if (activeStep === steps.process) {
            dispatch(processActions.validate());
            const { process } = getState();
            isValid = process.isValid;

        } else if (activeStep === steps.participants) {
            const selectedDealers = getState().newBwicDealers.selectedDealers;
            const selectPlatformDealersPopupVisible = getState().newBwicDealers.selectPlatformDealersPopupVisible;
            const notSelectedPlatformDealers = newBwicDealersSelector(getState())
                .filter(c => !selectedDealers[c.id] && c.status === CompanyStatus.active);

            if (notSelectedPlatformDealers.length !== 0 && !selectPlatformDealersPopupVisible) {
                dispatch(newBwicDealersActions.setSelectPlatformDealersPopupVisible(true));
                isValid = false;
            } else {
                isValid = !!size(selectedDealers);
            }
        } else {
            isValid = true;
        }

        if (isValid) {
            const { newBWIC } = getState();

            const stepKeys = Object.keys(steps);

            const activeStepIndex = stepKeys.findIndex(key => newBWIC.activeStep === steps[key]);
            const nextStepKey = stepKeys[activeStepIndex + 1];

            dispatch(changeStep(steps[nextStepKey]));
        }
    }
}

function previousStep() {
    const { newBWIC } = store.getState();

    const stepKeys = Object.keys(steps);

    const activeStepIndex = stepKeys.findIndex(key => newBWIC.activeStep === steps[key]);
    const previousStepKey = stepKeys[activeStepIndex - 1];

    return changeStep(steps[previousStepKey]);
}

function changeStep(step) {
    return dispatch => {
        dispatch(formsActions.reset());
        dispatch({
            type: actionTypes.CHANGE_STEP,
            step
        });
    }
}

function submit() {
    return (dispatch, getState) => {
        const requestState = getState().submit.requestState;

        if (isRequesting(requestState) || isRequestSuccess(requestState)) return null;

        const { grid, process, newBwicDealers } = getState();

        const companies = newBwicDealersSelector(getState())
            .filter(c => newBwicDealers.selectedDealers[c.id])
            .map(c => ({ id: c.id }));
        const template = process.templates.current;

        const getLiveBiddingSettings = () => template.type === BwicProcessType.Live
            ? {
                stage1Deadline: template.liveBidding.stage1Deadline,
                improvementRound: template.liveBidding.improvementRound,
                minBidIncrement: template.liveBidding.minBidIncrement,
                levelSpecificationType: template.liveBidding.levelSpecificationType,
                openBiddingImproverCount: template.liveBidding.openBiddingImproverCount
            }
            : {};

        const getJumpBallSettings = () => template.type === BwicProcessType.JumpBall
            ? {
                jumpBallAutomaticStage1Deadline: template.jumpBall.automaticStage1Deadline,
                jumpBallImproverCount: template.jumpBall.improverCount,
                jumpBallImprovementRound: template.jumpBall.improvementRound
            }
            : {};

        const getTopXSettings = () => template.type === BwicProcessType.TopX
            ? {
                topXAutomaticStage1Deadline: template.topX.automaticStage1Deadline,
                topXImproverCount: template.topX.improverCount,
                topXImprovementRound: template.topX.improvementRound
            }
            : {};

        const getBestFootForwardSettings = () => template.type === BwicProcessType.BestFootForward
            ? {
                BffStage1Deadline: template.bff.stage1Deadline
            }
            : {};

        const getAutoFeedbackDelay = () =>
            template.type === BwicProcessType.Standard ||
                template.type === BwicProcessType.TopX ||
                template.type === BwicProcessType.JumpBall
                ? template.autoFeedbackDelay
                : undefined

        const getMinBidIncrement = () => template.minBidIncrement && (
            template.type === BwicProcessType.Standard ||
            template.type === BwicProcessType.TopX ||
            template.type === BwicProcessType.JumpBall ||
            template.type === BwicProcessType.BestFootForward)
            ? Number(template.minBidIncrement)
            : undefined

        const getIsAlltoAllProtocol = () =>
            process.isAllToAll ?? user.hasRoles(roles.BrokerDealerTrader)
                ? true
                : false

        const bwic = {
            bidsDue: dateTimeUtils.setTime(process.date, moment.utc(process.bidsDue)).utcOffset(0, true).toISOString(),
            goodUntil: dateTimeUtils.setTime(process.date, moment.utc(process.goodUntil)).utcOffset(0, true).toISOString(),
            daysToSettle: process.daysToSettle,
            timeZone: process.timeZone,
            securities: grid.dataItems.filter(s => !s.draft),
            process: {
                type: template.type,
                reserveLevelsApply: template.reserveLevelsApply,
                earlyTrades: template.earlyTrades,
                rulesText: template.rulesText,
                processTemplateId: template.id,
                autoFeedbackDelay: getAutoFeedbackDelay(),
                minBidIncrement: getMinBidIncrement(),
                cutOffReminder: template.cutOffReminder,
                ...getLiveBiddingSettings(),
                ...getJumpBallSettings(),
                ...getTopXSettings(),
                ...getBestFootForwardSettings()
            },
            companies,
            isAllToAll: getIsAlltoAllProtocol()
        };

        dispatch(submitActions.submitState(RequestState.request));

        bwicService
            .submit(bwic)
            .then((bwicReferenceName) => {
                dispatch(submitActions.submitState(RequestState.success, bwicReferenceName))
                history.replace({
                    pathname: routes.newBWICCreated,
                    state: {
                        date: process.date,
                        bidsDue: process.bidsDue,
                        timeZone: process.timeZone,
                        bwicReferenceName,
                    }
                })
            })
            .catch(e => {
                dispatch(submitActions.submitState(RequestState.failure));
                dispatch(errorActions.unexpectedError(e));
            });
    }
}

function setSearchTerm(searchTerm) {
    return {
        type: actionTypes.DEALERS_SET_SEARCH_TERM,
        payload: { searchTerm }
    };
}

function updateFilterOption(filterOption) {
    return {
        type: actionTypes.DEALERS_UPDATE_FILTER_OPTION,
        payload: { filterOption }
    };
}

function clearFilterOptions() {
    return { type: actionTypes.DEALERS_CLEAR_FILTER_OPTIONS }
}
