import { ActionType, createAsyncAction } from 'typesafe-actions';
import { PageConfigType } from "../types/page-config/PageConfigType";
import { PageConfig } from "../types/page-config/PageConfig";
import { PageConfigList } from "../types/state/entities/PageConfigState";
import { UserConfigColumn } from '../types/user-config/UserConfigColumn';

const getConfigs = createAsyncAction(
    'entities/page-config/REQUEST_CONFIG',
    'entities/page-config/SUCCESS_CONFIG',
    'entities/page-config/FAILURE_CONFIG'
)<undefined, { configs: PageConfigList }, undefined>();

const getDefaultConfig = createAsyncAction(
    'entities/page-config/REQUEST_DEFAULT_CONFIG',
    'entities/page-config/SUCCESS_DEFAULT_CONFIG',
    'entities/page-config/FAILURE_DEFAULT_CONFIG'
)<{ configType: PageConfigType }, { configType: PageConfigType, config: PageConfig }, { configType: PageConfigType }>();

const saveColumns = createAsyncAction(
    'entities/page-config/REQUEST_SAVE_CONFIG',
    'entities/page-config/SUCCESS_SAVE_CONFIG',
    'entities/page-config/FAILURE_SAVE_CONFIG'
)<{ configType: PageConfigType }, { configType: PageConfigType, columns: UserConfigColumn[] }, { configType: PageConfigType }>();

export type TPageConfigActions = ActionType<typeof pageConfigActions[keyof typeof pageConfigActions]>

export const pageConfigActions = {
    getConfigs,
    getDefaultConfig,
    saveColumns
}
