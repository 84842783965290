import { createAction } from "typesafe-actions";
import { ContactSalesRequest } from '../types/contact-sales/ContactSalesRequest';

const show = createAction('contactSales/SHOW');
const hide = createAction('contactSales/HIDE');
const requestSendingFlag = createAction('contactSales/REQUEST_SENDING', resolve => (isSending: boolean) => resolve({ isSending }));
const send = createAction('contactSales/SEND', resolve => (request: ContactSalesRequest) => resolve({ request }));

export const contactSalesActions = {
    show,
    hide,
    requestSendingFlag,
    send
}
