import { saveAs } from 'file-saver';
import { errorActions, notificationActions } from './';
import { portfolioService } from '../services';
import { editPortfolioActions as actionTypes, errorMessages, routes } from '../constants';
import { securitiesActions, gridActions } from '.';
import { activeInventorySecurityActions } from './active-inventory-security.actions';
import { history } from '../history';
import { portfolioListActions } from './portfolio-list.actions';

export const editPortfolioActions = {
    init,
    reset,
    createNewPortfolio,
    renamePortfolioDialog,
    updatePortfolioSecurities,
    deletePortfolioDialog,
    deletePortfolio,
    exportPortfolio
}

function init(portfolioReferenceName) {
    return dispatch => {
        dispatch(loading(true));
        portfolioService
            .getPortfolio(portfolioReferenceName)
            .then(success, e => dispatch(errorActions.criticalError(e)))
            .then(() => dispatch(loading(false)));

        function success(portfolio) {
            dispatch({ type: actionTypes.PORTFOLIO_LOADED, portfolio });
            dispatch(securitiesActions.init(portfolio.securities));
        }
    };
}

function loading(isLoading) {
    return {
        type: actionTypes.EDIT_PORTFOLIO_LOADING,
        isLoading
    };
}

function renamePortfolioDialog(visible) {
    return dispatch => {
        dispatch({
            type: actionTypes.RENAME_DIALOG_VISIBLE,
            dialog: { visible }
        })

        dispatch(gridActions.blockInput(visible));
    };
}

function createNewPortfolio(portfolioName) {
    return (dispatch, getState) => {
        dispatch(gridActions.validate());
        const { grid } = getState();
        const securities = grid.dataItems.filter(s => !s.draft);

        if (!grid.isValid && securities.length) {
            return dispatch(notificationActions.notificationAddErrorMessage(errorMessages.portfolioSaveSecuritiesMessage, errorMessages.portfolioSaveSecuritiesTitle))
        }
        dispatch({type: actionTypes.PORTFOLIO_UPDATE_REQUEST});
        portfolioService.createNewPortfolio(portfolioName, securities)
            .then(() => {
                dispatch({type: actionTypes.PORTFOLIO_UPDATE_SUCCESS});
                dispatch({ type: actionTypes.PORTFOLIO_SAVED });
                dispatch(activeInventorySecurityActions.reset());
                dispatch(portfolioListActions.reset());
                history.replace(routes.portfolio);
            })
            .catch((e) => {
                dispatch({ type: actionTypes.PORTFOLIO_UPDATE_FAILURE });
                dispatch(errorActions.unexpectedError(e));
            })
    }
}

function updatePortfolioSecurities(updatedPortfolioName) {
    return async (dispatch, getState) => {
        dispatch(gridActions.validate());
        const { editPortfolio, grid } = getState();
        const securities = grid.dataItems.filter(s => !s.draft);

        if (!grid.isValid && securities.length) {
            return dispatch(notificationActions.notificationAddErrorMessage(errorMessages.portfolioSaveSecuritiesMessage, errorMessages.portfolioSaveSecuritiesTitle))
        }
        dispatch({type: actionTypes.PORTFOLIO_UPDATE_REQUEST});

        if (updatedPortfolioName) {
            try {
                await portfolioService.renamePortfolio(editPortfolio.portfolio.id, updatedPortfolioName)
            } catch (e) {
                dispatch(errorActions.unexpectedError(e))
                dispatch({ type: actionTypes.PORTFOLIO_UPDATE_FAILURE });
                return;
            }
        }
        try {
            await portfolioService.savePositions(editPortfolio.portfolio.id, securities)
            dispatch({type: actionTypes.PORTFOLIO_UPDATE_SUCCESS});
            dispatch({ type: actionTypes.PORTFOLIO_SAVED });
            dispatch(activeInventorySecurityActions.reset());
            dispatch(portfolioListActions.reset());
            history.replace(routes.portfolio);
        } catch (e) {
            dispatch({ type: actionTypes.PORTFOLIO_UPDATE_FAILURE });
            dispatch(errorActions.unexpectedError(e));
        }

    }
}

function deletePortfolioDialog(visible) {
    return {
        type: actionTypes.DELETE_PORTFOLIO_DIALOG,
        dialog: { visible }
    };
}

function deletePortfolio(portfolioId) {
    return dispatch => {
        portfolioService
            .deletePortfolio(portfolioId)
            .then(success)
            .catch(e => dispatch(errorActions.unexpectedError(e)));

        function success() {
            dispatch({ type: actionTypes.PORTFOLIO_DELETED });
            history.replace(routes.portfolio);
        }
    }
}

function setRequestingExportState(status) {
    return { type: actionTypes.PORTFOLIO_SET_REQUESTING_EXPORT_STATE, payload: { status }}
}

function exportPortfolio(id){
    return (dispatch) => {
        dispatch(setRequestingExportState(true));
        portfolioService
            .exportPortfolio(id)
            .then(file => saveAs(file.blob, file.name || 'portfolio.csv'))
            .catch(e => dispatch(errorActions.unexpectedError(e)))
            .finally(() => dispatch(setRequestingExportState(false)));
    };
}

function reset() {
    return dispatch => {
        dispatch({ type: actionTypes.RESET });
        dispatch(securitiesActions.reset());
        dispatch(errorActions.resetError());
    }
}
