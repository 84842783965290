import { ActionType, createAction } from 'typesafe-actions';
import { SignupRequest } from '../types/account/SignupRequest';

const submit = createAction("signup/SUBMIT", resolve => (request: SignupRequest) => resolve({ request }));
const submitSuccess = createAction("signup/SUBMIT_SUCCESS");
const submitError = createAction("signup/SUBMIT_ERROR");

export type TSignupAction = ActionType<
    typeof submit |
    typeof submitSuccess |
    typeof submitError
>;

export const signupActions = {
    submit,
    submitSuccess,
    submitError
}
