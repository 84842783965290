import { createAction } from "typesafe-actions";
import { RequestState } from "../../constants/request-state";
import { Company } from "../../types/amr-pipeline/models/Company";

const requestCollateralManagers = createAction('entities/collateral-managers/REQUEST', resolve => (requestState: RequestState) => resolve({ requestState}));

const responseCollateralManagers = createAction(
    'entities/collateral-managers/RESPONSE',
    resolve => (requestState: RequestState, collateralManagers: Company[]) => resolve({ requestState, collateralManagers })
);

export const collateralManagersActions = {
    requestCollateralManagers,
    responseCollateralManagers
};
