import { createAction } from 'typesafe-actions';
import { RequestState } from "../constants/request-state";
import { CompanySlim } from '../types/company/CompanySlim';

const request = createAction('entities/broker-dealer-list/REQUEST');
const requestResult = createAction('entities/broker-dealer-list/REQUEST_RESULT',
    resolve => (requestState: RequestState, result: CompanySlim[] = []) =>
        resolve({ requestState, result })
);
const updateDealerDistributionListInvitationSent = createAction(
    'entities/broker-dealer-list/UPDATE_DEALER_DISTRIBUTION_LIST_INVITATION_SEND',
    resolve => (dealerId: number) => resolve({ dealerId })
);
const updateDealerHeadOfTradingInvitationSent = createAction(
    'entities/broker-dealer-list/UPDATE_DEALER_HEAD_OF_TRADING_INVITATION_SEND',
    resolve => (dealerId: number) => resolve({ dealerId })
);
const resetEntities = createAction('entities/broker-dealer-list/RESET');


export const brokerDealerListActions = {
    request,
    requestResult,
    updateDealerDistributionListInvitationSent,
    updateDealerHeadOfTradingInvitationSent,
    resetEntities
};
