import { saveAs } from 'file-saver';
import {
    companiesActions as actionTypes,
    companyColumnsExportValues,
    companyRoles,
    companyStatuses,
    errorMessages,
    routes,
    roles,
    userStatuses
} from '../constants';
import { errorActions, notificationActions } from '.';
import { companiesService, locationService, salesRepresentativesService, usersService } from '../services';
import { RequestState } from "../constants/request-state";
import { history } from '../history';
import { logger } from '../logging/logger';
import { CompanyStatus } from '../types/company/CompanyStatus';
import { getSearchDateRange } from '../utils';
import { UserStatus } from '../types/account/UserStatus';
import { companyFilterInitialState, memberFilterInitialState } from '../reducers/companies.reducer';
import { amrPipelineService } from '../services/amr-pipeline.service';
import { isNil } from 'lodash';

export const companiesActions = {
    init,
    initCreateEditUser,
    reset,
    resetEditMember,
    orderCompaniesBy,
    orderMembersBy,
    approveCompany,
    rejectCompany,
    companyRejectReasonSet,
    companyApprovalConfirmationShow,
    companyApprovalConfirmationHide,
    companyRejectionConfirmationShow,
    companyRejectionConfirmationHide,
    companyStatusChangeConfirmation,
    memberStatusChangeConfirmation,
    resendInviteConfirmation,
    resetPasswordConfirmation,
    chnageTfaEnabledStatusConfirmation,
    chnageTfaEnabledStatus,
    resendInvite,
    resetPassword,
    changeCompanyStatus,
    changeMemberBlockedStatus,
    filterCompanies,
    filterMembers,
    showCompanyMembers,
    showMemberCompany,
    saveMember,
    setMemberOfacStatus,
    setCompanyOfacStatus,
    exportUsers,
    storeUsers,
    getUserLoginHistory,
    resetUserLoginHistory,
    memberSubscriptionChange,
    companySubscriptionChange,
    changeClearingBankSettings,
    setTempPassword,
    deleteUser,
    exportCompanies,
    companyPushDataReceived,
    applyCompanyFilters,
    applyMemberFilters,
    resetCompanyFilters,
    resetMemberFilters
};

function init() {
    return async (dispatch, getState) => {
        try {
            const companiesPromise = companiesService.getCompanies();
            const usersPromise = usersService.getUsers();
            const userRequestsPromise = usersService.getUserCreationRequests();
            const countriesPromise = locationService.getCountries();
            const salesRepresentativesPromise = salesRepresentativesService.getList();

            const companyList = await companiesPromise;
            const users = await usersPromise;
            const userRequests = await userRequestsPromise;
            const countries = await countriesPromise;
            const salesRepresentatives = await salesRepresentativesPromise;

            dispatch(storeCompanies(companyList));
            dispatch(storeUsers(users));
            dispatch(storeUserRequests(userRequests));
            dispatch({ type: actionTypes.COUNTRIES, countries });
            dispatch({ type: actionTypes.STORE_SALES_REPRESENTATIVES, salesRepresentatives });

            const { isInitCompleted, companyFilter, memberFilter } = getState().companies;

            if (isInitCompleted) {
                dispatch(filterCompanies(companyFilter));
                dispatch(filterMembers(memberFilter));
            } else {
                dispatch(initCompleted());
            }
        } catch (e) {
            dispatch(errorActions.criticalError(e));
        }
    };
}

function initCreateEditUser(userId, companyId = 0) {
    return async (dispatch) => {
        try {
            const companiesPromise = companiesService.getCompanies();
            const userPromise = userId
                ? usersService.getUser(userId)
                : Promise.resolve({
                    companyId,
                    firstName: '',
                    lastName: '',
                    jobTitle: '',
                    email: '',
                    phone: '',
                    roles: [],
                    primaryContact: false,
                });
            const [companies, user] = await Promise.all([companiesPromise, userPromise]);

            if(!userId && companyId){
                const company = companies.find(c => c.id === companyId);
                if(company?.role === companyRoles.Media){
                    user.roles = [roles.Media, roles.Viewer];
                }
            }

            dispatch(storeCompanies(companies));
            dispatch(setEditingUser(user))
        } catch (e) {
            dispatch(errorActions.criticalError(e));
        } finally {
            dispatch(updateStateEditUserInit(true));
        }
    }
}

function setEditingUser(user) {
    return { type: actionTypes.COMPANY_SET_EDITING_USER, payload: { user } }
}

function updateStateEditUserInit(status) {
    return { type: actionTypes.COMPANY_UPDATE_INIT_STATUS_EDIT_USER, payload: { status } }
}

function initCompleted() {
    return {
        type: actionTypes.INIT_COMPLETED
    };
}

function storeUsers(users) {
    return { type: actionTypes.USERS, users };
}

function storeCompanies(companies) {
    return {
        type: actionTypes.COMPANIES,
        companies
    };
}

function storeUserRequests(userRequests) {
    return {
        type: actionTypes.USER_REQUESTS,
        userRequests
    };
}

function orderCompaniesBy(column) {
    return (dispatch, getState) => {
        const { companyOrderBy } = getState().companies;

        let direction = 'asc';
        if (companyOrderBy.column === column) {
            direction = companyOrderBy.direction === 'asc' ? 'desc' : 'asc';
        }

        dispatch({
            type: actionTypes.ORDER_BY,
            orderBy: { column, direction }
        });
    };
}

function orderMembersBy(column) {
    return (dispatch, getState) => {
        const { memberOrderBy } = getState().companies;

        let direction = 'asc';
        if (memberOrderBy.column === column) {
            direction = memberOrderBy.direction === 'asc' ? 'desc' : 'asc';
        }

        dispatch({
            type: actionTypes.MEMBERS_ORDER_BY,
            orderBy: { column, direction }
        });
    };
}

function companyApprovalConfirmationShow(company) {
    return {
        type: actionTypes.COMPANY_APPROVAL_CONFIRMATION_SHOW,
        company,
    }
}

function companyApprovalConfirmationHide() {
    return {
        type: actionTypes.COMPANY_APPROVAL_CONFIRMATION_SHOW,
    }
}

function companyRejectionConfirmationShow(company) {
    return {
        type: actionTypes.COMPANY_REJECTION_CONFIRMATION_SHOW,
        company,
    }
}

function companyRejectionConfirmationHide() {
    return {
        type: actionTypes.COMPANY_REJECTION_CONFIRMATION_HIDE,
    }
}

function companyStatusChangeConfirmation(visible, company) {
    return {
        type: actionTypes.COMPANY_STATUS_CHANGE_CONFIRMATION,
        confirmation: { visible, company }
    };
}

function memberStatusChangeConfirmation(visible, member) {
    return {
        type: actionTypes.MEMBER_STATUS_CHANGE_CONFIRMATION,
        confirmation: { visible, member }
    };
}

function companyApproveRequest(companyId) {
    return {
        type: actionTypes.COMPANY_APPROVE_REQUEST,
        companyId,
    }
}

function companyApproveSuccess(companyId) {
    return {
        type: actionTypes.COMPANY_APPROVE_SUCCESS,
        companyId,
    }
}

function companyApproveFailure(companyId, error) {
    return {
        type: actionTypes.COMPANY_APPROVE_FAILURE,
        companyId,
        error,
    }
}

function companyRejectReasonSet(value) {
    return {
        type: actionTypes.COMPANY_REJECT_REASON_SET,
        value
    }
}

function approveCompany(companyId) {
    return (dispatch) => {
        dispatch(companyApproveRequest(companyId));
        companiesService.postCompanyApprove(companyId)
            .then(() => {
                dispatch(companyApproveSuccess(companyId));
            })
            .catch(e => {
                dispatch(companyApproveFailure(companyId, e));
                dispatch(notificationActions.notificationAddErrorMessage('Company approval failed'));
            })
    }
}

function rejectCompany(companyId) {
    return (dispatch, getState) => {
        dispatch(companyApproveRequest(companyId));
        companiesService.postCompanyReject(companyId, getState().companies.rejectionReason)
            .then(() => {
                dispatch(companyApproveSuccess(companyId));
            })
            .catch(e => {
                dispatch(companyApproveFailure(companyId, e));
                dispatch(notificationActions.notificationAddErrorMessage('Company rejection failed'));
            });
    }
}

function changeCompanyStatus(company, message = '') {
    return (dispatch) => {
        dispatch(companyStatusChangeConfirmation(false));

        const status =
            company.status === companyStatuses.offPlatform.statusCode ||
                company.status === companyStatuses.active.statusCode
                ? companyStatuses.blocked.statusCode : companyStatuses.active.statusCode;

        companiesService
            .changeCompanyStatus(company.id, status, message)
            .catch((e) => {
                dispatch(errorActions.unexpectedError(e))
            });
    };
}

function resendInviteConfirmation(visible, member) {
    return {
        type: actionTypes.RESEND_INVITE_CONFIRMATION,
        confirmation: { visible, member }
    };
}

function resetPasswordConfirmation(visible, member) {
    return {
        type: actionTypes.RESET_PASSWORD_CONFIRMATION,
        confirmation: { visible, member }
    };
}

function resetPassword(member) {
    return dispatch => {
        dispatch(resetPasswordConfirmation(false));

        usersService
            .resetPassword(member.id)
            .then(
                () => dispatch(notificationActions.warningModal(
                    'Password reset',
                    `Password setup link has been sent to ${member.fullName()}.`)),
                e => dispatch(errorActions.unexpectedError(e))
            );
    };
}

function resendInvite(member) {
    return (dispatch, getState) => {
        dispatch(resendInviteConfirmation(false));

        usersService
            .resendInvite(member.id)
            .then(
                invited,
                e => dispatch(errorActions.unexpectedError(e))
            );

        function invited(status) {
            dispatch(invitedAlert(`${member.firstName} ${member.lastName}`));

            const users = getState().companies.users.map(u =>
                u.id === member.id ? { ...u, status } : u);
            dispatch(storeUsers(users));
        }
    };
}

function invitedAlert(name) {
    return dispatch => {
        dispatch(
            notificationActions.warningModal(
                'Re-Send Invite',
                `The invitation email has been sent to ${name}.`
            )
        )
    };
}

function changeMemberBlockedStatus(member, message) {
    return (dispatch, getState) => {
        dispatch(memberStatusChangeConfirmation(false));

        if (member.status !== userStatuses.blocked.statusCode) {
            if (isUserPrimaryContact()) {
                dispatch(
                    notificationActions.notificationAddError(
                        'User can\'t be blocked', 'Primary Contact User can\'t be blocked. Please update User information.'
                    )
                );
                return;
            }

            Promise.all([
                usersService.disableUser(member.id, message).then(disabled, error),
                amrPipelineService.blockUserRequest(member.email),
            ]);
        } else {
            Promise.all([
                usersService.enableUser(member.id).then(enabled, error),
                amrPipelineService.unblockUserRequest(member.email),
            ]);
        }

        function isUserPrimaryContact() {
            const { users } = getState().companies;
            const user = users.find(u => u.id === member.id);

            return user && user.primaryContact;
        }

        function disabled() {
            const users = getState().companies.users.map(u =>
                u.id === member.id ? { ...u, status: userStatuses.blocked.statusCode, blockReason: message } : u);

            dispatch(storeUsers(users));
        }

        function enabled(user) {
            const users = getState().companies.users.map(u =>
                u.id === member.id ? { ...u, status: user.status, blockReason: '' } : u);

            dispatch(storeUsers(users));
        }

        function error(e) {
            dispatch(errorActions.unexpectedError(e));
        }
    };
}

function filterCompanies(filter) {
    return {
        type: actionTypes.COMPANY_FILTER_CHNAGED,
        filter
    };
}

function applyCompanyFilters() {
    return {
        type: actionTypes.COMPANY_FILTER_APPLY
    };
}

function applyMemberFilters() {
    return {
        type: actionTypes.MEMBER_FILTER_APPLY
    }
}

function resetCompanyFilters() {
    return {
        type: actionTypes.COMPANY_FILTER_RESET
    };
}

function resetMemberFilters() {
    return {
        type: actionTypes.MEMBER_FILTER_RESET
    }
}

function filterMembers(filter) {
    return {
        type: actionTypes.MEMBERS_FILTER_CHNAGED,
        filter
    };
}

function showCompanyMembers(company) {
    return (dispatch) => {
        const filter = {
            ...memberFilterInitialState,
            companies: [company.id]
        };
        dispatch(filterMembers(filter));
        dispatch(applyMemberFilters());
        history.replace(routes.manageCompanyMembers);
    }
}

function showMemberCompany(user) {
    return (dispatch) => {
        const filter = {
            ...companyFilterInitialState,
            searchTerm: user.companyName
        };

        dispatch(filterCompanies(filter));
        history.replace(routes.manageCompanies);
    }
}

function reset() {
    return dispatch => {
        dispatch({ type: actionTypes.RESET });
        dispatch(errorActions.resetError());
    };
}

function resetEditMember() {
    return { type: actionTypes.RESET_EDIT_MEMBER }
}

function saveMember(user) {
    return async (dispatch, getState) => {
        const { editMember } = getState().companies;
        const isEdit = !!user.id;
        const initialUser = editMember.initialUser;
        const isPrimaryContactAndCompanyChanged =
            isEdit &&
            user.primaryContact &&
            initialUser.primaryContact &&
            initialUser.companyId !== user.companyId;

        const userToSave = {
            ...user,
            phone: user.phone == null ? user.phone : user.phone.trim(),
            primaryContact: !!user.primaryContact,
            desk: isNil(user.desk) ? null : user.desk,
        };

        if (isPrimaryContactAndCompanyChanged) {
            dispatch(notificationActions.notificationAddError(
                errorMessages.userInformationCanNotBeChanged,
                errorMessages.primaryContactUserCanNotBeMovedToAnotherCompany)
            );
            return;
        }
        dispatch(savingUser(true));
        try {
            await usersService.saveUser(userToSave, editMember.sendInvite);
            history.replace(routes.manageCompanyMembers);
        } catch (e) {
            dispatch(errorActions.error(
                e,
                errorMessages.unexpectedError,
                errorMessages.userInformationCanNotBeChanged)
            );
        } finally {
            dispatch(savingUser(false));
            await amrPipelineService.saveUser({
                firstName: userToSave.firstName,
                lastName: userToSave.lastName,
                position: userToSave.jobTitle,
                phoneNumber: userToSave.phone,
                userEmail: userToSave.email,
                linkedIn: userToSave.linkedIn,
                location: userToSave.location
            })
        }
    }
}

function savingUser(savingUserFlag) {
    return {
        type: actionTypes.SAVING_USER,
        savingUserFlag
    };
}

function setMemberOfacStatus(memberId, status) {
    return dispatch => {
        dispatch(setMemberOfacRequest(memberId));
        companiesService.setMemberOfacStatus(memberId, status)
            .then((res) => {
                if (res && Number(res.id)) {
                    dispatch(setMemberOfacSuccess(Number(res.id), res.ofac))
                } else {
                    dispatch(setMemberOfacFailure(memberId))
                }
            })
            .catch((e) => {
                dispatch(errorActions.unexpectedError(e));
                dispatch(setMemberOfacFailure(memberId));
            })
    }
}

function setMemberOfacRequest(memberId) {
    return (dispatch, getState) => {
        const { membersOfacRequestStatus } = getState().companies;
        const newStatusList = { ...membersOfacRequestStatus };
        newStatusList[memberId] = RequestState.request;
        dispatch({
            type: actionTypes.COMPANY_MEMBER_SET_OFAC_REQUEST,
            payload: { membersOfacRequestStatus: newStatusList }
        })
    }
}

function setMemberOfacSuccess(memberId, ofac) {
    return (dispatch, getState) => {
        let { users, membersOfacRequestStatus } = getState().companies;
        const newUsers = [...users].map((member) => {
            if (member.id === memberId) {
                member.ofac = ofac
            }
            return member
        });

        const newStatusList = { ...membersOfacRequestStatus };
        if (newStatusList[memberId]) delete newStatusList[memberId];

        dispatch({
            type: actionTypes.COMPANY_MEMBER_SET_OFAC_SUCCESS,
            payload: { membersOfacRequestStatus: newStatusList, users: newUsers }
        })
    }
}

function setMemberOfacFailure(memberId) {
    return (dispatch, getState) => {
        const { membersOfacRequestStatus } = getState().companies;
        const newStatusList = { ...membersOfacRequestStatus };
        if (newStatusList[memberId]) delete newStatusList[memberId];
        dispatch({
            type: actionTypes.COMPANY_MEMBER_SET_OFAC_FAILURE,
            payload: { membersOfacRequestStatus: newStatusList }
        })
    }
}

function setCompanyOfacStatus(companyId, status) {
    return dispatch => {
        dispatch(setCompanyOfacRequest(companyId));
        companiesService.setCompanyOfacStatus(companyId, status)
            .then((res) => {
                if (res && Number(res.id)) {
                    dispatch(setCompanyOfacSuccess(Number(res.id), res.ofac))
                } else {
                    dispatch(setCompanyOfacFailure(companyId))
                }
            })
            .catch(() => {
                dispatch(setCompanyOfacFailure(companyId))
            })
    }
}

function setCompanyOfacRequest(companyId) {
    return (dispatch, getState) => {
        const { companiesOfacRequestStatus } = getState().companies;
        const newStatusList = { ...companiesOfacRequestStatus };
        newStatusList[companyId] = RequestState.request;
        dispatch({
            type: actionTypes.COMPANY_SET_OFAC_REQUEST,
            payload: { companiesOfacRequestStatus: newStatusList }
        })
    }
}

function setCompanyOfacSuccess(companyId, ofac) {
    return (dispatch, getState) => {
        let { companies, companiesOfacRequestStatus } = getState().companies;
        const newCompanies = [...companies].map((company) => {
            if (company.id === companyId) {
                company.ofac = ofac
            }
            return company
        });

        const newStatusList = { ...companiesOfacRequestStatus };
        if (newStatusList[companyId]) delete newStatusList[companyId];

        dispatch({
            type: actionTypes.COMPANY_SET_OFAC_SUCCESS,
            payload: { companiesOfacRequestStatus: newStatusList, companies: newCompanies }
        })
    }
}

function setCompanyOfacFailure(companyId) {
    return (dispatch, getState) => {
        const { companiesOfacRequestStatus } = getState().companies;
        const newStatusList = { ...companiesOfacRequestStatus };
        if (newStatusList[companyId]) delete newStatusList[companyId];
        dispatch({
            type: actionTypes.COMPANY_SET_OFAC_FAILURE,
            payload: { companiesOfacRequestStatus: newStatusList }
        })
    }
}

function getFilterCriteria(filter) {

    const statuses = [
        filter.active && UserStatus.Active,
        filter.blocked && UserStatus.Blocked,
        filter.invited && UserStatus.Invited,
        filter.notInvited && UserStatus.NotInvited,
        filter.pendingApproval && 'pending-approval'
    ].filter(status => status);

    const rolesArray = [
        filter.administrator && roles.Administrator,
        filter.arrangersClient && roles.ArrangersClient,
        filter.brokerDealerTrader && roles.BrokerDealerTrader,
        filter.brokerDealerViewer && roles.BrokerDealerViewer,
        filter.dataEntry && roles.DataEntry,
        filter.sellerAdmin && roles.SellerAdministrator,
        filter.sellerTrader && roles.SellerTrader,
        filter.sellerViewer && roles.SellerViewer,
        filter.subscriptionManager && roles.SubscriptionManager,
        filter.viewer && roles.Viewer,
        filter.collateralManager && roles.CollateralManager,
        filter.media && roles.Media
    ].filter(status => status);

    const dateFilter = getSearchDateRange(filter);
    const regByFrom = dateFilter.dateFrom;
    const regByTo = dateFilter.dateTo;

    return {
        searchTerms: filter.searchTerm,
        statuses,
        roles: rolesArray,
        companyIds: filter.companies,
        regByIds: filter.regBy,
        regByFrom,
        regByTo
    };
}

function exportUsers() {
    return (dispatch, getState) => {
        const { exportUsersRequestState } = getState().companies;
        const filter = getState().companies.memberFilter;
        const filterOptions = getFilterCriteria(filter);

        if (exportUsersRequestState !== RequestState.request) {
            dispatch({ type: actionTypes.MANAGE_EXPORT_USERS_REQUEST });
            usersService.exportUsers(filterOptions)
                .then(file => {
                    saveAs(file.blob, file.name || 'kopenTech_BWIC_users.xlsx');
                    dispatch({ type: actionTypes.MANAGE_EXPORT_USERS_SUCCESS });
                })
                .catch(() => {
                    dispatch({ type: actionTypes.MANAGE_EXPORT_USERS_FAILURE })
                });
        }
    }
}

function chnageTfaEnabledStatusConfirmation(visible, member) {
    return {
        type: actionTypes.TFA_STATUS_CHANGE_CONFIRMATION,
        confirmation: { visible, member }
    };
}

function chnageTfaEnabledStatus(member) {
    return dispatch => {
        dispatch(chnageTfaEnabledStatusConfirmation(false));

        usersService
            .updateTfaEnabledState(member.id, !member.tfaEnabled)
            .then(() => dispatch({ type: actionTypes.TFA_ENABLED_STATE_CHANGED, userId: member.id }))
            .catch(e => dispatch(errorActions.unexpectedError(e)));
    };
}

function getUserLoginHistory(user) {
    return dispatch => {
        usersService.getUserLoginHistory(user.id)
            .then((history) => dispatch({
                type: actionTypes.COMPANY_MEMBER_SET_USER_LAST_LOGIN_HISTORY,
                payload: { history, user }
            }))
            .catch(e => dispatch(errorActions.unexpectedError(e)))
    }
}

function resetUserLoginHistory() {
    return dispatch => dispatch({ type: actionTypes.COMPANY_MEMBER_RESET_USER_LAST_LOGIN_HISTORY })
}

function memberSubscriptionChange(userId, subscriptionType, trialPeriodEnd) {
    return {
        type: actionTypes.COMPANY_MEMBER_SUBSCRIPTION_CHANGE,
        userId,
        subscriptionType,
        trialPeriodEnd
    };
}

function companySubscriptionChange(companyId, subscriptionType, subscriptionPeriodEnd) {
    return {
        type: actionTypes.COMPANY_SUBSCRIPTION_CHANGE,
        companyId,
        subscriptionType,
        subscriptionPeriodEnd,
    };
}

function changeClearingBankSettings(companyId, isClearingBank) {
    return {
        type: actionTypes.COMPANY_SET_CLEARING_BANK,
        companyId,
        isClearingBank
    };
}

function deleteUser(userId) {
    return async (dispatch, getState) => {
        const user = getState().companies.users.find(u => u.id === userId);
        const companyUserCount = getState().companies.users.filter(u => u.companyId === user.companyId).length;
        try {
            await usersService.deleteUser(userId);
            dispatch({ type: actionTypes.COMPANY_MEMBER_DELETED, payload: { userId } });
            if(companyUserCount === 1) { // Last one deleted
                dispatch(resetSubscription(user.companyId));
            }
        } catch (e) {
            dispatch(errorActions.unexpectedError(e));
        }
    }
}

function resetSubscription(companyId) {
    return { type: actionTypes.RESET_SUBSCRIPTION, payload: { companyId } }
}

function setTempPassword(userId) {
    return dispatch => {
        usersService.setTempPassword(userId)
            .catch(e => dispatch(errorActions.unexpectedError(e)));
    }
}

function getUserListRequestStatus(status) {
    return { type: actionTypes.COMPANY_GET_USER_LIST_REQUEST_STATUS, payload: { status } }
}

function getUserList() {
    return async (dispatch) => {
        dispatch(getUserListRequestStatus(true));
        try {
            const users = await usersService.getUsers();
            dispatch(companiesActions.storeUsers(users));
        } catch (e) {
            logger.exception(e, 'Failed to load company users');
        } finally {
            dispatch(getUserListRequestStatus(false));
        }
    }
}

function companyPushDataReceived(company) {
    return async (dispatch, getState) => {
        const { companies } = getState().companies;
        const targetCompany = companies.find(c => c.id === company.id);

        if (targetCompany && (targetCompany.status !== company.status || targetCompany.role !== company.role)) {
            dispatch(getUserList());
        }
        dispatch({ type: actionTypes.COMPANY_PUSH_DATA_RECEIVED, payload: { company } })
    }
}

function exportCompanies() {
    return async (dispatch, getState) => {
        dispatch(setIsRequestingExportCompany(true))
        try {
            const filter = getState().companies.companyFilter;
            const order = getState().companies.companyOrderBy;

            const searchTerm = filter.searchTerm;
            const regByIds = filter.regBy;
            const status = [
                filter.active && CompanyStatus.active,
                filter.blocked && CompanyStatus.blocked,
                filter.offPlatform && CompanyStatus.offPlatform
            ].filter(status => status);

            const role = [
                filter.administrator && companyRoles.Administrator,
                filter.brokerDealer && companyRoles.BrokerDealer,
                filter.seller && companyRoles.Seller,
                filter.viewer && companyRoles.Viewer,
                filter.media && companyRoles.Media
            ].filter(status => status);

            const dateFilter = getSearchDateRange(filter);
            const regByFrom = dateFilter.dateFrom;
            const regByTo = dateFilter.dateTo;
            const salesRepresentative = filter.salesRepresentatives;

            const orderBy = order.direction;
            const orderByColumn = companyColumnsExportValues[order.column];

            const file = await companiesService.exportCompanies(searchTerm, status, role, regByFrom, regByTo, salesRepresentative, regByIds, orderByColumn, orderBy)

            saveAs(file.blob, file.name);
        } catch (e) {
            dispatch(errorActions.unexpectedError(e))
        } finally {
            dispatch(setIsRequestingExportCompany(false))
        }
    }
}

function setIsRequestingExportCompany(status) {
    return { type: actionTypes.MANAGE_SET_REQUEST_STATE_EXPORT_COMPANIES, payload: { status } }
}
