import { ActionType, createAction } from 'typesafe-actions';
import { ManageEmailFilterState } from '../types/state/ManageEmailState';

const setFilter = createAction(
    'manageEmail/UPDATE_FILTER',
    resolve => (filter: ManageEmailFilterState) => resolve({ filter })
)

export type manageEmailActionsType = ActionType<typeof setFilter>

export const manageEmailActions = {
    setFilter,
}
