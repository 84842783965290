import { createAction } from 'typesafe-actions';
import { SellerAdminEditFormModel } from '../types/seller-admin/SellerAdminEditFormModel';
import { CompanyUser } from '../types/management/CompanyUser';
import { UserCreationRequest } from '../types/management/UserCreationRequest';
import { RequestState } from '../constants/request-state';
import { UserStatus } from '../types/account/UserStatus';
import { UpdateBwicAnnouncementNotifications } from '../types/profile/UpdateBwicAnnouncementNotifications';

const getUserListRequest = createAction('sellerAdminUsers/GET_USERS_LIST/REQUEST');
const getUserListSuccess = createAction(
    'sellerAdminUsers/GET_USERS_LIST/SUCCESS',
    resolve => (users: CompanyUser[], requestUsers: UserCreationRequest[]) => resolve({ users, requestUsers })
);
const getUserListFailure = createAction('sellerAdminUsers/GET_USERS_LIST/FAILURE');

const saveUser = createAction(
    'sellerAdminUsers/SAVE_USER',
    resolve => (data: SellerAdminEditFormModel, userId: number) => resolve({ data, userId })
);
const setUserUpdateState = createAction(
    'sellerAdminUsers/DET_UPDATE_USER_STATE',
    resolve => (requestState: RequestState, user?: CompanyUser) => resolve({ requestState, user })
);
const userSaved = createAction(
    'sellerAdminUsers/SET_USER_SAVED',
    resolve => (user: CompanyUser) => resolve({ user })
);

const saveUserRequest = createAction(
    'sellerAdminUsers/SAVE_USER_REQUEST',
    resolve =>
        (data: SellerAdminEditFormModel, userRequestId: number, concurrencyStamp?: Date | undefined) =>
            resolve({ data, userRequestId, concurrencyStamp })
);
const setUserRequestUpdateState = createAction(
    'sellerAdminUsers/SET_USER_REQUEST_UPDATE_STATE',
    resolve => (requestState: RequestState) => resolve({ requestState })
);
const userRequestSaved = createAction(
    'sellerAdminUsers/SET_USER_REQUEST_SAVED',
    resolve => (userRequest: UserCreationRequest) => resolve({ userRequest })
);

const disableUser = createAction(
    'sellerAdminUsers/DISABLE_USER',
    resolve => (userId: number, blockReason: string) => resolve({ userId, blockReason })
);
const enableUser = createAction(
    'sellerAdminUsers/ENABLE_USER',
    resolve => (userId: number) => resolve({ userId })
);
const setUserOperationState = createAction(
    'sellerAdminUsers/SET_USER_OPERATION_STATE',
    resolve => (requestState: RequestState, userId: number) => resolve({ requestState, userId, })
);
const setUserEnabled = createAction(
    'sellerAdminUsers/SET_ENABLED_USER',
    resolve => (userId: number, status: UserStatus) => resolve({ userId, status })
);
const setUserDisable = createAction(
    'sellerAdminUsers/SET_DISABLED_USER',
    resolve => (userId: number, blockReason: string) => resolve({ userId, blockReason })
);

const disableUserRequest = createAction(
    'sellerAdminUsers/DISABLE_USER_REQUEST',
    resolve => (userRequestId: number, concurrencyStamp: Date, blockReason: string,) => resolve({
        userRequestId,
        concurrencyStamp,
        blockReason
    })
);
const enableUserRequest = createAction(
    'sellerAdminUsers/ENABLE_USER_REQUEST',
    resolve => (userRequestId: number, concurrencyStamp: Date) => resolve({ userRequestId, concurrencyStamp })
);
const setUserRequestOperationState = createAction(
    'sellerAdminUsers/SET_USER_REQUEST_OPERATION_STATE',
    resolve => (requestState: RequestState, userId: number) => resolve({
        requestState,
        userId
    })
);
const userRequestEnabled = createAction(
    'sellerAdminUsers/SET_USER_REQUEST_ENABLED',
    resolve => (userRequest: UserCreationRequest) => resolve({ userRequest })
);
const userRequestDisabled = createAction(
    'sellerAdminUsers/SET_USER_REQUEST_DISABLED',
    resolve => (userRequest: UserCreationRequest) => resolve({ userRequest })
);

const exportUsersRequest = createAction('sellerAdminUsers/EXPORT_REQUEST');
const exportUsersResponse = createAction('sellerAdminUsers/EXPORT_RESPONSE');

const reset = createAction('sellerAdminUsers/RESET');

const changeBwicAnnouncementNotificationsRequest = createAction(
    'sellerAdminUsers/CHANGE_BWIC_ANNOUNCMENT_NOTIFICATIONS_REQUEST',
    resolve => (requestState: RequestState) => resolve({ requestState })
)

const changeBwicAnnouncementNotifications = createAction(
    'sellerAdminUsers/CHANGE_BWIC_ANNOUNCMENT_NOTIFICATIONS',
    resolve => (bwicAnnouncementNotifications: UpdateBwicAnnouncementNotifications) =>
        resolve({
            bwicCreateNotifyAdmins: bwicAnnouncementNotifications.bwicCreateNotifyAdmins,
            bwicCreateNotifyViewers: bwicAnnouncementNotifications.bwicCreateNotifyViewers
        })
)

export const sellerAdminUsersActions = {
    getUserListRequest,
    getUserListSuccess,
    getUserListFailure,
    saveUserRequest,
    setUserRequestUpdateState,
    userRequestSaved,
    saveUser,
    setUserUpdateState,
    userSaved,
    disableUser,
    enableUser,
    setUserOperationState,
    setUserEnabled,
    setUserDisable,
    disableUserRequest,
    enableUserRequest,
    reset,
    setUserRequestOperationState,
    userRequestEnabled,
    userRequestDisabled,
    exportUsersRequest,
    exportUsersResponse,
    changeBwicAnnouncementNotificationsRequest,
    changeBwicAnnouncementNotifications
};
