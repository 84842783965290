import { ActionType, createAction } from "typesafe-actions";
import { RequestState } from "../../constants/request-state";
import { RejectedTrade } from '../../types/trades/RejectedTrade';

const request = createAction('entities/rejected-trades/REQUEST');
const requestResult = createAction('entities/rejected-trades/REQUEST_RESULT',
    resolve => (requestState: RequestState, trades: RejectedTrade[] = []) =>
        resolve({ requestState, trades })
);
const setTradesAsResolved = createAction('entities/rejected-trades/SET_AS_RESOLVED',
    resolve => (tradeIds: string[]) => resolve({ tradeIds })
);
const pushBuyerRejectTrade = createAction(
    'entities/rejected-trades/PUSH_BUYER_REJECT_TRADE',
    resolve => (trade: RejectedTrade) => resolve({ trade })
);

export type TRejectedTradesActionType = ActionType<typeof request | typeof requestResult | typeof setTradesAsResolved | typeof pushBuyerRejectTrade>;

export const rejectedTradeActions = {
    request,
    requestResult,
    setTradesAsResolved,
    pushBuyerRejectTrade,
}
