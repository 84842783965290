import { ActionType, createAction } from 'typesafe-actions';
import { MarketingEvent } from '../types/marketing/MarketingEvent';
import { News } from '../types/news/News';
import { NewsQueryParams } from '../types/news/NewsQueryParams';
import { NewsTabCategory } from '../types/news/enums/NewsTabCategory';
import { NewsAccessType } from '../types/amr-pipeline/enums/NewsAccessType';

const fetchMarketingEventsRequest = createAction('news/FETCH_MARKETING_EVENTS_REQUEST');
const fetchMarketingEventsResponse = createAction(
    'news/FETCH_MARKETING_EVENTS_RESPONSE',
    resolve => (events: MarketingEvent[]) => resolve({ events }),
);


const fetchNewsRequest = createAction('news/FETCH_REQUEST', resolve => (page: number, tab: NewsTabCategory) => resolve({ page, tab }));
const fetchNewsResponse = createAction(
    'news/FETCH_RESPONSE',
    resolve => (news: News[], page: number, tab: NewsTabCategory) => resolve({ news, page, tab }),
);

const fetchNewsDetails = createAction(
    'news/FETCH_DETAILS',
    resolve => (newsReferenceName: string, queryParams?: NewsQueryParams) =>
        resolve({ newsReferenceName, queryParams }),
);

const fetchNewsDetailsResponse = createAction(
    'news/FETCH_DETAILS_RESPONSE',
    resolve => (news: News) => resolve({ news }),
);

const fetchTotalNewsCountRequest = createAction('news/FETCH_TOTAL_NEWS_COUNT_REQUEST');
const fetchTotalNewsCountResponse = createAction(
    'news/FETCH_TOTAL_NEWS_COUNT_RESPONSE',
    resolve => (totalNews: {
        [NewsTabCategory.All]: number,
        [NewsTabCategory.Primary]: number,
        [NewsTabCategory.Secondary]: number,
        [NewsTabCategory.Research]: number,
    }) => resolve({ totalNews }),
);

const deleteNews = createAction(
    'news/DELETE',
    resolve => (referenceName: string, page: number, tab: NewsTabCategory) => resolve({ referenceName, page, tab }),
);

const deleteSingleNews = createAction(
    'news/DELETE_SINGLE_NEWS',
    resolve => (referenceName: string) => resolve({ referenceName }),
);

const resetNewsList = createAction('news/RESET_NEWS_LIST');
const reset = createAction('news/RESET');

const logUserActivity = createAction('news/LOG_USER_ACTIVITY', resolve => (accessType: NewsAccessType, link?: string) => resolve({ accessType, link }));

const downloadDocument = createAction(
    'news/DOWNLOAD_DOCUMENT',
    resolve => (newsReferenceName: string, documentReferenceName: string, fileName: string) =>
    resolve({ newsReferenceName, documentReferenceName, fileName })
);

const downloadDocuments = createAction(
    'news/DOWNLOAD_DOCUMENTS',
    resolve => (newsItem: News) => resolve({ newsItem })
);

export type TNewsActions = ActionType<
    typeof newsActions[keyof typeof newsActions]
>;

export const newsActions = {
    fetchNewsRequest,
    fetchNewsResponse,
    fetchMarketingEventsRequest,
    fetchMarketingEventsResponse,
    fetchTotalNewsCountRequest,
    fetchTotalNewsCountResponse,
    fetchNewsDetails,
    fetchNewsDetailsResponse,
    deleteNews,
    deleteSingleNews,
    reset,
    resetNewsList,
    logUserActivity,
    downloadDocument,
    downloadDocuments,
};
