import { createAction } from 'typesafe-actions';
import { DateFilterOption } from '../types/filters/DateFilterOption';
import { DateRange } from '../types/filters/DateRange';

const toogleFilterSalesRepresentative = createAction('manage-companies/COMPANY_FILTER_TOOGLE_SALES_REPS',
    resolve => (salesRepresentatives: number[]) => resolve({ salesRepresentatives })
)

const setFilterSalesRepresentative = createAction('manage-companies/COMPANY_FILTER_SET_SALES_REPS',
    resolve => (salesRepresentatives: number[]) => resolve({ salesRepresentatives })
)

const toogleFilterRegBy = createAction('manage-companies/COMPANY_FILTER_TOOGLE_REG_BY',
    resolve => (regBy: number[]) => resolve({ regBy })
)

const setFilterRegBy = createAction('manage-companies/COMPANY_FILTER_SET_REG_BY',
    resolve => (regBy: number[]) => resolve({ regBy })
)

const toogleFilterStatusActive = createAction('manage-companies/COMPANY_FILTER_TOOGLE_STATUS_ACTIVE');
const toogleFilterStatusBlocked = createAction('manage-companies/COMPANY_FILTER_TOOGLE_STATUS_BLOCKED');
const toogleFilterStatusOffPlatform = createAction('manage-companies/COMPANY_FILTER_TOOGLE_STATUS_OFF_PLATFORM');

const toogleFilterRoleAdministrator = createAction('manage-companies/COMPANY_FILTER_TOOGLE_ROLE_ADMINISTRATOR')
const toogleFilterRoleBrokerDealer = createAction('manage-companies/COMPANY_FILTER_TOOGLE_ROLE_BROKER_DEALER')
const toogleFilterRoleSeller = createAction('manage-companies/COMPANY_FILTER_TOOGLE_ROLE_SELLER')
const toogleFilterRoleViewer = createAction('manage-companies/COMPANY_FILTER_TOOGLE_ROLE_VIEWER')
const toogleFilterRoleMedia = createAction('manage-companies/COMPANY_FILTER_TOOGLE_ROLE_MEDIA')

const setDateFilterChange = createAction('manage-companies/COMPANY_FILTER_DATE_CHANGED',
    resolve => (dateOption: DateFilterOption) => resolve({ dateOption })
)

const setDateFilterRangeChange = createAction('manage-companies/COMPANY_FILTER_DATE_RANGE_CHANGED',
    resolve => (dateRange: DateRange) => resolve({ dateRange })
)

export const manageCompaniesFiltersActions = {
    toogleFilterSalesRepresentative,
    setFilterSalesRepresentative,
    toogleFilterRegBy,
    setFilterRegBy,
    toogleFilterStatusActive,
    toogleFilterStatusBlocked,
    toogleFilterStatusOffPlatform,
    toogleFilterRoleAdministrator,
    toogleFilterRoleBrokerDealer,
    toogleFilterRoleSeller,
    toogleFilterRoleViewer,
    toogleFilterRoleMedia,
    setDateFilterChange,
    setDateFilterRangeChange
}