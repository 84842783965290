import moment from 'moment';
import {
    editParsedBwicActions as actionTypes,
    errorMessages,
    routes,
    gridColumns,

    constants
} from '../constants';
import { parsedBwicsService, daysService, bwicService } from '../services';
import { errorActions, notificationActions, securitiesActions } from '.';
import { dateTimeUtils, numericUtils } from '../utils';
import { history } from '../history';
import { gridActions } from './grid.actions';
import { priceTalksActions } from './price-talks.actions';
import { BwicProcessType } from '../types/models/Process';
import { values } from 'lodash';
import { Stage2Participants } from '../types/models/ParsedBwicProcess';

export const editParsedBwicActions = {
    init,
    reset,
    changeDate,
    changeDaysToSettle,
    changeTimeZone,
    changeBidsDueTime,
    changeGoodUntilTime,
    changeCustomRules,
    changeReserveLevelsApply,
    changeCanTradeEarly,
    changeCanBidEarly,
    changePartialBidsAccepted,
    changeBwicProcessType,
    changeHardCutOffNumber,
    changeStage1EndTime,
    changeStage2EndTime,
    changeBidsGoodForHours,
    changeBidsGoodForMinutes,
    changeStage2Participants,
    showManageParsedBwics,
    save,
    saveAndPublish,
    saveOnExit,
    changeEmailReceivedDate,
    storeOriginalBwic,
    cancelDialog,
    cancelBwic
}

export const getProcessSpecificSettings = parsedProcess => {
    switch (parsedProcess?.processType) {
        case BwicProcessType.JumpBall: return parsedProcess.jumpBall;
        case BwicProcessType.TopX: return parsedProcess.topX;
        case BwicProcessType.BestFootForward: return parsedProcess.bff;
        case BwicProcessType.Unknown: return parsedProcess.unknown;
        default: return {};
    }
}


function init(bwicReferenceName) {
    return async (dispatch, getState) => {
        try {
            const day = await daysService.getNextBusinessDate();

            const bwicPromise = bwicReferenceName
                ? bwicService.getByReferenceName(bwicReferenceName)
                : Promise.resolve(getState().editParsedBwic.bwic || { securities: [] });

            const bwic = await bwicPromise;

            if (bwic.referenceName) {
                dispatch(changeDate(bwic.date));
                dispatch(changeBidsDueTime(bwic.bidsDue));
                dispatch(changeGoodUntilTime(bwic.goodUntil));
                dispatch(changeTimeZone(bwic.timeZone.id));
                dispatch(changeDaysToSettle(bwic.daysToSettle));
                dispatch(changeCustomRules((bwic.process && bwic.process.rulesText) || ''));
                dispatch(securitiesActions.init(bwic.securities, gridColumns.securities()));
                dispatch(storeOriginalBwic(bwic));
                dispatch(changeEmailReceivedDate(bwic.emailReceivedDate))

                if (bwic.parsedProcess) {
                    const processSettings = getProcessSpecificSettings(bwic.parsedProcess);
                    dispatch(changeReserveLevelsApply(bwic.parsedProcess.reserveLevelsApply))
                    dispatch(changeCanTradeEarly(bwic.parsedProcess.canTradeEarly))
                    dispatch(changeCanBidEarly(bwic.parsedProcess.canBidEarly))
                    dispatch(changePartialBidsAccepted(bwic.parsedProcess.partialBidsAccepted))
                    dispatch(changeBwicProcessType(bwic.parsedProcess.processType))
                    dispatch(changeHardCutOffNumber(processSettings.hardCutOff))
                    dispatch(changeStage1EndTime(processSettings.stage1EndDateUtc))
                    dispatch(changeStage2EndTime(processSettings.stage2EndDateUtc))
                    dispatch(changeStage2Participants(processSettings.improverCount))
                    if (bwic.parsedProcess.bidsGoodFor) {
                        const timeSpan = dateTimeUtils.parseTimeSpan(bwic.parsedProcess.bidsGoodFor);
                        if (timeSpan) {
                            dispatch(changeBidsGoodForHours(timeSpan.hours || ''))
                            dispatch(changeBidsGoodForMinutes(timeSpan.minutes || ''))
                        }
                    }
                }
            } else {
                dispatch(changeDate(day));
                dispatch(changeBidsDueTime(dateTimeUtils.timestampToUtcString('10:00 AM')));
                dispatch(changeGoodUntilTime(dateTimeUtils.timestampToUtcString('12:00 PM')));
                dispatch(changeDaysToSettle(constants.daysToSettleDefault));
                dispatch(changeReserveLevelsApply(true));
                dispatch(changeBwicProcessType(BwicProcessType.TopX));
                dispatch(changeCustomRules(''));
                dispatch(securitiesActions.init(bwic.securities, gridColumns.securities()));
                dispatch(storeOriginalBwic(bwic));
            }
        } catch (e) {
            dispatch(errorActions.criticalError(e));
        } finally {
            dispatch({ type: actionTypes.INIT_COMPLETED })
        }
    }
}

function storeOriginalBwic(bwic) {
    return {
        type: actionTypes.STORE_ORIGINAL_BWIC,
        bwic
    };
}

function reset() {
    return dispatch => {
        dispatch({ type: actionTypes.RESET });
        dispatch(securitiesActions.reset());
        dispatch(errorActions.resetError());
    }
}

function changeEmailReceivedDate(date) {
    return (dispatch, getState) => {
        const { errors = {} } = getState().editParsedBwic;

        dispatch({
            type: actionTypes.SET_EMAIL_RECEIVED_DATE,
            payload: { date }
        });

        if (errors && errors.emailReceivedDate) {
            dispatch(validate());
        }
    };
}

function changeDate(date) {
    return (dispatch, getState) => {
        const { errors = {} } = getState().editParsedBwic;

        dispatch({ type: actionTypes.CHANGE_DATE, date });
        if (errors && errors.date) {
            dispatch(validate());
        }
    }
}

function changeDaysToSettle(days) {
    return {
        type: actionTypes.DAYS_TO_SETTLE,
        days
    };
}

function changeTimeZone(timeZone) {
    return {
        type: actionTypes.TIME_ZONE,
        timeZone
    };
}

function changeBidsDueTime(time) {
    return (dispatch, getState) => {
        const { errors = {} } = getState().editParsedBwic;

        dispatch({ type: actionTypes.BIDS_DUE, time });
        if (hasTimeErrors(errors)) {
            dispatch(validate());
        }

        const bidsDueTime = moment.utc(time);
        const goodUntilTime = moment.utc(time).add(2, 'hour');

        const goodUntil = goodUntilTime.isSame(bidsDueTime, 'day')
            ? goodUntilTime.format(constants.dateTimeFormatUtc)
            : dateTimeUtils.timestampToUtcString('11:59 PM');

        dispatch(changeGoodUntilTime(goodUntil));
    }
}

function changeGoodUntilTime(time) {
    return (dispatch, getState) => {
        const { errors = {} } = getState().editParsedBwic;

        dispatch({ type: actionTypes.GOOD_UNTIL, time });
        if (hasTimeErrors(errors)) {
            dispatch(validate());
        }
    }
}

function hasTimeErrors(errors = {}) {
    return errors.time || errors.bidsGoodFor || errors.stage1EndDateUtc || errors.stage2EndDateUtc;
}

function changeCustomRules(rulesText) {
    return {
        type: actionTypes.RULES_TEXT,
        rulesText
    };
}

function changeReserveLevelsApply(value) {
    return {
        type: actionTypes.RESERVE_LEVELS_APPLY_CHANHE,
        value
    };
}
function changeCanTradeEarly(value) {
    return {
        type: actionTypes.CAN_TRADE_EARLY_CHANGE,
        value
    };
}
function changeCanBidEarly(value) {
    return {
        type: actionTypes.CAN_BID_EARLY_CHANGE,
        value
    };
}
function changePartialBidsAccepted(value) {
    return {
        type: actionTypes.PARTIAL_BIDS_ACCEPTED_CHANHE,
        value
    };
}
function getDefaultStage1EndTimeUtc(processType) {
    const defaultValue = '10:30 AM';
    return processType === BwicProcessType.TopX ||
        processType === BwicProcessType.JumpBall ||
        processType === BwicProcessType.BestFootForward ||
        processType === BwicProcessType.Unknown
        ? dateTimeUtils.timestampToUtcString(defaultValue)
        : null;
}
function getDefaultStage2EndTimeUtc(processType) {
    const defaultValue = '12:00 PM';
    return processType === BwicProcessType.TopX ||
        processType === BwicProcessType.JumpBall ||
        processType === BwicProcessType.Unknown
        ? dateTimeUtils.timestampToUtcString(defaultValue)
        : null;
}
function getDefaultStage2Participants(processType) {
    return processType === BwicProcessType.JumpBall ||
        processType === BwicProcessType.TopX ||
        processType === BwicProcessType.Unknown
        ? Stage2Participants.Top3
        : null;
}
function changeBwicProcessType(value) {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.BWIC_PROCESS_TYPE_CHANGE,
            value
        });

        const hardCutOff = value === BwicProcessType.Standard ? null : 1;
        dispatch(changeHardCutOffNumber(hardCutOff));
        dispatch(changeStage1EndTime(getDefaultStage1EndTimeUtc(value)));
        dispatch(changeStage2EndTime(null))
        dispatch(changeStage2Participants(getDefaultStage2Participants(value)));

        if (hasTimeErrors(getState().editParsedBwic.errors)) {
            dispatch(validate());
        }
    };
}
function changeHardCutOffNumber(value) {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.HARD_CUT_OFF_NUMBER_CHANGE,
            value
        });

        const processType = getState().editParsedBwic.bwicProcessType;

        dispatch(changeStage1EndTime(getDefaultStage1EndTimeUtc(processType)));
        dispatch(changeStage2EndTime(+value === 2 ? getDefaultStage2EndTimeUtc(processType) : null))
        dispatch(changeStage2Participants(getDefaultStage2Participants(processType)));

        if (hasTimeErrors(getState().editParsedBwic.errors)) {
            dispatch(validate());
        }
    }
}
function changeStage1EndTime(value) {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.STAGE_1_TIME_CHANGE,
            value
        });

        const { errors } = getState().editParsedBwic;
        if (errors?.stage1EndDateUtc || errors.stage2EndDateUtc) {
            dispatch(validate());
        }
    }
}
function changeStage2EndTime(value) {
    return (dispatch, getState) => {

        dispatch({
            type: actionTypes.STAGE_2_TIME_CHANGE,
            value
        });

        const { errors } = getState().editParsedBwic;
        if (errors?.stage1EndDateUtc || errors.stage2EndDateUtc) {
            dispatch(validate());
        }
    };
}
function changeBidsGoodForHours(value) {
    return (dispatch, getState) => {
        const { errors } = getState().editParsedBwic;

        dispatch({
            type: actionTypes.BIDS_GOOD_FOR_HOURS_CHANGE,
            value
        });

        if (errors?.bidsGoodFor) {
            dispatch(validate());
        }
    };
}
function changeBidsGoodForMinutes(value) {
    return (dispatch, getState) => {
        const { errors } = getState().editParsedBwic;

        dispatch({
            type: actionTypes.BIDS_GOOD_FOR_MINUTES_CHANGE,
            value
        });

        if (errors?.bidsGoodFor) {
            dispatch(validate());
        }
    };
}
function changeStage2Participants(value) {
    return {
        type: actionTypes.STAGE_2_PARTICIPANTS_CHANGE,
        value
    };
}
function validate() {
    return (dispatch, getState) => {
        const { date, emailReceivedDate, bidsGoodForHours, bidsGoodForMinutes, ...state } =
            getState().editParsedBwic;

        const bidsDue = moment.utc(setBwicDate(date, adjustTime(state.bidsDue)));
        const goodUntil = moment.utc(setBwicDate(date, adjustTime(state.goodUntil)));
        const stage1EndDateUtc = state.stage1EndDateUtc ? moment.utc(setBwicDate(date, state.stage1EndDateUtc)) : null;
        const stage2EndDateUtc = state.stage2EndDateUtc ? moment.utc(setBwicDate(date, state.stage2EndDateUtc)) : null;

        const errors = {};
        errors.date = validateDate(date);
        errors.time = validateTime(bidsDue, goodUntil);
        errors.emailReceivedDate = validateEmailReceivedDate(emailReceivedDate);
        errors.bidsGoodFor = validateBidsGoodFor(bidsGoodForHours, bidsGoodForMinutes);
        errors.stage1EndDateUtc = validateStage1End(stage1EndDateUtc, stage2EndDateUtc, bidsDue, goodUntil);
        errors.stage2EndDateUtc = validateStage2End(stage1EndDateUtc, stage2EndDateUtc, bidsDue, goodUntil);

        const isValid = !values(errors).some(x => !!x);

        dispatch({
            type: actionTypes.VALIDATE,
            validation: {
                errors,
                isValid
            }
        })
    };
}

function validateDate(date) {
    if (date === '') {
        return errorMessages.empty;
    } else if (typeof date === 'undefined') {
        return errorMessages.invalidValue;
    }

    const momentDate = moment(date)
    const weekday = momentDate.isoWeekday();

    if ([6, 7].some(d => d === weekday)) {
        return errorMessages.bwicDateShouldNotBeWeekend;
    }

    return undefined;
}

function validateEmailReceivedDate(date) {
    if (date === '') {
        return errorMessages.empty;
    } else if (typeof date === 'undefined') {
        return errorMessages.invalidValue;
    }

    const min = moment().subtract(1, 'year');
    const max = moment().add(1, 'year');

    if (min.isAfter(date, 'd') || max.isBefore(date, 'd')) {
        return errorMessages.dateRange(
            min.format(constants.dateFormat),
            max.format(constants.dateFormat)
        );
    }

    return undefined;
}

function validateTime(bidsDue, goodUntil) {
    if (bidsDue >= goodUntil) {
        return errorMessages.bidsDueShoulbBeLessThanGoodUntil;
    }
}

function validateBidsGoodFor(hours, minutes) {
    if ((!Number(hours) && !Number(minutes))) return undefined;

    const totalMinutes = numericUtils.numberOrDefault(hours) * 60 + numericUtils.numberOrDefault(minutes)

    const min = 30; // minutes
    const maxHours = 12;

    if (totalMinutes < min || totalMinutes > (maxHours * 60)) {
        return errorMessages.shouldBeBetween(`${30} minutes`, `${maxHours} hours`)
    }

    return undefined;
}

function validateStage1End(stage1End, stage2End, start, end) {
    if (!stage1End) return undefined;
    if (start && end && (stage1End.isBefore(start) || stage1End.isAfter(end))) {
        return "The time should be between start time and end time";
    }
    if (stage2End && stage1End.isSameOrAfter(stage2End)) {
        return "The value cannot be greater then the 2nd Stage Hard Cut Off (UTC)"
    }

    return undefined;
}

function validateStage2End(stage1End, stage2End, start, end) {
    if (!stage2End) return undefined;
    if (start && end && (stage2End.isBefore(start) || stage2End.isAfter(end))) {
        return "The time should be between start time and end time";
    }
    if (stage1End && stage2End.isSameOrBefore(stage1End)) {
        return "The value cannot be less then the 1st Stage Hard Cut Off (UTC)"
    }

    return undefined;
}

function adjustTime(utcTime) {
    const time = dateTimeUtils.timestampFromUtcString(utcTime);

    if (time === '12:00 AM') {
        return dateTimeUtils.timestampToUtcString('11:59:59 PM');
    }

    return utcTime;
}

export function setBwicDate(bwicDate, time) {
    if (!time) return undefined;

    return (bwicDate ? moment.utc(bwicDate) : moment.utc())
        .set('hour', moment.utc(time).hours())
        .set('minute', moment.utc(time).minute());
}

function showManageParsedBwics() {
    return () => {
        history.push(routes.manageParsedBwics);
    };
}

function save() {
    return (dispatch, getState) => {
        dispatch(validate());
        dispatch(gridActions.validate());

        const { isValid } = getState().editParsedBwic;
        const isPositionsValid = getState().grid.isValid;

        if (isValid && isPositionsValid) {
            dispatch(setSaveProgressState(true));
            doSave(getState())
                .then(() => {
                    dispatch(saved());
                })
                .catch(e => {
                    if (e.status === 409) {
                        dispatch(
                            notificationActions.notificationAddErrorMessageModal(
                                errorMessages.refreshPage,
                                errorMessages.cantSavePxTalk409,
                                true
                            )
                        );
                    } else {
                        dispatch(errorActions.error(e, errorMessages.unexpectedError))
                    }
                })
                .finally(() => dispatch(setSaveProgressState(false)));
        }
    };
}

function setSaveProgressState(isSaving) {
    return {
        type: actionTypes.SAVING_STATE,
        isSaving
    };
}

function saveOnExit(pathname, beforeNavigateCallback) {
    return async (dispatch, getState) => {
        dispatch(validate());
        dispatch(gridActions.validate());

        const { isValid } = getState().editParsedBwic;
        const isPositionsValid = getState().grid.isValid;

        if (isValid && isPositionsValid) {
            dispatch(setSaveProgressState(true));
            try {
                await doSave(getState());
                history.push(pathname);
            } catch (e) {
                beforeNavigateCallback(false);
                dispatch(errorActions.unexpectedError(e));
            } finally {
                dispatch(setSaveProgressState(false));
            }
        } else if (beforeNavigateCallback) {
            beforeNavigateCallback(false);
        }
    };
}

function saveAndPublish() {
    return async (dispatch, getState) => {
        dispatch(validate());
        dispatch(gridActions.validate());

        const { isValid } = getState().editParsedBwic;
        const isPositionsValid = getState().grid.isValid;

        if (isValid && isPositionsValid) {
            dispatch(setSaveProgressState(true));
            try {
                const referenceName = await doSave(getState());
                await parsedBwicsService.publish(referenceName);
                dispatch(saved());
            } catch (e) {
                dispatch(errorActions.unexpectedError(e));
            } finally {
                dispatch(setSaveProgressState(false));
            }
        }
    };
}

function saved() {
    return (dispatch, getState) => {
        getState().grid.dataItems
            .filter(s => !s.draft && s.pxTalks && s.id)
            .forEach(s => {
                dispatch(priceTalksActions.replacePriceTalks(s.id, s.pxTalks))
            });
        dispatch({ type: actionTypes.BWIC_SAVED })
    }
}

function doSave(state) {
    const positions = state.grid.dataItems.filter(s => !s.draft);
    const { bwic, date, bidsDue, goodUntil, timeZone, daysToSettle, rulesText, emailReceivedDate,
        reserveLevelsApply, canTradeEarly, canBidEarly, partialBidsAccepted, bidsGoodForHours, bidsGoodForMinutes,
        bwicProcessType, hardCutOff, stage1EndDateUtc, stage2EndDateUtc, improverCount } = state.editParsedBwic;

    const payload = {
        referenceName: bwic.referenceName,
        date: moment(date).startOf('day').utcOffset(0, true).toISOString(),
        bidsDue: moment.utc(bidsDue).utcOffset(0, true).toISOString(),
        goodUntil: moment.utc(goodUntil).utcOffset(0, true).toISOString(),
        timeZone,
        daysToSettle,
        emailReceivedDate,
        rulesText: rulesText == null ? rulesText.trim() : rulesText,
        reserveLevelsApply,
        canTradeEarly,
        canBidEarly,
        partialBidsAccepted,
        bidsGoodFor: bidsGoodForHours || bidsGoodForMinutes
            ? dateTimeUtils.timeSpan(
                numericUtils.numberOrDefault(bidsGoodForHours),
                numericUtils.numberOrDefault(bidsGoodForMinutes),
                0)
            : undefined,
        processType: bwicProcessType,
        hardCutOff,
        stage1EndDateUtc: setBwicDate(date, stage1EndDateUtc)?.toISOString(),
        stage2EndDateUtc: setBwicDate(date, stage2EndDateUtc)?.toISOString(),
        improverCount,
        positions
    }

    return parsedBwicsService.save(payload);
}

function cancelDialog(visible) {
    return {
        type: actionTypes.CANCEL_DIALOG,
        cancelDialog: { visible }
    };
}

function cancelBwic(referenceName) {
    return dispatch => {
        dispatch(cancelDialog(false));

        parsedBwicsService
            .cancel(referenceName)
            .then(
                () => history.push(routes.manageParsedBwics),
                e => dispatch(errorActions.unexpectedError())
            );
    };
}


