import {
    manageParsedBwicsActions as actionTypes,
    bwicDateFilterOptions,
    errorMessages,
    routes
} from "../constants";
import { parsedBwicsService, pxTalkService, colorService } from "../services";
import {
    changePxTalkActions,
    changeColorActions,
    errorActions,
    editParsedBwicActions as editActions,
    searchSecuritiesActions,
    notificationActions
} from ".";
import { getSearchDateRange, getDateRangeOption } from '../utils';
import { identifiers, dateFrom, dateTo, statuses, published } from '../utils/filtering/serializers/serializer.definitions';
import { queryStringSerializer } from '../utils/filtering';
import { priceTalksActions } from './price-talks.actions';
import { history } from "../history";
import { textRulesPanelActions } from "./text-rules-panel.actions";

export const manageParsedBwicsActions = {
    init,
    reset,
    search,
    refreshLastUpdatedDate,
    resetFilters,
    filterDateChange,
    filterCustomDateChange,
    filterStatusChange,
    filterPublishedChange,
    setBwicCollapsedState,
    setCollapseAll,
    securitySelectionChange,
    publishDialog,
    unpublishDialog,
    deleteBwicDialog,
    publish,
    unpublish,
    editPxTalk,
    savePxTalk,
    editColor,
    saveColor,
    deleteColor,
    showNewBwic,
    deleteBwic,
    changeAmrFlag,
    loadNextPage
}

function init(filterQueryString) {
    return async (dispatch) => {
        dispatch(loading(true));
        dispatch(searchSecuritiesActions.reset());
        try {
            const queryString = filterQueryString && filterQueryString.trim();

            if (queryString) {
                dispatch(resetFilters(false));
                dispatch(loadFilterFromQueryString(queryString));
            }

            dispatch(search());
        } catch (e) {
            dispatch(errorActions.criticalError(e));
        }
        finally {
            dispatch(loading(false));
        }
    }
}

function loadFilterFromQueryString(queryString) {
    return dispatch => {
        let from, to;

        const serializers = [
            identifiers(handleIdentifiersDeserialized),
            dateFrom(date => from = date),
            dateTo(date => to = date),
            statuses(handleStatusDeserialized),
            published(published => dispatch(filterPublishedChange(published)))
        ];

        queryStringSerializer.deserialize(queryString, serializers);

        dispatch(initFilterDateRange(from, to));

        function handleIdentifiersDeserialized(identifiers) {
            identifiers.forEach(i => dispatch(searchSecuritiesActions.addSearchItem(i)));
        }

        function handleStatusDeserialized(statuses) {
            const statusKeys = statuses.map(s => Number(s))
            dispatch(filterStatusChange(...statusKeys));
        }
    };
}

function initFilterDateRange(dateFrom, dateTo) {
    return dispatch => {
        const { option, customRange } = getDateRangeOption(dateFrom, dateTo);

        dispatch(filterDateChange(option));
        if (option.key === bwicDateFilterOptions.custom.key) {
            dispatch(filterCustomDateChange(customRange));
        }
    };
}


function reset(hardReset = false) {
    return (dispatch, getState) => {
        const { lastAppliedSearchTerm } = getState().searchSecurities;

        dispatch(searchSecuritiesActions.reset());
        dispatch(errorActions.resetError());
        dispatch({
            type: actionTypes.RESET,
            searchTerm: lastAppliedSearchTerm,
            hardReset
        });
    };
}

function resetFilters(shouldSearch = true) {
    return dispatch => {
        dispatch({ type: actionTypes.RESET_FILTERS });
        if (shouldSearch) {
            dispatch(search());
        }
    };
}

function refreshLastUpdatedDate() {
    return { type: actionTypes.REFRESH_LAST_UPDATED_DATE }
}

function loading(isLoading) {
    return {
        type: actionTypes.LOADING,
        isLoading
    };
}

function search() {
    return (dispatch, getState) => {
        const firstPageNumber = 1;
        const { isNextPageRequesting } = getState().manageParsedBwics;
        if (!isNextPageRequesting) {
            dispatch(resetPaging());
            dispatch(searchInProgress());
            dispatch(textRulesPanelActions.hide());
        }

        const state = getState();
        const searchCriteria = getSearchCriteria(state);
        const { currentPageNumber, pageSize } = state.manageParsedBwics;

        const nextPageNumber = isNextPageRequesting ? currentPageNumber + 1 : firstPageNumber;
        parsedBwicsService
            .search(searchCriteria, nextPageNumber, pageSize)
            .then(success)
            .catch(failure)
            .finally(() => dispatch(nextPageRequesting(false)));

        function success({ totalRecordNumber, result }) {
            dispatch(redirectWithFilterArguments(searchCriteria));
            dispatch({
                type: actionTypes.SEARCH_COMPLETE,
                bwics: result,
                totalRecordNumber,
                isNextPageRequesting,
                nextPageNumber
            });
        }

        function failure(e) {
            dispatch(resetFilters(false));
            dispatch(errorActions.criticalError(e));
        }
    }
}

function redirectWithFilterArguments(filter) {
    return () => {
        const serializers = [identifiers(), statuses(), dateFrom(), dateTo(), published()];
        const queryString = queryStringSerializer.serialize(filter, serializers);

        if (queryString) {
            history.replace(history.location.pathname + '?' + queryString);
        }
    }
}

function getPublishedOption(options) {
    if (options[0] && options[0].selected && !options[1].selected) return true;
    if (options[0] && options[1].selected && !options[0].selected) return false;
    return null;
}

function getSearchCriteria({ manageParsedBwics, searchSecurities }) {
    const { isNextPageRequesting, filter, lastAppliedFilter } = manageParsedBwics;
    const { searchTermItems } = searchSecurities;
    const theFilter = isNextPageRequesting ? lastAppliedFilter : filter;

    return {
        isinCusipsAndTickers: searchTermItems,
        statuses: theFilter.statuses.filter(s => s.selected).map(s => s.value),
        published: getPublishedOption(theFilter.stateOptions),
        ...getSearchDateRange(theFilter)
    };
}

function searchInProgress() {
    return { type: actionTypes.SEARCH_PROGRESS };
}

function filterDateChange(option) {
    return (dispatch, getState) => {
        const { filter } = getState().manageParsedBwics;

        dispatch({
            type: actionTypes.FILTER_DATE_CHANGE,
            option
        });

        if (option.key !== bwicDateFilterOptions.custom.key &&
            filter.customDateRange &&
            filter.customDateRange.from) {
            dispatch(filterCustomDateChange({}));
        }
    }
}

function filterCustomDateChange(dateRange) {
    return {
        type: actionTypes.FILTER_CUSTOM_DATE_CHANGE,
        dateRange
    };
}

function filterStatusChange(...statuses) {
    return dispatch => {
        dispatch({
            type: actionTypes.FILTER_STATUS_SELECTION_CHANGE,
            statuses
        });
    };
}

function filterPublishedChange(published) {
    return dispatch => {
        dispatch({
            type: actionTypes.FILTER_PUBLISHED_STATE_SELECTION_CHANGE,
            published
        });
    }
}

function setBwicCollapsedState(referenceName, collapsed) {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.SET_BWIC_COLLAPSED_STATE,
            bwic: { referenceName, collapsed }
        });

        const { bwics, collapseAll } = getState().manageParsedBwics;
        const collapsedCount = bwics.filter(b => b.collapsed).length;
        if (collapsedCount === bwics.length && !collapseAll) {
            dispatch(setCollapseAll(true));
        } else if (collapsedCount === 0 && collapseAll) {
            dispatch(setCollapseAll(false));
        }
    };
}

function setCollapseAll(collapseAll) {
    return {
        type: actionTypes.SET_COLLAPSE_ALL,
        collapseAll
    };
}

function securitySelectionChange(bwicReferenceName, positionId, selected) {
    return {
        type: actionTypes.POSITION_SELECTION_CHANGE,
        selection: { bwicReferenceName, positionId, selected }
    };

}

function publishDialog(visible, bwic) {
    return {
        type: actionTypes.PUBLISH_DIALOG,
        dialog: visible ? { visible, bwic } : {}
    };
}

function unpublishDialog(visible, bwic) {
    return {
        type: actionTypes.UNPUBLISH_DIALOG,
        dialog: visible ? { visible, bwic } : {}
    };
}

function publish(bwic) {
    return (dispatch, getState) => {
        dispatch(publishDialog(false));
        parsedBwicsService
            .publish(bwic.referenceName)
            .then(
                success,
                e => dispatch(errorActions.error(e, errorMessages.unexpectedError))
            );

        function success() {
            const bwics = getState().manageParsedBwics.bwics.map(b =>
                b.referenceName === bwic.referenceName
                    ? { ...b, onReview: false }
                    : b);
            dispatch(updateBwicList(bwics));
        }
    }
}

function unpublish(bwic) {
    return (dispatch, getState) => {
        dispatch(unpublishDialog(false));
        parsedBwicsService
            .unpublish(bwic.referenceName)
            .then(
                success,
                e => dispatch(errorActions.error(e, errorMessages.unexpectedError))
            );

        function success() {
            const bwics = getState().manageParsedBwics.bwics.map(b =>
                b.referenceName === bwic.referenceName
                    ? { ...b, onReview: true }
                    : b);
            dispatch(updateBwicList(bwics));
        }
    }
}

function updateBwicList(bwics) {
    return { type: actionTypes.UPDATE_BWIC_LIST, payload: { bwics } }
}

function editPxTalk(visible, bwicReferenceName, position) {
    return (dispatch, getState) => {
        if (visible) {
            const preloadedPxTalks = getState().entities.priceTalks.priceTalks[position.id]?.items;
            dispatch({
                type: actionTypes.EDIT_PXTALK,
                editPxTalk: { bwicReferenceName, positionId: position.id, priceTalkLock: position.priceTalkLock }
            });
            dispatch(changePxTalkActions.init(bwicReferenceName, position.id, position.ticker, preloadedPxTalks || position.pxTalks));
        } else {
            dispatch({
                type: actionTypes.EDIT_PXTALK,
                editPxTalk: {}
            });
        }
    };
}

function savePxTalk() {
    return (dispatch, getState) => {
        const state = getState();
        const { pxTalks, deletedPriceTalks } = state.changePxTalk;
        const { bwicReferenceName, positionId, priceTalkLock } = state.manageParsedBwics.editPxTalk;

        if (pxTalks && pxTalks.every(p => !p.error)) {
            dispatch(changePxTalkActions.reset());
            dispatch(editPxTalk(false));
            pxTalkService
                .save(bwicReferenceName, positionId, pxTalks, priceTalkLock, deletedPriceTalks)
                .then(newPriceTalkLock => {
                    dispatch({
                        type: actionTypes.UPDATE_PXTALK,
                        update: { bwicReferenceName, positionId, pxTalks, priceTalkLock: newPriceTalkLock }
                    });
                    dispatch(priceTalksActions.replacePriceTalks(positionId, pxTalks));
                })
                .catch(e => {
                    if (e.status === 409) {
                        dispatch(
                            notificationActions.notificationAddErrorMessageModal(
                                errorMessages.refreshPage,
                                errorMessages.cantSavePxTalk409,
                                true
                            )
                        );
                    } else {
                        dispatch(errorActions.unexpectedError(e));
                    }
                })
        }
    };
}

function editColor(visible, bwicReferenceName, position) {
    return dispatch => {
        if (visible) {
            dispatch({
                type: actionTypes.EDIT_COLOR,
                editColor: { bwicReferenceName, positionId: position.id }
            });
            dispatch(changeColorActions.init(position.ticker, position.color));
        } else {
            dispatch({
                type: actionTypes.EDIT_COLOR,
                editColor: {}
            });
        }
    };
}

function saveColor() {
    return (dispatch, getState) => {
        const state = getState();
        const { color, errors } = state.changeColor;
        const { bwicReferenceName, positionId } = state.manageParsedBwics.editColor;

        if (color && errors.isValid) {
            dispatch(changeColorActions.reset());
            dispatch(editColor(false));

            colorService
                .save(bwicReferenceName, positionId, color)
                .then(
                    () => dispatch({
                        type: actionTypes.UPDATE_COLOR,
                        update: { bwicReferenceName, positionId, color: { ...color, isNew: false } }
                    }),
                    e => dispatch(errorActions.unexpectedError(e))
                );
        }
    };
}

function deleteColor() {
    return (dispatch, getState) => {
        const { bwicReferenceName, positionId } = getState().manageParsedBwics.editColor;

        dispatch(changeColorActions.reset());
        dispatch(editColor(false));

        colorService
            .deleteColor(bwicReferenceName, positionId)
            .then(
                () => dispatch({
                    type: actionTypes.UPDATE_COLOR,
                    update: { bwicReferenceName, positionId, undefined }
                }),
                e => dispatch(errorActions.unexpectedError(e))
            );
    };
}

function showNewBwic() {
    return (dispatch, getState) => {
        const { bwics } = getState().manageParsedBwics;
        const securities = [];

        for (let i = 0; i < bwics.length; i++) {
            if (bwics[i].positions) {
                for (let j = 0; j < bwics[i].positions.length; j++) {
                    if (bwics[i].positions[j].selected) {
                        securities.push({
                            ...bwics[i].positions[j],
                            pxTalks: [],
                            color: null
                        });
                    }
                }
            }
        }

        dispatch(editActions.storeOriginalBwic({ securities }));
        history.push(routes.newParsedBWIC);
    };
}

function deleteBwicDialog(visible, referenceName) {
    return {
        type: actionTypes.DELETE_BWIC_DIALOG,
        dialog: { visible, referenceName }
    };
}

function deleteBwic(referenceName) {
    return (dispatch, getState) => {
        dispatch(deleteBwicDialog(false));

        parsedBwicsService
            .deleteBwic(referenceName)
            .then(success)
            .catch(e => dispatch(errorActions.error(e, errorMessages.unexpectedError)));

        function success() {
            const bwics = getState().manageParsedBwics.bwics
                .filter(b => b.referenceName !== referenceName);

            dispatch(updateBwicList(bwics));
        }
    };
}

function loadNextPage() {
    return (dispatch, getState) => {
        const { searchInProgress, isNextPageRequesting } = getState().manageParsedBwics;

        if (!searchInProgress && !isNextPageRequesting) {
            dispatch(nextPageRequesting(true));
            dispatch(search());
        }
    }
}

function nextPageRequesting(isRequesting) {
    return {
        type: actionTypes.MANAGE_PARSED_BWICS_NEXT_PAGE_REQUESTING,
        isRequesting
    }
}

function resetPaging() {
    return {
        type: actionTypes.MANAGE_PARSED_BWICS_RESET_PAGING
    };
}

function setRequestStatusIsAMR(referenceName, isRequesting) {
    return { type: actionTypes.MANAGE_PARSED_BWICS_SET_REQUEST_STATUS_IS_AMR, payload: { referenceName, isRequesting } }
}

function changeAmrFlag(referenceName, status) {
    return async (dispatch) => {
        dispatch(setRequestStatusIsAMR(referenceName, true));
        try {
            await parsedBwicsService.changeAmrFlag(referenceName, status);
            dispatch({ type: actionTypes.MANAGE_PARSED_BWICS_SET_IS_AMR_FLAG, payload: { referenceName, status } });
        } catch (e) {
            dispatch(errorActions.unexpectedError(e));
        } finally {
            dispatch(setRequestStatusIsAMR(referenceName, false));
        }
    }
}

