import { createAction } from 'typesafe-actions';

export const dashboardInit = createAction('dashboard/INIT');
export const dashboardInitSuccess = createAction('dashboard/INIT_SUCCESS');

export const dashboardInitFailure = createAction(
    'dashboard/INIT_FAILURE',
    resolve => (error) => resolve({ error })
);

export const dashboardFetchBwics = createAction(
    'dashboard/FETCH_BWICS',
    resolve => (requestState, data) => resolve({ requestState, data })
);

export const dashboardFetchPortfolioExist = createAction(
    'dashboard/FETCH_PORTFOLIO_EXIST',
    resolve => (requestState, data) => resolve({ requestState, data })
);

export const dashboardInitPortfolioSecurities = createAction(
    'dashboard/INIT_PORTFOLIO_SECURITIES',
);

export const dashboardFetchPortfolioSecurities = createAction(
    'dashboard/FETCH_PORTFOLIO_SECURITIES',
    resolve => (requestState, data) => resolve({ requestState, data })
);

export const dashboardFetchColorRates = createAction(
    'dashboard/FETCH_COLOR_RATES',
    resolve => (requestState, data) => resolve({ requestState, data })
);

export const dashboardFetchDntRates = createAction(
    'dashboard/FETCH_DNT_RATES',
    resolve => (requestState, data) => resolve({ requestState, data })
);

export const dashboardFetchSameDayPeriods = createAction(
    'dashboard/FETCH_SAME_DAYS_PERIODS',
    resolve => (requestState, data) => resolve({ requestState, data })
);

export const dashboardInitTradingStats = createAction(
    'dashboard/INIT_TRADING_STATS',
);

export const dashboardFetchTradingStats = createAction(
    'dashboard/FETCH_TRADING_STATS',
    resolve => (requestState, data) => resolve({ requestState, data })
);

export const dashboardInitPriceLevel = createAction(
    'dashboard/INIT_PRICE_LEVEL',
);

export const dashboardFetchPriceLevel = createAction(
    'dashboard/FETCH_PRICE_LEVEL',
    resolve => (requestState, data) => resolve({ requestState, data })
);

export const dashboardFetchBidRequestsAsSeller = createAction(
    'dashboard/FETCH_BID_REQUESTS_AS_SELLER',
    resolve => (requestState, data) => resolve({ requestState, data })
);

export const dashboardFetchDtcSellingBuying = createAction(
    'dashboard/FETCH_DTC_SELLING_BUYING',
    resolve => (requestState, data) => resolve({ requestState, data })
);

export const dashboardFetchAAAPriceLevel = createAction(
    'dashboard/FETCH_AAA_PRICE_LEVEL',
    resolve => (requestState, data) => resolve({ requestState, data })
);

export const dashboardFetchMostTradedTickers = createAction(
    'dashboard/FETCH_MOST_TRADED_TICKERS',
    resolve => (requestState, data) => resolve({ requestState, data })
);

export const dashboardFetchBiggestMovers = createAction(
    'dashboard/FETCH_BIGGEST_MOVERS',
    resolve => (requestState, data) => resolve({ requestState, data })
);

export const dashboardFetchDtcIgHy = createAction(
    'dashboard/FETCH_DTC_INVESTMENT_GRADE',
    resolve => (requestState, data) => resolve({ requestState, data })
);

export const dashboardFetchPxTalkDeviation = createAction(
    'dashboard/FETCH_PX_TALK_DEVIATION',
    resolve => (requestState, data) => resolve({ requestState, data })
);

export const dashboardFetchBwicPercentOfTrace = createAction(
    'dashboard/FETCH_BWIC_PERCENT_OF_DEVIATION',
    resolve => (requestState, data) => resolve({ requestState, data })
);

export const dashboardFetchKWatchNews = createAction(
    'dashboard/FETCH_K_WATCH_NEWS',
    resolve => (requestState, data) => resolve({ requestState, data })
);

export const dashboardFetchTopIssuers = createAction(
    'dashboard/FETCH_BWIC_TOP_ISSUERS',
    resolve => (types, ccy, year, collateralTypes) => resolve({ types, ccy, year, collateralTypes })
);

export const dashboardFetchTopIssuersResult = createAction(
    'dashboard/FETCH_BWIC_TOP_ISSUERS_RESULT',
    resolve => (requestState, data) => resolve({ requestState, data })
);

export const dashboardExportTopIssuers = createAction(
    'dashboard/EXPORT_TOP_ISSUERS',
    resolve => (types, ccy, year, collateralTypes, sortingParams) => resolve({ types, ccy, year, collateralTypes, sortingParams })
);

export const dashboardSetRequestStatusExportTopIssuers = createAction(
    'dashboard/SET_REQUEST_STATUS_EXPORT_TOP_ISSUERS',
    resolve => (requestState) => resolve({ requestState })
);

export const dashboardFetchTopArrangers = createAction(
    'dashboard/FETCH_BWIC_TOP_ARRANGERS',
    resolve => (types, ccy, year, collat) => resolve({ types, ccy, year, collat })
);

export const dashboardFetchTopArrangersResult = createAction(
    'dashboard/FETCH_BWIC_TOP_ARRANGERS_RESULT',
    resolve => (requestState, data) => resolve({ requestState, data })
);

export const dashboardExportTopArrangers = createAction(
    'dashboard/EXPORT_TOP_ARRANGERS',
    resolve => (types, ccy, year, collateralTypes, sortingParams) => resolve({ types, ccy, year, collateralTypes, sortingParams })
);

export const dashboardSetRequestStatusExportTopArrangers = createAction(
    'dashboard/SET_REQUEST_STATUS_EXPORT_TOP_ARRANGERS',
    resolve => (requestState) => resolve({ requestState })
);

export const dashboardFetchTopTrustees = createAction(
    'dashboard/FETCH_BWIC_TOP_TRUSTEES',
    resolve => (types, ccy, year, collateralTypes) => resolve({ types, ccy, year, collateralTypes })
);

export const dashboardFetchTopTrusteesResult = createAction(
    'dashboard/FETCH_BWIC_TOP_TRUSTEES_RESULT',
    resolve => (requestState, data) => resolve({ requestState, data })
);

export const dashboardExportTopTrustees = createAction(
    'dashboard/EXPORT_TOP_TRUSTEES',
    resolve => (types, ccy, year, collateralTypes, sortingParams) => resolve({ types, ccy, year, collateralTypes, sortingParams })
);

export const dashboardSetRequestStatusExportTopTrustees = createAction(
    'dashboard/SET_REQUEST_STATUS_EXPORT_TOP_TRUSTEES',
    resolve => (requestState) => resolve({ requestState })
);

export const dashboardFetchTopStaticDeals = createAction(
    'dashboard/FETCH_BWIC_TOP_STATIC_DEALS',
    resolve => (year) => resolve({ year })
);

export const dashboardFetchTopStaticDealsResult = createAction(
    'dashboard/FETCH_BWIC_TOP_STATIC_DEALS_RESULT',
    resolve => (requestState, data) => resolve({ requestState, data })
);

export const dashboardExportTopStaticDeals = createAction(
    'dashboard/EXPORT_BWIC_TOP_STATIC_DEALS',
    resolve => (year, orderByTransaction) => resolve({ year, orderByTransaction })
);

export const dashboardSetRequestStatusExportTopStaticDeals = createAction(
    'dashboard/SET_REQUEST_STATUS_EXPORT_BWIC_TOP_STATIC_DEALS',
    resolve => (requestState) => resolve({ requestState })
);

export const dashboardFetchTopDebutDeals = createAction(
    'dashboard/FETCH_BWIC_TOP_DEBUT_DEALS',
    resolve => (year) => resolve({ year })
);

export const dashboardFetchTopDebutDealsResult = createAction(
    'dashboard/FETCH_BWIC_TOP_DEBUT_DEALS_RESULT',
    resolve => (requestState, data) => resolve({ requestState, data })
);

export const dashboardExportTopDebutDeals = createAction(
    'dashboard/EXPORT_BWIC_TOP_DEBUT_DEALS',
    resolve => (year, orderByTransaction) => resolve({ year, orderByTransaction })
);

export const dashboardSetRequestStatusExportTopDebutDeals = createAction(
    'dashboard/SET_REQUEST_STATUS_EXPORT_BWIC_TOP_DEBUT_DEALS',
    resolve => (requestState) => resolve({ requestState })
);

export const dashboardFetchKIndicator = createAction(
    'dashboard/FETCH_K_INDICATOR',
    resolve => (requestState, data) => resolve({ requestState, data })
);

export const dashboardSetFilter = createAction(
    'dashboard/SET_FILTER',
    resolve => (filter) => resolve({ filter })
);

export const dashboardSetNotAppliedFilter = createAction(
    'dashboard/SET_NOT_APPLIED_FILTER',
    resolve => (filter) => resolve({ filter })
);

export const dashboardClearFilter = createAction(
    'dashboard/CLEAR_FILTER'
);

export const dashboardSetFilterActive = createAction(
    'dashboard/SET_FILTER_ACTIVE',
    resolve => (active) => resolve({ active })
);

export const dashboardResetNotAppliedFilter = createAction('dashboard/RESET_NOT_APPLIED_FILTER');

export const dashboardReset = createAction('dashboard/RESET');
export const dashboardOnSelectedSecurity = createAction('dashboard/ON_SELECTED_SECURITY');

export const dashboardSetSearchTermItem = createAction(
    'dashboard/SET_SEARCH_TERM_ITEM',
    resolve => (text) => resolve({ text })
);

export const dashboardFetchSecurityHistoryRequest = createAction('dashboard/FETCH_SECURITY_HISTORY_REQUEST');

export const dashboardFetchSecurityHistorySuccess = createAction(
    'dashboard/FETCH_SECURITY_HISTORY_SUCCESS',
    resolve => (securityHistory) => resolve({ securityHistory })
);

export const dashboardFetchSecurityHistoryFailure = createAction('dashboard/FETCH_SECURITY_HISTORY_FAILURE');
export const dashboardExportBwiStatistic = createAction('dashboard/EXPORT_BWIC_STATISTIC');

export const dashboardExportTradingStats = createAction(
    'dashboard/EXPORT_TRADING_STATS',
    resolve => (currencies, ratings) => resolve({ currencies, ratings })
);

export const dashboardSetRequestStatusExportTradingStats = createAction(
    'dashboard/SET_REQUEST_STATUS_EXPORT_TRADING_STATS',
    resolve => (status) => resolve({ status })
);

export const dashboardPushUpdateTradingStats = createAction(
    'dashboard/PUSH_DATA_UPDATE_TRADING_STATS',
    resolve => (tradingStats) => resolve({ tradingStats })
);

export const dashboardPushUpdatePriceLevel = createAction(
    'dashboard/PUSH_UPDATE_PRICE_LEVEL',
    resolve => (priceLevel) => resolve({ priceLevel })
);

export const dashboardPushBiggestMovers = createAction(
    'dashboard/PUSH_UPDATE_BIGGEST_MOVERS',
    resolve => (biggestMovers) => resolve({ biggestMovers })
)

export const dashboardPushUpdateMostTradedTickers = createAction(
    'dashboard/PUSH_UPDATE_MOST_TRADED_TICKERS',
    resolve => (mostTradedTickers) => resolve({ mostTradedTickers })
);

export const dashboardInitIssueTransactionVolume = createAction(
    'dashboard/ISSUE_TRANSACTION_VOLUME_INIT',
);

export const dashboardIssueTransactionVolumeRequest = createAction(
    'dashboard/ISSUE_TRANSACTION_VOLUME_REQUEST',
    resolve => (filter) => resolve({ filter })
);

export const dashboardIssueTransactionVolumeResult = createAction(
    'dashboard/ISSUE_TRANSACTION_VOLUME_RESULT',
    resolve => (requestState, data) =>
        resolve({ requestState, data })
);

export const dashboardUsBslEsgNewIssueRequest = createAction(
    'dashboard/US_BSL_ESG_NEW_ISSUE_REQUEST',
    resolve => (grouping) => resolve({ grouping }),
);

export const dashboardUsBslEsgNewIssueResult = createAction(
    'dashboard/US_BSL_ESG_NEW_ISSUE_RESULT',
    resolve => (requestState, esgLanguageDeals, usBslNewIssue) =>
        resolve({ requestState, esgLanguageDeals, usBslNewIssue })
);

export const dashboardGetPortfolioInventorySecuritiesRequest = createAction(
    'dashboard/GET_PORTFOLIO_INVENTORY_SECURITIES_REQUEST',
    resolve => (pageNumber, itemsOnPage, sortField, sortOrder) => resolve({ pageNumber, itemsOnPage, sortField, sortOrder })
)

export const dashboardGetPortfolioInventorySecuritiesSuccess = createAction(
    'dashboard/GET_PORTFOLIO_INVENTORY_SECURITIES_SUCCESS',
    resolve => (securities, pageNumber, totalRecordNumber) => resolve({ securities, pageNumber, totalRecordNumber })
)

export const dashboardGetPortfolioInventorySecuritiesFailure = createAction(
    'dashboard/GET_PORTFOLIO_INVENTORY_SECURITIES_FAILURE'
)

export const dashboardSetPortfolioInventoryCurrentPage = createAction(
    'dashboard/SET_PORTFOLIO_INVENTORY_CURRENT_PAGE',
    resolve => (currentPage) => resolve({ currentPage })
)

export const dashboardPortfolioInventorySortParamsChanged = createAction(
    'dashboard/PORTFOLIO_INVENTORY_SORT_PARAMS_CHANGED',
    resolve => (sortField, sortOrder) => resolve({ sortField, sortOrder })
)

export const dashboardPortfolioInventoryReset = createAction('dashboard/PORTFOLIO_INVENTORY_RESET')

export const dashboardUsBslEuCompliantDealsRequest = createAction(
    'dashboard/US_BSL_ESG_EU_COMPLIANT_DEALS_REQUEST',
    resolve => (filter) => resolve(filter),
);

export const dashboardUsBslEuCompliantDealsResult = createAction(
    'dashboard/US_BSL_ESG_EU_COMPLIANT_DEALS_RESPONSE',
    resolve => (requestState, usBslEuCompliantDeals, usBslDeals) =>
        resolve({ requestState, usBslEuCompliantDeals, usBslDeals })
);

export const dashboardShortLongDatedDealsRequest = createAction(
    'dashboard/SHORT_LONG_DATED_DEALS_REQUEST',
    resolve => (filter, managerReferenceName, startDate) => resolve({ filter, managerReferenceName, startDate }),
);

export const dashboardShortLongDatedDealsResult = createAction(
    'dashboard/SHORT_LONG_DATED_DEALS_RESPONSE',
    resolve => (requestState, data) =>
        resolve({ requestState, data })
);

export const dashboardIssuanceSpreadRequest = createAction(
    'dashboard/ISSUANCE_SPREAD_REQUEST',
    resolve => (filter, managerReferenceName, startDate) => resolve({ filter, managerReferenceName, startDate }),
);

export const dashboardIssuanceSpreadResult = createAction(
    'dashboard/ISSUANCE_SPREAD_RESPONSE',
    resolve => (requestState, data) =>
        resolve({ requestState, data })
);

export const dashboarNewIssuedWeeklySpreadsRequest = createAction(
    'dashboard/NEW_ISSUE_WEEKLY_SPREADS_REQUEST'
);

export const dashboardNewIssueWeeklySpreadsResult = createAction(
    'dashboard/NEW_ISSUE_WEEKLY_SPREADS_RESPONSE',
    resolve => (requestState, data) =>
        resolve({ requestState, data })
);
