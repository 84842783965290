import { ActionType, createAction } from "typesafe-actions";
import { RequestState } from "../constants/request-state";
import { PxTalk } from "../types/bwic/PxTalk";

const request = createAction("entities/priceTalks/REQUEST",
    resolve => (bwicReferenceName: string, positionId: number) => resolve({ bwicReferenceName, positionId })
);
const requestResult = createAction("entities/priceTalks/REQUEST_RESULT",
    resolve => (positionId: number, requestState: RequestState, priceTalks: PxTalk[] = []) => resolve({ positionId, requestState, priceTalks })
);
const addPriceTalk = createAction("entities/priceTalks/ADD",
    resolve => (positionId: number, priceTalk: PxTalk) => resolve({ positionId, priceTalk })
);
const replacePriceTalks = createAction("entities/priceTalks/REPLACE",
    resolve => (positionId: number, priceTalks: PxTalk[]) => resolve({ positionId, priceTalks })
);
const resetFailed = createAction("entities/priceTalks/RESET_FAILED");
const reset = createAction("entities/priceTalks/RESET");

export const priceTalksActions = {
    request,
    requestResult,
    addPriceTalk,
    replacePriceTalks,
    resetFailed,
    reset
}

export type TPriceTalksActionType = ActionType<
    typeof request |
    typeof requestResult |
    typeof addPriceTalk |
    typeof replacePriceTalks |
    typeof resetFailed |
    typeof reset
>;
