import { dashboardService } from "../services";
import { user } from "../user";
import { roles } from "../constants";
import { dealersStatsActions as actionTypes } from '../constants/actionTypes/dealers-stats.actions'
import { errorActions } from "./error.actions";
import { Dispatch } from "redux";
import { saveAs } from "file-saver";
import { IDealersStats } from "../types/models/DealersStats";

interface GetDealersStatsService {
    exist: () => Promise<boolean>;
    fetch: () => Promise<{ data: IDealersStats[] }>;
    export: () => Promise<{ name: string, blob: Blob }>;
}

export const dealersStatsActions = {
    checkStatsExist,
    fetchStats,
    exportDealersStats,
    setExportDealerStatsRequestStatus,
};

function getDealersStatsService(): GetDealersStatsService {
    const isSeller: boolean = user.hasRoles(...roles.seller());
    return {
        exist: isSeller ? dashboardService.fetchDealersStatsExists : dashboardService.fetchSellersStatsExists,
        fetch: isSeller ? dashboardService.fetchDealersStats : dashboardService.fetchClientsStats,
        export: isSeller ? dashboardService.exportDealersStats : dashboardService.exportSellerStats,
    }
}

function checkStatsExist() {
    return async (dispatch: Dispatch<any>) => {
        try {
            dispatch(setRequestStateFetchDealerStatsExist(true));
            const statsExist = await getDealersStatsService().exist();
            dispatch(storeDealersStatsExist(statsExist));
        } catch (e) {
            dispatch(errorActions.unexpectedError(e));
        } finally {
            dispatch(setRequestStateFetchDealerStatsExist(false));
        }
    };
}

function setRequestStateFetchDealerStatsExist(status: boolean) {
    return {
        type: actionTypes.FETCH_STATS_EXIST_REQUEST_STATUS,
        payload: {status}
    };
}

function storeDealersStatsExist(existStatus: boolean) {
    return {
        type: actionTypes.STORE_STATS_EXIST_STATUS,
        payload: {existStatus}
    };
}


function fetchStats() {
    return async (dispatch: Dispatch<any>) => {
        try {
            dispatch(setFetchStatsRequestStatus(true));
            const fetchStats = await getDealersStatsService().fetch();

            if (fetchStats) {
                dispatch(storeStats(fetchStats.data))
            }
        } catch (e) {
            errorActions.unexpectedError(e)
        } finally {
            dispatch(setFetchStatsRequestStatus(false))
        }
    };
}


function storeStats(stats: IDealersStats[]) {
    return {
        type: actionTypes.STORE_STATS,
        payload: {stats}
    };
}

function setFetchStatsRequestStatus(status: boolean) {
    return {
        type: actionTypes.FETCH_STATS_REQUEST_STATUS,
        payload: {status}
    };
}

function exportDealersStats() {
    return async (dispatch: Dispatch<any>) => {
        try {
            const exportStats = await getDealersStatsService().export();

            if (exportStats) {
                dispatch(setExportDealerStatsRequestStatus(true));
                saveAs(exportStats.blob, exportStats.name);
            }

        } catch (e) {
            errorActions.unexpectedError(e)
        } finally {
            dispatch(setExportDealerStatsRequestStatus(false))
        }
    };
}

function setExportDealerStatsRequestStatus(status: boolean) {
    return {
        type: actionTypes.EXPORT_STATS_REQUEST_STATUS,
        payload: {status}
    };
}
