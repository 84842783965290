import { createAction } from 'typesafe-actions';
import { CompanySlim } from '../types/company/CompanySlim';
import { Company } from '../types/company/Company';

const show = createAction(
    'companyDetails/SET_VISIBLE',
    resolve => (company: CompanySlim, isBrokerDealer?: boolean, rowKey?: number) => resolve({ company, isBrokerDealer, rowKey })
);

const hide = createAction(
    'companyDetails/HIDE'
);

const fetchCompanyDetailsRequest = createAction(
    'companyDetails/FETCH_COMPANY_DETAILS_REQUEST',
    resolve => (companyId: number, companyReferenceName: string) => resolve({ companyId, companyReferenceName })
);

const fetchCompanyDetailsResponse = createAction(
    'companyDetails/FETCH_COMPANY_DETAILS_RESPONSE',
    resolve => (companyId: number) => resolve({ companyId })
);

const setCompanyDetails = createAction(
    'companyDetails/SET_COMPANY_DETAILS',
    resolve => (companyId: number, company: Company) => resolve({ companyId, company })
);

const reset = createAction('companyDetails/RESET');

export const companyDetailsActions = {
    show,
    hide,
    fetchCompanyDetailsRequest,
    fetchCompanyDetailsResponse,
    setCompanyDetails,
    reset
};
