import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { createCustomAction } from 'typesafe-actions';
import { biddingActions, notificationActions, sellerBiddingActions, tradeConfirmActions } from '.';
import { pushDataActions as actionTypes, roles } from '../constants';
import { logger } from '../logging/logger';
import { getSecuritiesWithLatestBids } from '../selectors';
import { BidRequest as BidAsDealerRequest } from '../types/bid-as-dealer/BidRequest';
import { SellerBuysideSearchResult } from '../types/bid-as-dealer/SellerBuysideSearchResult';
import { Bid } from '../types/bidding/Bid';
import { BidRequest, BidRequestType } from '../types/bidding/BidRequest';
import { Feedback } from '../types/bidding/Feedback';
import { BwicHistoryAction } from '../types/bwic-history/BwicHistoryAction';
import { Color } from '../types/bwic/Color';
import { PxTalk } from '../types/bwic/PxTalk';
import { BwicStatus } from '../types/enums/BwicStatus';
import { AutoFeedbackSettings } from '../types/models/AutoFeedbackSettings';
import { AppState } from '../types/state/AppState';
import { PushHistoryStateItemType } from '../types/state/PushHistoryState';
import { Trade } from '../types/trades/Trade';
import { user } from '../user';
import { biddingUtils, formatUtils, moneyUtils } from '../utils';
import { pushHistoryActions } from './entities/push-history.actions';
import { rejectedTradeActions } from './entities/rejected-trades.actions';
import { BwicHistoryActionType } from '../types/bwic-history/BwicHisoryActionType';
import { BidLevel } from '../types/bidding/BidLevel';
import { liveBiddingActions } from './entities/live-bidding.actions';
import { CanBidOnPosition } from '../types/live-bidding/LiveBiddingPosition';
import { CompanySlim } from '../types/company/CompanySlim';

type TDispatch = ThunkDispatch<any, void, AnyAction>;

interface PushData {
    bwicReferenceName: string;
    action: BwicHistoryAction;
}
export function dataPushReceived(data: any) {
    return (dispatch: TDispatch) => {
        switch (data.action.actionType) {
            case BwicHistoryActionType.NewBid:
            case BwicHistoryActionType.DirectBid:
                dispatch(handleNewBid(data));
                break;
            case BwicHistoryActionType.AxedFinalChanged:
                dispatch(handleAxedFinalChange(data));
                break;
            case BwicHistoryActionType.PriceTalk:
                dispatch(newPriceTalk(data));
                break;
            case BwicHistoryActionType.QuickFeedback:
            case BwicHistoryActionType.DirectFeedback:
                dispatch(handleQuickFeedback(data));
                break;
            case BwicHistoryActionType.TargetLevel:
                dispatch(targetLevel(data));
                break;
            case BwicHistoryActionType.TradedAway:
                dispatch(handleTradedAway(data));
                break;
            case BwicHistoryActionType.TradeSubmitted:
                dispatch(trade(data));
                break;
            case BwicHistoryActionType.TradeAffirmed:
                dispatch(tradeStatus(data));
                break;
            case BwicHistoryActionType.TradeRejected:
                dispatch(tradeStatus(data));
                break;
            case BwicHistoryActionType.ColorDistribution:
                dispatch(handleColorDistribution(data));
                break;
            case BwicHistoryActionType.ColorDistributionSend:
                dispatch(handleColorDistributionSend(data));
                break;
            case BwicHistoryActionType.Bidding:
                dispatch(handleBwicStatusChanged(data, BwicStatus.bidding));
                break;
            case BwicHistoryActionType.Finished:
                dispatch(handleBwicStatusChanged(data, BwicStatus.finished));
                break;
            case BwicHistoryActionType.Canceled:
                dispatch(handleBwicStatusChanged(data, BwicStatus.canceled));
                break;
            // TO DO: nothing is happen ON FE when stage 1 is started
            //case BwicHistoryActionType.OpenBiddingStage1Started:
            //    dispatch(stagedBiddingStatusChange(data));
            //    break;
            case BwicHistoryActionType.OpenBiddingStage1Ended:
                dispatch(handleStagedBiddingStartStage2(data));
                break;
            case BwicHistoryActionType.OpenBiddingStage2Ended:
                dispatch(handleStagedBiddingFinishStage2(data));
                break;
            case BwicHistoryActionType.OpenBiddingStage2Level:
                dispatch(handleLiveBiddingStage2Level(data));
                break;
            case BwicHistoryActionType.ModifyAutoFeedbackConfig:
                dispatch(handleAutoFeedbackSettingsChanged(data));
                break;
            case BwicHistoryActionType.BidAsDealerRequest:
                dispatch(handleBidAsDealerRequest(data));
                break;
            case BwicHistoryActionType.BuyerRejectedTrade:
                dispatch(rejectedTradeActions.pushBuyerRejectTrade(data.trade))
                break;
            case BwicHistoryActionType.BiddingCompleteReminder:
                dispatch(handleBwicCompleteReminder(data));
                break;
            default:
                return null;
        };
    };
}

const newBid = createCustomAction('push/bwic/NEW_BID', type =>
    (bwicReferenceName: string, positionId: number, historyAction: BwicHistoryAction, bid: Bid) =>
        ({ type, bwicReferenceName, positionId, historyAction, bid })
);
type TNewBidPushData = { bwicReferenceName: string; action: BwicHistoryAction; bid: Bid, bwicName: string; };

function handleNewBid({ bwicReferenceName, action, bid, bwicName }: TNewBidPushData) {
    logger.trace(`Push: New Bid: ${bid.value}`);

    return (dispatch: TDispatch, getState: () => AppState) => {
        dispatch(newBid(bwicReferenceName, action.positionId!, action, bid));
        dispatch(pushHistoryActions.add(
            { receivedDate: new Date(), positionId: action.positionId, bwicReferenceName },
            PushHistoryStateItemType.NewBid
        ))
        if (user.hasRoles(roles.SellerTrader) && action.positionId != null) {
            const { securities, tradingPositions } = getState().sellerBidding;
            const isPositionTrading = !!(tradingPositions && Object.keys(tradingPositions).length && tradingPositions[action.positionId]);

            if (isPositionTrading) {
                const position = securities.find(p => p.id === action.positionId);
                if (position) {
                    const [positionWithActualBids] = getSecuritiesWithLatestBids({ securities: [position] });
                    const [best] = biddingUtils.getBidsByLevel(positionWithActualBids.bids, BidLevel.Best);
                    if (best && +best.value <= +bid.value) {
                        dispatch(sellerBiddingActions.tradingPositionCompanyChange(action.positionId, biddingUtils.getBidCompanyIdentifier(bid)));
                        dispatch(sellerBiddingActions.tradeConfirm(false));
                        dispatch(notificationActions.warningModal(
                            'New Bid Notification',
                            `<div class="margin-b-8 text-medium">The Best level changed. Please review bids and re-submit the trade.</div> New bid from ${bid.company.name}.
                                     ${bwicName}
                                     ${position.ticker} ${moneyUtils.amountToMM(position.size, true)} @${formatUtils.formatBid(bid.value)}`
                        ));
                    }
                }
            }
        }
    };
}

const axedFinalChange = createCustomAction('push/bwic/AXED_FINAL_CHANGE', type => (
    bwicReferenceName: string,
    positionId: number,
    historyAction: BwicHistoryAction,
    modifiedDate: Date,
    bidId: number,
    axed: boolean,
    final: boolean,
    createdBy: string,
    company: CompanySlim) => ({
        type,
        bwicReferenceName,
        positionId,
        historyAction,
        modifiedDate,
        bidId,
        axed,
        final,
        createdBy,
        company
    }));
interface TAxedFinalChangePushData extends PushData {
    modifiedDate: Date;
    bidId: number;
    axed: boolean;
    final: boolean;
    createdBy: string;
    company: CompanySlim;
}
function handleAxedFinalChange({ bwicReferenceName, action, bidId, axed, final, modifiedDate, createdBy, company }: TAxedFinalChangePushData) {
    logger.trace(`Push: Axed Final Change for Bid ${bidId} axed=${axed} final=${final}`);
    return axedFinalChange(bwicReferenceName, action.positionId!, action, modifiedDate, bidId, axed, final, createdBy, company);
}

interface PriceTalkPushData extends PushData {
    priceTalk: PxTalk;
}
function newPriceTalk({ bwicReferenceName, action, priceTalk }: PriceTalkPushData) {
    logger.trace(`Push: New Price Talk: ${priceTalk.talk}`);

    return {
        type: actionTypes.PUSH_DATA_NEW_PX_TALK,
        bwicReferenceName,
        positionId: action.positionId,
        priceTalk,
        historyAction: action
    }
}

const quickFeedback = createCustomAction('push/bwic/QUICK_FEEDBACK', type =>
    (bwicReferenceName: string, feedback: Feedback, historyAction: BwicHistoryAction, positionId?: number) =>
        ({ type, bwicReferenceName, feedback, historyAction, positionId })
);
const bidRequest = createCustomAction('push/bwic/BID_REQUEST_FEEDBACK', type =>
    (bwicReferenceName: string, bidRequest: BidRequest, historyAction: BwicHistoryAction, positionId?: number) =>
        ({ type, bwicReferenceName, bidRequest, historyAction, positionId })
);
interface QuickFeedbackPushData extends PushData {
    feedback: Feedback;
}
function handleQuickFeedback({ bwicReferenceName, action, feedback }: QuickFeedbackPushData) {
    logger.trace(`Push: Quick Feedback: ${feedback.feedback}`);
    return (dispatch: TDispatch) => {
        dispatch(pushHistoryActions.add(
            { receivedDate: new Date(), positionId: action.positionId, bwicReferenceName },
            PushHistoryStateItemType.Feedback
        ))

        if (feedback.bidId) {
            return dispatch(quickFeedback(bwicReferenceName, feedback, action, action.positionId));
        }

        const request: BidRequest = {
            bidRequestType: BidRequestType.BidOrPass,
            created: new Date()
        }

        dispatch(bidRequest(bwicReferenceName, request, action, action.positionId));
    }
}
interface TradeLevelPushData extends PushData {
    targetLevel?: number;
}

function targetLevel({ bwicReferenceName, action, targetLevel }: TradeLevelPushData) {
    logger.trace(`Push: Target Level: ${targetLevel}`);

    return {
        type: actionTypes.PUSH_DATA_TARGET_LEVEL,
        bwicReferenceName,
        positionId: action.positionId,
        targetLevel
    };
}

const tradedAway = createCustomAction('push/bwic/TRADED_AWAY', type =>
    (bwicReferenceName: string, positionId: number, historyAction: BwicHistoryAction, isTradedAway: boolean) =>
        ({ type, bwicReferenceName, positionId, historyAction, isTradedAway })
);
interface TradedAwayPushData extends PushData {
    isTradedAway: boolean;
}
function handleTradedAway({ bwicReferenceName, action, isTradedAway }: TradedAwayPushData) {
    logger.trace(`Push: Traded away ${bwicReferenceName}: ${isTradedAway}`);
    return tradedAway(bwicReferenceName, action.positionId!, action, isTradedAway);
}

interface TradePushData extends PushData {
    trade: Trade;
}
function trade({ bwicReferenceName, action, trade }: TradePushData) {
    logger.trace('Push: Trade');
    return (dispatch: TDispatch) => {
        dispatch(tradeAction(bwicReferenceName, action.positionId!, action, trade));
        dispatch(checkAwaitingTrades());
    };
}

function checkAwaitingTrades() {
    return (dispatch: TDispatch) => {
        if (user.hasRoles(roles.BrokerDealerTrader, roles.SellerTrader)) {
            dispatch(tradeConfirmActions.checkAwaitingTrades());
        }
    };
}

interface TradeStatusPushData extends PushData {
    trade: Trade;
}
function tradeStatus({ bwicReferenceName, action, trade }: TradeStatusPushData) {
    logger.trace(`Push: Trade Status: ${trade.status}`);

    return (dispatch: TDispatch) => {
        dispatch(tradeAction(bwicReferenceName, action.positionId!, action, trade));

        if (user.hasRoles(roles.BrokerDealerTrader, roles.SellerTrader)) {
            dispatch(tradeConfirmActions.setAwaitingBwicTradeStatus(
                [action.positionId],
                trade.status,
                trade.rejectReason
            ));
        }
    };
}

const tradeAction = createCustomAction('push/bwic/TRADE', type =>
    (bwicReferenceName: string, positionId: number, historyAction: BwicHistoryAction, trade: Trade) =>
        ({ type, bwicReferenceName, historyAction, trade, positionId })
);

const colorDistribution = createCustomAction('push/bwic/COLOR_DISTRIBUTION', type =>
    (bwicReferenceName: string, historyAction: BwicHistoryAction, isColorDistribution: boolean) =>
        ({ type, bwicReferenceName, isColorDistribution, historyAction })
);
interface ColorDistributionPushData extends PushData {
    isColorDistribution: boolean;
}
function handleColorDistribution({ bwicReferenceName, action, isColorDistribution }: ColorDistributionPushData) {
    logger.trace(`Push: Color Distribution ${bwicReferenceName}: ${isColorDistribution}`);
    return colorDistribution(bwicReferenceName, action, isColorDistribution);
}

export type TPositionColor = { positionId: number; color: Color };

interface ColorDistributionSendPushData extends PushData {
    colors: TPositionColor[]
}

const publicColors = createCustomAction('push/bwic/PUBLIC_COLORS', type =>
    (bwicReferenceName: string, historyAction: BwicHistoryAction, colors: TPositionColor[]) =>
        ({ type, bwicReferenceName, colors, historyAction })
);

function handleColorDistributionSend({ bwicReferenceName, action, colors }: ColorDistributionSendPushData) {
    logger.trace('Push: Colors Sent');
    return publicColors(bwicReferenceName, action, colors);
}

const bwicStatusChanged = createCustomAction('push/bwic/BWIC_STATUS_CHANGE', type =>
    (bwicReferenceName: string, historyAction: BwicHistoryAction, bwicStatus: BwicStatus) =>
        ({ type, bwicReferenceName, historyAction, bwicStatus })
);

function handleBwicStatusChanged({ bwicReferenceName, action }: PushData, bwicStatus: BwicStatus) {
    logger.trace(`Push: BWIC Status Changed: ${bwicStatus}`);

    return (dispatch: TDispatch) => {
        dispatch(liveBiddingActions.reset(bwicReferenceName));
        dispatch(bwicStatusChanged(bwicReferenceName, action, bwicStatus));
        dispatch(pushHistoryActions.add(
            { receivedDate: new Date(), bwicReferenceName },
            PushHistoryStateItemType.BwicStatusChanged
        ));
        dispatch(biddingActions.bwicStatusChange(bwicReferenceName, bwicStatus));
        if (bwicStatus === BwicStatus.finished) {
            dispatch(checkAwaitingTrades());
        }
    };
}

interface StartStage2PushData extends PushData {
    bwicName: string;
    stage1EndDateUtc?: Date;
    canDealerBidOnPositions?: CanBidOnPosition[];
}

const stagedBiddingStartStage2 = createCustomAction('push/bwic/STAGED_BIDDING_START_STAGE2', type => (
    bwicReferenceName: string,
    historyAction: BwicHistoryAction,
    canDealerBidOnPositions?: CanBidOnPosition[],
    stage1EndDateUtc?: Date) =>
    ({ type, bwicReferenceName, historyAction, canDealerBidOnPositions, stage1EndDateUtc })
);
function handleStagedBiddingStartStage2({ bwicReferenceName, stage1EndDateUtc, canDealerBidOnPositions, action }: StartStage2PushData) {
    logger.trace('Push: Open Bidding Start Stage 2');
    return (dispatch: TDispatch) => {
        dispatch(liveBiddingActions.stage2Start(bwicReferenceName, canDealerBidOnPositions, stage1EndDateUtc));
        dispatch(stagedBiddingStartStage2(bwicReferenceName, action, canDealerBidOnPositions, stage1EndDateUtc));
        dispatch(pushHistoryActions.add(
            { receivedDate: new Date(), positionId: action.positionId, bwicReferenceName },
            PushHistoryStateItemType.BwicStage1Ended
        ));
    };
}

const stagedBiddingFinishStage2 = createCustomAction('push/bwic/STAGED_BIDDING_FINISH_STAGE2', type =>
    (bwicReferenceName: string, historyAction: BwicHistoryAction) =>
        ({ type, bwicReferenceName, historyAction })
);

function handleStagedBiddingFinishStage2({ bwicReferenceName, action }: PushData) {
    logger.trace(`Push: Finish Stage 2 ${bwicReferenceName}`);
    return (dispatch: TDispatch) => {
        dispatch(liveBiddingActions.reset(bwicReferenceName));
        dispatch(stagedBiddingFinishStage2(bwicReferenceName, action));
        dispatch(pushHistoryActions.add(
            { receivedDate: new Date(), positionId: action.positionId, bwicReferenceName },
            PushHistoryStateItemType.BwicStage2Ended
        ));
    };
}

interface LiveBiddingStage2LevelPushData extends PushData {
    level: number;
    tiedForBest: boolean;
}

const liveBiddingStage2Level = createCustomAction('push/bwic/LIVE_BIDDING_STAGE2_LEVEL', type =>
    (bwicReferenceName: string, positionId: number, level: number, tiedForBest: boolean, sentDateUtc: Date) =>
        ({ type, bwicReferenceName, positionId, level, tiedForBest, sentDateUtc })
);

function handleLiveBiddingStage2Level({ bwicReferenceName, level, tiedForBest, action }: LiveBiddingStage2LevelPushData) {
    logger.trace(`Push: Open Bidding Feedback: ${level}`);

    return (dispatch: TDispatch) => {
        dispatch(liveBiddingActions.stage2LevelChange(bwicReferenceName, action.positionId!, level, tiedForBest, action.sentDateUtc));
        dispatch(liveBiddingStage2Level(bwicReferenceName, action.positionId!, level, tiedForBest, action.sentDateUtc));
    }
}

interface AutoFeedbackSettingsChangedPushData extends PushData {
    autoFeedbackConfiguration: AutoFeedbackSettings;
}
function handleAutoFeedbackSettingsChanged({ bwicReferenceName, autoFeedbackConfiguration }: AutoFeedbackSettingsChangedPushData) {
    logger.trace('Push: Auto-Feedback config update');

    return {
        type: actionTypes.PUSH_DATA_AUTO_FEEDBACK_CONFIG,
        bwicReferenceName,
        settings: autoFeedbackConfiguration
    };
}

const bidAsDealerRequest = createCustomAction('push/bwic/BID_REQUEST',
    type => (bwicReferenceName: string, bidAsDealerRequest: BidAsDealerRequest | SellerBuysideSearchResult) =>
        ({ type, bwicReferenceName, bidAsDealerRequest })
);

interface TBidAsDealerRequestPushData {
    bwicReferenceName: string;
    bidAsDealerRequest?: BidAsDealerRequest;
    sellerBidAsDealerRequest?: SellerBuysideSearchResult;
}

function handleBidAsDealerRequest(data: TBidAsDealerRequestPushData) {
    logger.trace(`Push: Bid as dealer request.`);
    return (dispatch: TDispatch) => {
        const bidRequest = data.bidAsDealerRequest ?? data.sellerBidAsDealerRequest;
        if (bidRequest != null) {
            dispatch(bidAsDealerRequest(data.bwicReferenceName, bidRequest));
        }
    }
}

interface TBwicCompleteReminderData extends PushData {
    bwicName: string;
}
function handleBwicCompleteReminder({ bwicReferenceName, bwicName, action }: TBwicCompleteReminderData) {
    return pushHistoryActions.add(
        { receivedDate: new Date(), bwicReferenceName, payload: { bwicName, remainingMinutes: Number(action.payload) } },
        PushHistoryStateItemType.BiddingCompleteReminder
    );
}

export const pushBwicActions = {
    newBid,
    axedFinalChange,
    bidAsDealerRequest,
    quickFeedback,
    bidRequest,
    publicColors,
    colorDistribution,
    bwicStatusChanged,
    tradeAction,
    tradedAway,
    stagedBiddingStartStage2,
    stagedBiddingFinishStage2,
    liveBiddingStage2Level
}
