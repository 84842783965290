import { createAction } from "typesafe-actions";
import { DateFilterOption } from "../types/filters/DateFilterOption";
import { DateRange } from "../types/filters/DateRange";
import { PaginationResult } from "../types/PaginationResult";
import { SellerBuysideSearchResult } from "../types/bid-as-dealer/SellerBuysideSearchResult";
import { Rating } from "../types/enums/Rating";
import { RequestState } from '../constants/request-state';
import { EditBidRequestState, SellerBuysideFilterState } from "../types/state/SellerBuysideState";
import { BwicType } from "../types/enums/BwicType";
import { SettlementAgentAgreement } from '../types/bid-as-dealer/SettlementAgentAgreement';
import { BwicMonitorSortByOptions } from "../types/state/AllBwicsState";
import { SellerBuysideSearchResultSummary } from "../types/bid-as-dealer/SellerBuysideSearchResultSummary";

const init = createAction('sellerBuyside/INIT', resolve => () => resolve());
const initRequestStatus = createAction(
    'sellerBuyside/INIT_REQUEST_STATUS',
    resolve => (requestStatus: RequestState, bidAsDealerRequestExist: boolean = false) => resolve({ requestStatus, bidAsDealerRequestExist })
);
const reset = createAction('sellerBuyside/RESET', resolve => () => resolve());
const dateFilterChange = createAction(
    'sellerBuyside/DATE_FILTER_CHANGE',
    resolve => (dateOption: DateFilterOption) => resolve({ dateOption })
);
const customDateFilterChange = createAction(
    'sellerBuyside/CUSTOM_DATE_FILTER_CHANGE',
    resolve => (dateRange: DateRange) => resolve({ dateRange })
);
const bwicStatusFilterChange = createAction(
    'sellerBuyside/BWIC_STATUS_FILTER_CHANGE',
    resolve => (status: number, selected: boolean) => resolve({ status, selected })
);
const currencyFilterChange = createAction(
    'sellerBuyside/CURRENCY_FILTER_CHANGE',
    resolve => (currency: string, selected: boolean) => resolve({ currency, selected })
);
const ratingFilterChange = createAction(
    'sellerBuyside/RATING_FILTER_CHANGE',
    resolve => (rating: Rating, selected: boolean) => resolve({ rating, selected })
);

const typeFilterChange = createAction(
    'sellerBuyside/TYPE_FILTER_CHANGE',
    resolve => (type: BwicType, selected: boolean) => resolve({ type, selected })
);
const sizeFilterChange = createAction(
    'sellerBuyside/SIZE_FILTER_CHANGE',
    resolve => (size: { min?: number | string, max?: number | string }) => resolve({ size })
)
const filterApply = createAction('sellerBuyside/FILTER_APPLY',
    resolve => (isApply?: boolean, isSearchTermChange?: boolean) => resolve({ isApply, isSearchTermChange }));
const filterReset = createAction('sellerBuyside/FILTER_RESET', resolve => () => resolve());
const dataItemsLoadingState = createAction(
    'sellerBuyside/LOADING',
    resolve => (isLoading: boolean) => resolve({ isLoading })
);
const storeSearchResult = createAction(
    'sellerBuyside/STORE_SEARCH_RESULT',
    resolve => (result: PaginationResult<SellerBuysideSearchResult>, summary?: SellerBuysideSearchResultSummary) =>
        resolve({ result, summary })
);
const storeCurrencySelectedStatus = createAction(
    'sellerBuyside/SET_CURRENCIES_SELECTED_STATUS',
    resolve => (isSelected: boolean) => resolve({ isSelected })
);
export type SubmitBidRequestPayloadType = {
    bwicReferenceName: string;
    positionId: number;
    level: number;
    axed: boolean;
    final: boolean;
    positionSize: number;
    agreementBrokerDealerId: number;
    agreementCommission: number;
    commission: number;
    modifiedDate?: Date;
};
const submitBidRequest = createAction(
    'sellerBuyside/SUBMIT_BID_REQUEST',
    resolve => (data: SubmitBidRequestPayloadType) => resolve({ data })
);

const updateBidRequests = createAction(
    'sellerBuyside/UPDATE_BID_REQUESTS',
    resolve => (dataItems: SellerBuysideSearchResult[]) => resolve({ dataItems })
);
const updateRequestStateSubmitBid = createAction(
    'sellerBuyside/UPDATE_REQUEST_STATE_SUBMIT_BID_REQUEST',
    resolve => (status: boolean, positionId: number) => resolve({ status, positionId })
);
const saveSearchTermItems = createAction(
    'sellerBuyside/SAVE_SEARCH_TERM_ITEMS',
    resolve => (searchItems: string[]) => resolve({ searchItems })
);
const loadNextPage = createAction('sellerBuyside/LOAD_NEXT_PAGE');
const pagingReset = createAction('sellerBuyside/PAGING_RESET');
const storeAppliedFilter = createAction(
    'sellerBuyside/STORE_APPLIED_FILTER',
    resolve => (filter: SellerBuysideFilterState) => resolve({ filter })
);
const confirmBidRequestPopup = createAction(
    'sellerBuyside/CONFIRM_BID_REQUEST_POPUP',
    resolve => (positionId: number, visible: boolean) => resolve({ positionId, visible })
);
const exportBidRequestsRequest = createAction('sellerBuyside/EXPORT_BID_REQUESTS_REQUEST');
const exportBidRequestsSuccess = createAction('sellerBuyside/EXPORT_BID_REQUESTS_SUCCESS');
const exportBidRequestsFailure = createAction('sellerBuyside/EXPORT_BID_REQUESTS_FAILURE');


const updateEditBidRequestState = createAction(
    'sellerBuyside/EDIT_BID_REQUEST_STATE',
    resolve => (editBidRequestState: EditBidRequestState) => resolve({ editBidRequestState })
);
const hardReset = createAction('sellerBuyside/HARD_RESET');
const levelChange = createAction('sellerBuyside/LEVEL_CHANGE',
    resolve => (positionId: number, valueRaw: string, value: number | undefined, error: string, agreement: SettlementAgentAgreement) =>
        resolve({ positionId, valueRaw, value, error, agreement })
);
const axedChange = createAction('sellerBuyside/AXED_CHANGE',
    resolve => (positionId: number, axed: boolean, agreement: SettlementAgentAgreement) =>
        resolve({ positionId, axed, agreement })
);
const finalChange = createAction('sellerBuyside/FINAL_CHANGE',
    resolve => (positionId: number, final: boolean, agreement: SettlementAgentAgreement) =>
        resolve({ positionId, final, agreement })
);
const commissionChange = createAction('sellerBuyside/COMMISSION_CHANGE',
    resolve => (positionId: number, commission: number, error: string, agreement: SettlementAgentAgreement) =>
        resolve({ positionId, commission, error, agreement })
);
const resetPositionEditState = createAction('sellerBuyside/RESET_POSITION_EDIT_STATE',
    resolve => (positionId: number) => resolve({ positionId })
);
const submittedBidsFilterChange = createAction('sellerBuyside/SUBMITTED_BIDS_FILTER_CHANGE',
    resolve => (submittedBidsFilterValue: boolean | null) => resolve({ submittedBidsFilterValue })
);
const liveBiddingStage2TimerExpired = createAction('sellerBuyside/LIVE_BIDING_STAGE2_TIME_ENDED',
    resolve => (positionId: number) => resolve({ positionId })
);
const sortChanged = createAction('sellerBuyside/SORTING_CHANGED',
    resolve => (sortBy: BwicMonitorSortByOptions) => resolve({ sortBy })
);
const sortFieldChanged = createAction('sellerBuyside/SORTING_FIELD_CHANGED',
    resolve => (sortBy: BwicMonitorSortByOptions) => resolve({ sortBy })
);
const advancedFiltersBlocked = createAction('sellerBuyside/ADVANCED_FILTERS_BLOCKED',
    resolve => (blocked: boolean) => resolve({ blocked })
);
const resetSummary = createAction('sellerBuyside/RESET_SUMMARY');

export const sellerBuysideActions = {
    init,
    initRequestStatus,
    reset,
    hardReset,
    dateFilterChange,
    customDateFilterChange,
    bwicStatusFilterChange,
    currencyFilterChange,
    ratingFilterChange,
    typeFilterChange,
    sizeFilterChange,
    filterApply,
    filterReset,
    dataItemsLoadingState,
    storeSearchResult,
    loadNextPage,
    pagingReset,
    storeCurrencySelectedStatus,
    submitBidRequest,
    updateBidRequests,
    updateRequestStateSubmitBid,
    saveSearchTermItems,
    storeAppliedFilter,
    confirmBidRequestPopup,
    updateEditBidRequestState,
    exportBidRequestsRequest,
    exportBidRequestsSuccess,
    exportBidRequestsFailure,
    levelChange,
    axedChange,
    finalChange,
    commissionChange,
    resetPositionEditState,
    submittedBidsFilterChange,
    liveBiddingStage2TimerExpired,
    sortChanged,
    sortFieldChanged,
    advancedFiltersBlocked,
    resetSummary
};

