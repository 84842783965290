import { createAction } from 'typesafe-actions';
import { RequestState } from '../constants/request-state';
import { InventoryPosition } from '../types/inventory/InventoryPosition';
import { InventoryPositionStatus } from '../types/inventory/InventoryPositionStatus';
import { InventorySecurityOrderByColumn, InventorySecuritySortOrder } from '../types/inventory/InventorySecurityOrderByColumn';
import { InventoryResultSummary } from '../types/inventory/InventoryResultSummary';

const request = createAction('entities/inventory/REQUEST');
const requestResult = createAction('entities/inventory/REQUEST_RESULT',
    resolve => (requestState: RequestState, result: InventoryPosition[] = [], totalRecordNumber: number = 0, summary?: InventoryResultSummary) =>
        resolve({ requestState, result, totalRecordNumber, summary })
);
const resetEntities = createAction('entities/inventory/RESET');
const reset = createAction('inventory/RESET');
const resetSummary = createAction('inventory/RESET_SUMMARY');
const loadNextPage = createAction('entities/inventory/LOAD_NEXT_PAGE');
const init = createAction(
    'inventory/INIT',
    resolve => (sortField: InventorySecurityOrderByColumn, sortDirection: InventorySecuritySortOrder) => resolve({ sortField, sortDirection })
);
const requestInventoryExistFlag = createAction('invetory/INVENTORY_EXIST_FLAG_REQUEST');
const inventoryExistFlag = createAction(
    'inventory/INVENTORY_EXIST_FLAG',
    resolve => (exist: boolean) => resolve({ exist })
);
const loadSellerInventory = createAction(
    'inventory/LOAD_SELLER_INVENTORY',
    resolve => (sortField: InventorySecurityOrderByColumn, sortDirection: InventorySecuritySortOrder, loadSummary: boolean, applyFilter: boolean = true) => resolve({ sortField, sortDirection, loadSummary, applyFilter })
);

const exportInventoryRequest = createAction(
    'inventory/EXPORT_INVENTORY_REQUEST',
    resolve => (sortField: string, sortDirection: string) => resolve({ sortField, sortDirection })
);
const exportInventoryResponse = createAction('inventory/EXPORT_INVENTORY_RESPONSE');
const pushInventory = createAction(
    'entities/inventory/PUSH_INVENTORY',
    resolve => (inventories: InventoryPosition[], lockModifiedDate?: Date) => resolve({ inventories, lockModifiedDate })
);
const inventoryStatusChange = createAction(
    'entities/inventory/INVENTORY_STATUS_CHANGE',
    resolve => (inventoryPositionId: number, status: InventoryPositionStatus) => resolve({ inventoryPositionId, status })
);
const advancedFilterBlocked = createAction('inventory/ADVANCED_FILTER_BLOCKED', resolve => (blocked: boolean) => resolve({ blocked }));

export const inventoryActions = {
    init,
    requestInventoryExistFlag,
    inventoryExistFlag,
    loadNextPage,
    request,
    resetEntities,
    requestResult,
    reset,
    loadSellerInventory,
    exportInventoryRequest,
    exportInventoryResponse,
    pushInventory,
    inventoryStatusChange,
    advancedFilterBlocked,
    resetSummary
};
