import { ActionType, createAction } from "typesafe-actions"
import { RequestState } from "../../constants/request-state";
import { ArrangerPipeline } from "../../types/amr-pipeline/models/ArrangerPipeline";

const request = createAction('entities/arrangersPipeline/REQUEST',
    resolve => (tickers: string[]) => resolve({ tickers })
);
const response = createAction('entities/arrangersPipeline/RESPONSE',
    resolve => (requestState: RequestState, pipeline: ArrangerPipeline[] = []) =>
        resolve({ requestState, pipeline })
);
const reset = createAction('entities/arrangersPipeline/RESET');

export const arrangersPipelineActions = {
    request,
    response,
    reset
}

export type TArrangersPipelineActionType =
    ActionType<typeof arrangersPipelineActions[keyof typeof arrangersPipelineActions]>
