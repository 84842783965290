import { bwicHistoryActions as actionTypes } from '../constants';
import { bwicService } from '../services';
import { controlPanelActions } from '.';
import { isRequesting } from '../utils';
import { RequestState } from '../constants/request-state';

export const bwicHistoryActions = {
    init,
    loadHistory,
    showHistory,
    toggleHistory,
    markHistoryMessagesAsRead,
    reset
}

function init(bwicReferenceName, historyActions, positionId) {
    return {
        type: actionTypes.STORE_BWIC_HISTORY,
        bwicReferenceName,
        historyActions,
        positionId
    };
}

function loadHistory(bwicReferenceName, positionId) {
    return async (dispatch, getState) => {
        const historyState = getState().bwicHistory;

        if (!isRequesting(historyState.requestState) && historyState.bwicReferenceName !== bwicReferenceName) {
            dispatch(loadHistoryState(RequestState.request));

            try {
                const history = await bwicService.getBwicHistory(bwicReferenceName);
                dispatch(init(bwicReferenceName, history, positionId));
                dispatch(loadHistoryState(RequestState.success));
            } catch (e) {
                dispatch(loadHistoryState(RequestState.failure));
            }
        }
    };
}

function loadHistoryState(requestState) {
    return {
        type: actionTypes.LOAD_HISTORY_REQUEST_STATE,
        requestState
    }
}

function reset() {
    return {
        type: actionTypes.RESET
    };
}

function toggleHistory(positionId, ticker) {
    return (dispatch, getState) => {
        const history = getState().bwicHistory;
        const shouldShow = !history.visible || positionId !== history.positionId;

        dispatch(showHistory(shouldShow, positionId, ticker));
    };
}

function showHistory(visible, positionId, ticker) {
    return dispatch => {
        if (visible) {
            dispatch(controlPanelActions.hide());
        }

        dispatch({
            type: actionTypes.SHOW_BWIC_HISTORY,
            visible,
            positionId: visible && positionId,
            ticker: visible && ticker
        });
    };
}

function markHistoryMessagesAsRead(messages) {
    return dispatch => {
        dispatch({
            type: actionTypes.MARK_HISTORY_MESSAGES_AS_READ,
            messages
        });

        bwicService
            .markHistoryMessagesAsRead(messages)
            .catch(() => console.log('Failed to mark history action as read'));
    };
}
