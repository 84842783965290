import { formsActions as actionTypes } from '../constants';

export const formsActions = {
    activateValidation,
    reset
}

function activateValidation() {
    return { type: actionTypes.DISPLAY_VALIDATION_ERRORS, activate: true }
}

function reset() {
    return { type: actionTypes.DISPLAY_VALIDATION_ERRORS, activate: false }
}
