import { createAction } from 'typesafe-actions';
import { User } from '../types/account/User';

const user = createAction(
    'PUSH_USER_ACTION_USER',
    resolve => (user: User) => resolve({ user })
);

export const pushUserActions = {
    user
};

