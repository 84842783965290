import { ThunkDispatch } from 'redux-thunk';
import { history } from '../history';
import { AnyAction } from "redux";
import { createAction } from 'typesafe-actions';
import { AppState } from '../types/state/AppState';
import { gridActions } from './grid.actions';
import { notificationActions } from './notification.actions';
import { errorActions } from './error.actions';
import { errorMessages } from '../constants/error.messages';
import { TradeAllocationType } from '../types/trade-allocation/TradeAllocationType';
import { tradeAllocationTemplateService } from '../services/trade-allocation-template.service';
import { SaveTradeAllocationTemplate } from '../types/trade-allocation/template/SaveTradeAllocationTemplate';
import { user } from '../user';
import { GridDataItem } from '../types/state/GridState';
import { EditTradeAllocationTemplateItem } from '../types/trade-allocation/template/TradeAllocationTemplateItem';
import { tradeAllocationTemplateActions } from './trade-allocation-template.actions';
import { LocationStateBuilder, PanelType } from '../types/state/ui/LocationState';

const resetPanelState = createAction('blotter/trade-allocation-template/RESET');
const saveRequest = createAction('blotter/trade-allocation-template/SAVE_REQUEST');
const saveSuccess = createAction('blotter/trade-allocation-template/SAVE_SUCCESS');
const saveFailure = createAction('blotter/trade-allocation-template/SAVE_FAILURE');

export const blotterTradeAllocationTemplateActions = {
    resetPanelState,
    save,
    saveRequest,
    saveSuccess,
    saveFailure
}

function save(templateId: number, name: string, type: TradeAllocationType, activate: boolean, lock?: Date) {
    return async (dispatch: ThunkDispatch<AppState, void, AnyAction>, getState: () => AppState) => {
        dispatch(gridActions.validate());

        if (!getState().grid.isValid) return;

        dispatch(saveRequest());

        const items: GridDataItem<EditTradeAllocationTemplateItem>[] =
            getState().grid.dataItems.filter((i: GridDataItem<EditTradeAllocationTemplateItem>) => !i.draft && i.settlementAccountId);
        const saveModel: SaveTradeAllocationTemplate = {
            id: templateId || undefined,
            name,
            assetManager: { id: user.current()!.companyId },
            lock,
            type,
            items: items.map(i => ({
                id: i.id,
                settlementAccountId: Number(i.settlementAccountId),
                percent: type === TradeAllocationType.PercentOfTrade ? Number(i.percent) : undefined,
                rating: type === TradeAllocationType.CreditRatings ? i.rating : undefined
            }))
        };

        try {
            const template = await tradeAllocationTemplateService.saveTemplate(saveModel);
            dispatch(tradeAllocationTemplateActions.update(template));

            if (activate) {
                try {
                    const updates = await tradeAllocationTemplateService.setTemplateActive(template.id, true, template.lock);
                    const activated = updates.find(t => t.active);
                    const deactivated = updates.find(t => !t.active);

                    dispatch(tradeAllocationTemplateActions.activeChange(activated, deactivated));
                } catch (e) {
                    dispatch(errorActions.unexpectedError(e));
                }
            }
            
            dispatch(blotterTradeAllocationTemplateActions.saveSuccess());
            history.replace({
                ...history.location,
                state: new LocationStateBuilder()
                    .resetPanel()
                    .panel(PanelType.Templates)
                    .result()
            });
        } catch (e) {
            dispatch(saveFailure());
            if (e.status === 409) {
                dispatch(
                    notificationActions.notificationAddErrorMessageModal(
                        errorMessages.refreshPage,
                        "Can't save Trade Allocation Template",
                        true
                    )
                );
            } else {
                dispatch(errorActions.unexpectedError(e));
            }
        }
    };
}
