import { createAction } from 'typesafe-actions';

const confirmRequest = createAction(
    'portfolioAgreementDisclaimer/CONFIRM_REQUEST',
    resolve => (onConfirm?: () => void) => resolve({ onConfirm })
);
const confirmSuccess = createAction('portfolioAgreementDisclaimer/CONFIRM_SUCCESS');
const confirmFailure = createAction('portfolioAgreementDisclaimer/CONFIRM_FAILURE');

const downloadFileRequest = createAction('portfolioAgreementDisclaimer/DOWNLOAD_FILE_REQUEST');
const downloadFileSuccess = createAction('portfolioAgreementDisclaimer/DOWNLOAD_FILE_SUCCESS');
const downloadFileFailure = createAction('portfolioAgreementDisclaimer/DOWNLOAD_FILE_FAILURE');

const show = createAction(
    'portfolioAgreementDisclaimer/SHOW',
    resolve => (blockerId: string, text?:string) => resolve({ blockerId, text })
);
const hide = createAction(
    'portfolioAgreementDisclaimer/HIDE',
    resolve => (confirmed: boolean) => resolve({ confirmed })
);

const reset = createAction('portfolioAgreementDisclaimer/RESET');

export const portfolioAgreementDisclaimerActions = {
    show,
    hide,
    confirmRequest,
    confirmSuccess,
    confirmFailure,
    downloadFileRequest,
    downloadFileSuccess,
    downloadFileFailure,
    reset
};
