import { createAction } from 'typesafe-actions';

const fetchFavoriteBrokerDealersRequest = createAction(
    'favoriteBd/FETCH_REQUEST'
);

const fetchFavoriteBrokerDealersSuccess = createAction(
    'favoriteBd/FETCH_SUCCESS',
    resolve => (favorites: {[key: number]: boolean}) => resolve({ favorites })
);

const fetchFavoriteBrokerDealersFailure = createAction(
    'favoriteBd/FETCH_FAILURE'
);

const updateFavoriteBrokerDealerRequest = createAction(
    'favoriteBd/UPDATE_REQUEST',
    resolve => (dealerId: number, isFavorite: boolean) => resolve({ dealerId, isFavorite })
);

const updateFavoriteBrokerDealerSuccess = createAction(
    'favoriteBd/UPDATE_SUCCESS',
    resolve => (favorites: number[], dealerId: number) => resolve({ favorites, dealerId })
);

const updateFavoriteBrokerDealerFailure = createAction(
    'favoriteBd/UPDATE_FAILURE',
    resolve => (dealerId: number) => resolve({ dealerId })
);

const reset = createAction('favoriteBd/RESET');

export const favoriteBrokerDealersActions = {
    fetchFavoriteBrokerDealersRequest,
    fetchFavoriteBrokerDealersSuccess,
    fetchFavoriteBrokerDealersFailure,
    updateFavoriteBrokerDealerRequest,
    updateFavoriteBrokerDealerSuccess,
    updateFavoriteBrokerDealerFailure,
    reset,
};
