import { createAction } from 'typesafe-actions';

const reset = createAction('ui/table/RESET');
const hoverChange = createAction('ui/table/HOVER',
    resolve => (rowKey: string, hover: boolean) => resolve({ rowKey, hover })
);
const expandedChange = createAction('ui/table/EXPANDED',
    resolve => (rowKey: string, expanded: boolean) => resolve({ rowKey, expanded })
);
const resetExpanded = createAction('ui/table/RESET_EXPANDED');

const sortByChange = createAction('ui/table/SORT_BY',
    resolve => (tableKey: string, sortBy: string, sortDirection: string, sortingType: string) =>
        resolve({ tableKey, sortBy, sortDirection, sortingType })
);

export const tableActions = {
    hoverChange,
    expandedChange,
    resetExpanded,
    sortByChange,
    reset
};
