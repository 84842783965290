import { DateTimeRange } from '../types/filters/DateTimeRange';
import { DateFilterOption } from '../types/filters/DateFilterOption';
import { DateRange } from '../types/filters/DateRange';
import { DateTimeFilterOption } from '../types/filters/DateTimeFilterOption';
import { FilterRangeOption } from '../types/filters/FilterRangeOption';
import { RedirectFilterOption } from '../types/filters/RedirectFilterOption';
import { CommonFilter, TFilterType } from '../types/filters/FilterState';
import { createAction, createAsyncAction } from 'typesafe-actions';
import { Primitive } from '../utils/differ/types';
import { UserConfigFilter } from '../types/user-config/UserConfigFilter';
import { RadioOption } from '../types/filters/RadioOption';
import { TDeserializeRange } from '../utils/filtering/serializers/serializer.definitions';

export const createFilterActions = (filterType?: TFilterType) => {
    const init = createAction('filter/INIT', resolve => (filter: CommonFilter) => resolve({ filterType, filter }));

    const selectFilterFromConfig = createAction(
        'filter/SELECT_FILTER_FROM_CONFIG',
        resolve => (selectedFilter?: UserConfigFilter, referenceName?: string) =>
            resolve({ filterType, selectedFilter, referenceName }),
    );

    const filterSelectChange = createAction(
        'filter/SELECT_CHANGE',
        resolve => (option: Primitive, filterName: string) => resolve({ filterType, option, filterName }),
    );

    const resetFiltersAndUnselectSavedFilter = createAction(
        'filter/RESET_FILTERS_AND_UNSELECT_SAVED_FILTER',
        resolve => () => resolve({ filterType }),
    );

    const filterSelectSelectAll = createAction(
        'filter/SELECT_SELECT_ALL',
        resolve => (filterName: string, searchTerm: string) => resolve({ filterType, filterName, searchTerm }),
    );

    const filterSelectClearAll = createAction(
        'filter/SELECT_CLEAR_ALL',
        resolve => (filterName: string) => resolve({ filterName, filterType }),
    );

    const makeFilterVisible = createAction(
        'filter/MAKE_FILTER_VISIBLE',
        resolve => (filterName: string) => resolve({ filterType, filterName }),
    );

    const updateFilterState = createAction('filter/UPDATE_FILTER_STATE', resolve => () => resolve({ filterType }));

    const applyFilter = createAction('filter/APPLY_FILTER', resolve => () => resolve({ filterType }));

    const resetFilter = createAction('filter/RESET_FILTER', resolve => () => resolve({ filterType }));

    const resetFilterState = createAction('filter/RESET_FILTER_STATE', resolve => () => resolve({ filterType }));

    const resetFiltersVisibility = createAction(
        'filter/RESET_FILTERS_VISIBILITY',
        resolve => () => resolve({ filterType }),
    );

    const filterVisibilityChange = createAction(
        'filter/VISIBILITY_CHANGE',
        resolve => (filterName: string) => resolve({ filterType, filterName }),
    );

    const storeFilterData = createAsyncAction(
        'filter/STORE_FILTER_DATA_REQUEST',
        'filter/STORE_FILTER_DATA_SUCCESS',
        'filter/STORE_FILTER_DATA_FAILURE',
    )<
        { filterType: TFilterType; filterName: string },
        { filterType: TFilterType; filterName: string; data: unknown[] },
        { filterType: TFilterType; filterName: string }
    >();

    const changeRangeFilter = createAction(
        'filter/CHANGE_RANGE_FILTER',
        resolve => (value: FilterRangeOption, filterName: string) => resolve({ filterType, filterName, value }),
    );

    const filterRadioChange = createAction(
        'filter/RADIO_CHANGE',
        resolve => (option: string | boolean | RadioOption<number | string | boolean | null>, filterName: string) => resolve({ filterType, option, filterName }),
    );

    const filterRadioClearOption = createAction(
        'filter/RADIO_CLEAR_OPTION',
        resolve => (filterName: string) => resolve({ filterType, filterName }),
    );

    const filterRangeClearSelectedOptions = createAction(
        'filter/RANGE_CLEAR_SELECTED_OPTIONS',
        resolve => (filterName: string) => resolve({ filterType, filterName }),
    );

    const setFilterByReferenceName = createAction(
        'filter/SET_FILTER_BY_REFERENCE_NAME',
        resolve => (selectedFilterReferenceName: string, selectedFilter?: CommonFilter) =>
            resolve({ filterType, selectedFilter, selectedFilterReferenceName }),
    );

    const filterDateClearSelectedOptions = createAction(
        'filter/DATE_CLEAR_SELECTED_OPTIONS',
        resolve => (filterName: string) => resolve({ filterType, filterName }),
    );

    const filterDateSelectOption = createAction(
        'filter/FILTER_DATE_SELECT_OPTION',
        resolve => (option: DateFilterOption, filterName: string) => resolve({ filterType, option, filterName }),
    );

    const filterDateTimeOptionSelected = createAction(
        'filter/FILTER_DATE_TIME_SELECT_OPTION',
        resolve => (option: DateTimeFilterOption, filterName: string) => resolve({ filterType, option, filterName }),
    );

    const filterDateSelectCustomRange = createAction(
        'filter/FILTER_DATE_SELECT_CUSTOM_RANGE',
        resolve => (option: DateRange, filterName: string) => resolve({ filterType, option, filterName }),
    );

    const filterDateTimeSelectCustomRange = createAction(
        'filter/FILTER_DATE_TIME_SELECT_CUSTOM_RANGE',
        resolve => (option: DateTimeRange, filterName: string) => resolve({ filterType, option, filterName }),
    );

    const filterDateSelectYearsRange = createAction(
        'filter/FILTER_DATE_SELECT_YEARS_RANGE',
        resolve => (option: FilterRangeOption, filterName: string) => resolve({ filterType, option, filterName }),
    );

    const setRedirectFilters = createAction(
        'filter/SET_REDIRECT_FILTERS',
        resolve => (filter: RedirectFilterOption) => resolve({ filterType, filter }),
    );

    const redirectWithNoFilter = createAction(
        'filter/REDIRECT_WITH_NO_FILTER',
        resolve => () => resolve({ filterType }),
    );

    const filterDateQueryStringDeserialized = createAction(
        'filter/FILTER_DATE_QUERY_STRING_DESERIALIZED',
        resolve => (filterName: string, deserializeResult: TDeserializeRange) => resolve({ filterType, filterName, deserializeResult }),
    );

    const resetSelectedFilter = createAction(
        'filter/RESET_SELECTED_FILTER',
        resolve => () => resolve({ filterType })
    );

    return {
        init,
        selectFilterFromConfig,
        filterSelectChange,
        filterSelectSelectAll,
        filterSelectClearAll,
        makeFilterVisible,
        updateFilterState,
        applyFilter,
        resetFilter,
        resetFiltersVisibility,
        storeFilterData,
        changeRangeFilter,
        filterRadioChange,
        filterRadioClearOption,
        filterRangeClearSelectedOptions,
        setFilterByReferenceName,
        filterDateClearSelectedOptions,
        filterDateSelectOption,
        filterDateTimeOptionSelected,
        filterDateSelectCustomRange,
        filterDateTimeSelectCustomRange,
        filterDateSelectYearsRange,
        setRedirectFilters,
        resetFilterState,
        filterVisibilityChange,
        resetFiltersAndUnselectSavedFilter,
        redirectWithNoFilter,
        filterDateQueryStringDeserialized,
        resetSelectedFilter
    };
};
