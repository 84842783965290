import { ThunkDispatch } from 'redux-thunk';
import { createAction } from 'typesafe-actions';
import { AnyAction } from "redux";
import { AppState } from '../types/state/AppState';
import { EditTradeAllocationItem } from '../types/state/entities/TradeAllocationState';
import { GridDataItem } from '../types/state/GridState';
import { numericUtils } from '../utils';
import { gridActions } from './grid.actions';
import { notificationActions } from './notification.actions';
import { errorActions } from './error.actions';
import { errorMessages } from '../constants/error.messages';
import { TradeSettlementStatus } from '../types/settlement/TradeSettlementStatus';
import { settlementService } from '../services/settlement.service';
import { SaveTradeAllocationItem } from '../types/trade-allocation/TradeAllocationItem';
import { tradeAllocationActions } from './trade-allocation.actions';
import { history } from '../history';
import { LocationState, LocationStateBuilder } from '../types/state/ui/LocationState';
import { awaitingTradesActions } from './entities/awaitingTrades.actions';
import { gridColumns } from '../constants';
import { TradeSide } from '../types/trades/TradeSide';

const resetPopupState = createAction('blotter/trade-allocation/RESET');
const saveRequest = createAction('blotter/trade-allocation/SAVE_REQUEST');
const saveSuccess = createAction('blotter/trade-allocation/SAVE_SUCCESS',
    resolve => (
        tradeId: string,
        buyerSettlementStatus: TradeSettlementStatus,
        sellerSettlementStatus: TradeSettlementStatus,
        allocationLock: Date,
        isBuyerAllocationManual: boolean,
        isSellerAllocationManual: boolean
    ) =>
        resolve({ tradeId, allocationLock, buyerSettlementStatus, sellerSettlementStatus, isBuyerAllocationManual, isSellerAllocationManual })
);
const saveFailure = createAction('blotter/trade-allocation/SAVE_FAILURE');

export const blotterTradeAllocationActions = {
    resetPopupState,
    recalculateAmountOfTrade,
    recalculatePercentOfTrade,
    save,
    saveRequest,
    saveSuccess,
    saveFailure
}

function recalculateAmountOfTrade(rowIndex: number, tradeId: string) {
    // use thunk because gridActions.deleteRow && gridActions.insertDataItems cannot be dispatched from saga
    return (dispatch: ThunkDispatch<AppState, void, AnyAction>, getState: () => AppState) => {
        const dataItem: GridDataItem<EditTradeAllocationItem> =
            getState().grid.dataItems[rowIndex];
        const trade = getState().blotter.securities.find(t => t.tradeId === tradeId)

        if (dataItem && trade) {
            let amount: number | undefined;

            if (dataItem.percent != null && dataItem.percent !== '') {
                const percent = numericUtils.numberOrDefault(dataItem.percent);
                if (numericUtils.isNumber(percent)) {
                    amount = numericUtils.round(trade.size / 100 * percent);
                }
            }

            const updated: GridDataItem<EditTradeAllocationItem> = {
                ...dataItem,
                amount: amount ?? gridColumns.amountOfTrade.initialValue
            };

            dispatch(gridActions.deleteRow(rowIndex));
            dispatch(gridActions.insertDataItems([updated], rowIndex));
            dispatch(gridActions.updateDataItemDraftStatus(rowIndex));
        };
    };
}

function recalculatePercentOfTrade(rowIndex: number, tradeId: string) {
    // use thunk because gridActions.deleteRow && gridActions.insertDataItems cannot be dispatched from saga
    return (dispatch: ThunkDispatch<AppState, void, AnyAction>, getState: () => AppState) => {
        const dataItem: GridDataItem<EditTradeAllocationItem> =
            getState().grid.dataItems[rowIndex];
        const trade = getState().blotter.securities.find(t => t.tradeId === tradeId)

        if (dataItem && trade) {
            let percent: number | undefined;

            if (dataItem.amount != null && dataItem.amount !== '') {
                if (numericUtils.isNumber(dataItem.amount)) {
                    percent = numericUtils.round(Number(dataItem.amount) / (trade.size / 100), 2);
                }
            }

            const updated: GridDataItem<EditTradeAllocationItem> = {
                ...dataItem,
                percent: percent ?? gridColumns.percentOfTrade.initialValue
            };

            dispatch(gridActions.deleteRow(rowIndex));
            dispatch(gridActions.insertDataItems([updated], rowIndex));
            dispatch(gridActions.updateDataItemDraftStatus(rowIndex));
        };
    };
}

function save(tradeId: string, allocationLock: Date, side: TradeSide) {
    return async (dispatch: ThunkDispatch<AppState, void, AnyAction>, getState: () => AppState) => {
        dispatch(gridActions.validate());

        if (!getState().grid.isValid) return;

        dispatch(saveRequest());

        const items: SaveTradeAllocationItem[] = getState().grid.dataItems
            .filter((i: GridDataItem<EditTradeAllocationItem>) => !i.draft)
            .map(i => ({
                id: i.id,
                settlementAccountId: Number(i.settlementAccountId),
                amount: Number(i.amount),
                percent: Number(i.percent),
                proceed: i.proceed == null || i.proceed === '' ? undefined : Number(i.proceed),
                side
            }));

        try {
            const response = await settlementService.saveTradeAllocation(tradeId, allocationLock, items, side);

            dispatch(blotterTradeAllocationActions.saveSuccess(
                tradeId,
                response.buyerSettlementStatus,
                response.sellerSettlementStatus,
                response.allocationLock,
                response.isBuyerAllocationManual,
                response.isSellerAllocationManual,
            ));

            // TO DO: Remove when trade allocation push will be implemented
            dispatch(
                awaitingTradesActions.tradeAllocationCreated(
                    response.referenceName,
                    response.buyerSettlementStatus,
                    response.sellerSettlementStatus
                )
            )

            dispatch(tradeAllocationActions.update(tradeId, response.items));

            // Close popup
            const location = {
                ...history.location,
                state: new LocationStateBuilder(history.location.state as LocationState<unknown, unknown>)
                    .resetPopup()
                    .result()
            };
            history.replace(location);

        } catch (e) {
            dispatch(blotterTradeAllocationActions.saveFailure());
            if (e.status === 409) {
                dispatch(
                    notificationActions.notificationAddErrorMessageModal(
                        errorMessages.refreshPage,
                        "Can't save Trade Allocation",
                        true
                    )
                );
            } else {
                dispatch(errorActions.unexpectedError(e));
            }
        }
    };
}
