import { ActionType, createAction } from 'typesafe-actions';
import { RequestState } from '../constants/request-state';
import { DateFilterOption } from '../types/filters/DateFilterOption';
import { DateRange } from '../types/filters/DateRange';
import { AccessToken, AccessTokenApi } from '../types/management/AccessToken';
import { Company } from '../types/management/Company';

const requestTokens = createAction('manageToken/REQUEST_TOKENS');
const storeTokens = createAction('manageToken/GET_TOKENS',
    resolve => (tokens: AccessToken[]) => resolve({ tokens })
);
const requestCompanies = createAction('manageToken/REQUEST_COMPANIES');
const storeCompanies = createAction('manageToken/COMPANIESS',
    resolve => (companies: Company[]) => resolve({ companies })
);
const setSearchTerm = createAction('manageToken/SET_SEARCH_TERM',
    resolve => (searchTerm: string) => resolve({ searchTerm })
);
const setTokenRequestStatus = createAction('manageToken/TOKENS_REQUEST_STATUS',
    resolve => (requestStatus: RequestState) => resolve({ requestStatus })
);
const setCompanyRequestStatus = createAction('manageToken/COMPANIES_REQUEST_STATUS',
    resolve => (requestStatus: RequestState) => resolve({ requestStatus })
);
const setGenerateRequestStatus = createAction('manageToken/GENERATE_REQUEST_STATUS',
    resolve => (requestStatus: RequestState) => resolve({ requestStatus })
);
const setUpdateTokenRequestStatus = createAction('manageToken/UPDATE_REQUEST_STATUS',
    resolve => (requestStatus: RequestState) => resolve({ requestStatus })
);
const storeGeneratedToken = createAction('manageToken/STORE_GENERATED_TOKEN',
    resolve => (token?: AccessToken) => resolve({ token })
);
const generate = createAction('manageToken/GENERATE',
    resolve => (companyId: number, apis: AccessTokenApi[]) => resolve({ companyId, apis })
);
const update = createAction('manageToken/UPDATE',
    resolve => (tokenId: number, companyId: number, apis: AccessTokenApi[]) => resolve({ tokenId, companyId, apis })
);
const tokenUpdated = createAction('manageToken/UPDATED',
    resolve => (token: AccessToken) => resolve({ token })
);
const revoke = createAction('manageToken/REVOKE', resolve => (tokenId: number) => resolve({ tokenId }));
const tokenRevoked = createAction('manageToken/REVOKED', resolve => (tokenId: number) => resolve({ tokenId }));
const setRevokeTokenRequestStatus = createAction(
    'manageToken/SER_REVOKE_TOKEN_REQUEST_STATUS',
        resolve => (tokenId: number, status: RequestState) => resolve({tokenId, status})
);
const toogleFilterStatusActive = createAction('manageToken/filter/TOOGLE_STATUS_ACTIVE');
const toogleFilterStatusExpired = createAction('manageToken/filter/TOOGLE_STATUS_EXPIRED');
const toogleFilterStatusRevoked = createAction('manageToken/filter/TOOGLE_STATUS_REVOKED');
const toogleFilterApiBlotterApiReader = createAction('manageToken/filter/TOOGLE_API_BLOTTER');
const toogleFilterApiBwicMonitorApiReader = createAction('manageToken/filter/TOOGLE_API_BWIC_MONITOR');
const toogleFilterApiIssuanceMonitorApiReader = createAction('manageToken/filter/TOOGLE_API_ISSUANCE_MONITOR');
const toogleFilterApiPortfolioApi = createAction('manageToken/filter/TOOGLE_API_PORTFOLIO');
const toogleFilterApiEvalApiReader = createAction('manageToken/filter/TOOGLE_API_EVAL');
const toogleFilterApiDashboardApiReader = createAction('manageToken/filter/TOOGLE_API_DASHBOARD');
const toogleFilterCompanies = createAction('manageToken/filter/_TOOGLE_COMPANIES',
    resolve => (companies: number[]) => resolve({ companies })
);
const setFilterCompanies = createAction('manageToken/filter/SET_COMPANIES',
    resolve => (companies: number[]) => resolve({ companies })
);
const setDateFilterChange = createAction('manageToken/filter/DATE_CHANGED',
    resolve => (dateOption: DateFilterOption) => resolve({ dateOption })
);
const setDateFilterRangeChange = createAction('manageToken/filter/DATE_RANGE_CHANGED',
    resolve => (dateRange: DateRange) => resolve({ dateRange })
);
const resetTokenFilters = createAction('manageToken/filter/RESET');
const applyTokenFilters = createAction('manageToken/filter/APPLY');

const reset = createAction('manageToken/RESET');

export type ManageTokenActionsType = ActionType<typeof manageTokenActions[keyof typeof manageTokenActions]>

export const manageTokenActions = {
    storeTokens,
    toogleFilterStatusActive,
    toogleFilterStatusExpired,
    toogleFilterStatusRevoked,
    toogleFilterApiBlotterApiReader,
    toogleFilterApiBwicMonitorApiReader,
    toogleFilterApiIssuanceMonitorApiReader,
    toogleFilterApiPortfolioApi,
    toogleFilterApiEvalApiReader,
    toogleFilterApiDashboardApiReader,
    applyTokenFilters,
    resetTokenFilters,
    setSearchTerm,
    toogleFilterCompanies,
    setFilterCompanies,
    setDateFilterChange,
    setDateFilterRangeChange,
    setTokenRequestStatus,
    requestTokens,
    requestCompanies,
    storeCompanies,
    setCompanyRequestStatus,
    generate,
    update,
    tokenUpdated,
    revoke,
    tokenRevoked,
    setRevokeTokenRequestStatus,
    setGenerateRequestStatus,
    storeGeneratedToken,
    setUpdateTokenRequestStatus,
    reset
}
