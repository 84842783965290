import { dealersActions as actionTypes } from '../constants';
import { errorActions } from '.';
import { settlementAgentAgreementService } from '../services/settlement-agent-agreement.service';
import { createAction } from 'typesafe-actions';

const updateFilterOptions = createAction(
    'dealers/UPDATE_FILTER_OPTIONS',
    resolve => (filterOption) => resolve({ filterOption })
);

const clearFilterOptions = createAction('dealers/CLEAR_FILTER_OPTIONS');

const setSearchTerm = createAction(
    'dealers/SET_SEARCH_TERM',
    resolve => (searchTerm) => resolve({ searchTerm })
);

export const dealersActions = {
    requestToBid,
    reset,
    updateFilterOptions,
    clearFilterOptions,
    setSearchTerm,
    showBidRequestConfirmPopup,
    hideBidRequestConfirmPopup,
};

function reset() {
    return { type: actionTypes.DEALERS_RESET_STATE };
}

function requestToBid(settlementAgentId) {
    return (dispatch) => {
        dispatch(setRequestStatus(true));
        settlementAgentAgreementService.sendRequest({ settlementAgentId })
            .then(() => {
                dispatch(hideBidRequestConfirmPopup());
            })
            .catch(e => dispatch(errorActions.unexpectedError(e)))
            .finally(() => dispatch(setRequestStatus(false)));
    };
}

function setRequestStatus(status) {
    return {
        type: actionTypes.DEALERS_REQUEST_TO_BID_SET_REQUEST_STATUS,
        payload: { status }
    };
}

function showBidRequestConfirmPopup(dealerId) {
    return { type: actionTypes.DEALERS_SHOW_CONFIRM_BID_REQUEST_POPUP, payload: { dealerId } }
}

function hideBidRequestConfirmPopup() {
    return { type: actionTypes.DEALERS_HIDE_CONFIRM_BID_REQUEST_POPUP }
}
