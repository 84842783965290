import { createAction } from "typesafe-actions";
import { BillingContact } from "../types/billing/BillingContact";
import { PaymentCard } from "../types/billing/PaymentCard";
import { SaveBillingContact } from "../types/billing/SaveBillingContact";
import { SavePaymentCard } from "../types/billing/SavePaymentCard";
import { City } from "../types/location/City";
import { Country } from "../types/location/Country";
import { State } from "../types/location/State";
import { SubscriptionType } from "../types/billing/SubscriptionType";
import { UpgradeSubscriptionStep } from "../types/state/SubscriptionsState";
import { SubscriptionPush } from "../types/billing/SubscriptionPush";

const init = createAction('subscriptions/INIT');
const reset = createAction('subscriptions/RESET');
const loadingFlag = createAction(
    'subscriptions/LOADING',
    resolve => (isLoading: boolean) => resolve({ isLoading })
);
const paymentCardSavingFlag = createAction(
    'subscriptions/PAYMENT_CARD_SAVING',
    resolve => (isSaving: boolean) => resolve({ isSaving })
);
const storePaymentCards = createAction(
    'subscriptions/STORE_PAYMENT_CARDS',
    resolve => (paymentCards: PaymentCard[]) => resolve({ paymentCards })
);
const sortPaymentCards = createAction(
    'subscriptions/SORT_PAYMENT_CARDS'
);
const primaryCardChange = createAction(
    'subscriptions/PRIMARY_CARD_CHANGE',
    resolve => (paymentCard: PaymentCard, moveOnTop?: boolean) => resolve({ paymentCard, moveOnTop })
);
const primaryCardChangeCompleted = createAction(
    'subscriptions/PRIMARY_CARD_CHANGE_COMPLETED',
    resolve => (paymentCard: PaymentCard, moveOnTop?: boolean) => resolve({ paymentCard, moveOnTop })
);
const deletePaymentCard = createAction(
    'subscriptions/DELETE_PAYMENT_CARD',
    resolve => (paymentCard: PaymentCard) => resolve({ paymentCard })
);
const deletePaymentCardCompleted = createAction(
    'subscriptions/DELETE_PAYMENT_CARD_COMPLETED',
    resolve => (paymentCard: PaymentCard) => resolve({ paymentCard })
);
const addPaymentCardPopupVisible = createAction(
    'subscriptions/ADD_PAYMENT_CARD_POPUP_VISIBLE',
    resolve => (visible: boolean) => resolve({ visible })
);
const addPaymentCard = createAction(
    'subscriptions/ADD_PAYMENT_CARD',
    resolve => (paymentCard: SavePaymentCard, isLoadingVisible: boolean, setAsPrimary: boolean) =>
        resolve({ paymentCard, isLoadingVisible, setAsPrimary })
);
const storeBillingContact = createAction(
    'subscriptions/BILLING_CONTACT',
    resolve => (billingContact: BillingContact) => resolve({ billingContact })
);
const storeCountries = createAction(
    'subscriptions/COUNTRIES',
    resolve => (countries: Country[]) => resolve({ countries })
);
const storeStates = createAction(
    'subscriptions/STATES',
    resolve => (states: State[]) => resolve({ states })
);
const storeCities = createAction(
    'subscriptions/CITIES',
    resolve => (cities: City[]) => resolve({ cities })
);
const billingContactEditFlag = createAction(
    'subscriptions/BILLING_CONTACT_EDIT',
    resolve => (isBillingContactEdit: boolean) => resolve({ isBillingContactEdit })
);
const billingContactSavingFlag = createAction(
    'subscriptions/BILLING_CONTACT_SAVING',
    resolve => (isBillingContactSaving: boolean) => resolve({ isBillingContactSaving })
);
const saveBillingContact = createAction(
    'subscriptions/SAVE_BILLING_CONTACT',
    resolve => (billingContact: SaveBillingContact) => resolve({ billingContact })
);
const loadCities = createAction(
    'subscriptions/LOAD_CITIES',
    resolve => (stateId: number) => resolve({ stateId })
);
const citiesLoadingFlag = createAction(
    'subscriptions/CITIES_LOADING_FLAG',
    resolve => (isCitiesLoading: boolean) => resolve({ isCitiesLoading })
);
const billingContactSaved = createAction(
    'subscriptions/BILLING_CONTACT_SAVED',
    resolve => (billingContact: BillingContact) => resolve({ billingContact })
);
const subscriptionPushReceived = createAction(
    'subscriptions/SUBSCRIPTION_CHANGE_PUSH',
    resolve => (subscription: SubscriptionPush) => resolve({ subscription })
);
const upgradeSubscription = createAction(
    'subscriptions/UPGRADE',
    resolve => (targetSubscriptionType: SubscriptionType) => resolve({ targetSubscriptionType })
);
const showUpgradeSubscriptionWizard = createAction(
    'subscriptions/SHOW_UPGRADE_SUBSCRIPTION_WIZARD',
    resolve => (targetSubscriptionType: SubscriptionType, activeStep: UpgradeSubscriptionStep = UpgradeSubscriptionStep.BillingContact) =>
        resolve({ targetSubscriptionType, activeStep })
);
const hideUpgradeSubscriptionWizard = createAction('subscriptions/HIDE_UPGRADE_SUBSCRIPTION_WIZARD');
const setUpgradeSubscriptionStep = createAction(
    'subscriptions/UPGRADE_SUBSCRIPTION_STEP',
    resolve => (step: UpgradeSubscriptionStep) => resolve({ step })
);

export const subscriptionsActions = {
    init,
    reset,
    loadingFlag,
    paymentCardSavingFlag,
    storePaymentCards,
    sortPaymentCards,
    storeBillingContact,
    storeCountries,
    storeStates,
    storeCities,
    loadCities,
    citiesLoadingFlag,
    primaryCardChange,
    primaryCardChangeCompleted,
    deletePaymentCard,
    deletePaymentCardCompleted,
    addPaymentCardPopupVisible,
    addPaymentCard,
    billingContactEditFlag,
    billingContactSavingFlag,
    saveBillingContact,
    billingContactSaved,
    subscriptionPushReceived,
    upgradeSubscription,
    showUpgradeSubscriptionWizard,
    hideUpgradeSubscriptionWizard,
    setUpgradeSubscriptionStep
};

