import { errorActions as actionTypes, errorMessages } from '../constants';
import { logger } from '../logging/logger';
import { jsonUtils } from '../utils';
import { notificationActions } from './notification.actions';

export const errorActions = {
    criticalError,
    error,
    unexpectedError,
    resetError,
    isRequestCancelationError
};

function criticalError(error) {
    if (isRequestCancelationError(error)) {
        return {
            type: actionTypes.REQUEST_CANCELATION_ERROR,
            error
        };
    }

    return {
        type: actionTypes.CRITICAL_ERROR,
        error
    };
}

export function isRequestCancelationError(error) {
    return error && error.name === 'AbortError';
}

function error(error, message, caption) {
    if (isRequestCancelationError(error)) {
        return {
            type: actionTypes.REQUEST_CANCELATION_ERROR,
            error
        };
    } else if (error && +error.status === 401) {
        return criticalError(error);
    }

    const errorJson = error && error.message && jsonUtils.tryParse(error.message);
    message = (errorJson && errorJson.error) || (error && error.message) || message;
    caption = (errorJson && errorJson.title) || caption;

    const isModal = (errorJson && errorJson.displayInModal) || false;
    const refreshButtonVisible = Boolean(errorJson && errorJson.refreshButtonVisible);

    logger.exception(message, error);

    return isModal
        ? notificationActions.notificationAddErrorMessageModal(message, caption || errorMessages.error, refreshButtonVisible)
        : notificationActions.notificationAddErrorMessage(message, caption || errorMessages.error);
}

function unexpectedError(err) {
    return error(err, errorMessages.unexpectedError);
}

function resetError() {
    return { type: actionTypes.ERROR_RESET }
}
