import { createAction } from 'typesafe-actions';
import { RequestState } from "../constants/request-state";
import { Contact } from '../types/company/Contact';

const request = createAction('entities/broker-dealer-contact-list/REQUEST');
const requestResult = createAction('entities/broker-dealer-contact-list/REQUEST_RESULT',
    resolve => (requestState: RequestState, result: Contact[] = []) =>
        resolve({ requestState, result })
);
const pushUpdateList = createAction(
    'entities/broker-dealer-contact-list/PUSH_UPDATE_LIST',
    resolve => (contacts: Contact[] = []) => resolve({ contacts })
);
const setContactInvitationSent = createAction(
    'entities/broker-dealer-contact-list/PUSH_CONTACT_INVITATION',
    resolve => (contactId: number) => resolve({ contactId })
);
const resetEntities = createAction('entities/broker-dealer-contact-list/RESET');

export const brokerDealerContactListActions = {
    request,
    requestResult,
    pushUpdateList,
    setContactInvitationSent,
    resetEntities
};
