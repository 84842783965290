import { createAction } from 'typesafe-actions';
import { BwicPositionBase } from '../types/bwic/BwicPositionBase';
import { InventoryPosition } from '../types/inventory/InventoryPosition';
import { SecurityBwicStatisticsSummary } from '../types/security/SecurityBwicStatisticsSummary';

const bwicLogReset = createAction('bwic-log/RESET');
const bwicLogInitRequest = createAction('bwic-log/INIT_REQUEST',
    resolve => (security: BwicPositionBase | InventoryPosition) =>
        resolve({ security })
);
const bwicLogInitSuccess = createAction('bwic-log/INIT_SUCCESS',
    resolve => (statistics: SecurityBwicStatisticsSummary) =>
        resolve({ statistics })
);
const bwicLogInitFailure = createAction('bwic-log/INIT_FAILURE',
    resolve => (error: string) => resolve({ error })
);
const bwicLogExportDataRequest = createAction('bwic-log/EXPORT_DATA_REQUEST',
    resolve => (securityId: number) => resolve({ securityId })
);
const bwicLogExportDataSuccess = createAction('bwic-log/EXPORT_DATA_SUCCESS');
const bwicLogExportDataFailure = createAction('bwic-log/EXPORT_DATA_FAILURE',
    resolve => (error: string) => resolve({ error })
);

export const bwicLogActions = {
    bwicLogInitRequest,
    bwicLogInitFailure,
    bwicLogInitSuccess,
    bwicLogReset,
    bwicLogExportDataRequest,
    bwicLogExportDataSuccess,
    bwicLogExportDataFailure
}
