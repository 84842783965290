import { logger } from '../logging/logger';
import { brokerDealerContactListActions } from './broker-dealer-contact-list.actions';
import { brokerDealerListActions } from './broker-dealer-list.actions';

export const pushInvitationActions = {
    dataPushReceived: (data) => {
        return dispatch => {
            logger.trace('Push', 'Invitation', data);
            if (data.contactId) {
                dispatch(brokerDealerContactListActions.setContactInvitationSent(data.contactId))
            }
            if (data.companyId) {
                dispatch(brokerDealerListActions.updateDealerDistributionListInvitationSent(data.companyId));
                dispatch(brokerDealerListActions.updateDealerHeadOfTradingInvitationSent(data.companyId))
            }
        }
    }
};
