import { profileActions as actionTypes } from '../constants';
import { accountActions, errorActions } from '.';
import { accountService } from '../services';
import { userProfilePhotoActions } from './user-profile-photo.actions';
import { user } from '../user';
import { sellerAdminUsersActions } from './seller-admin-users.actions';
import { sellerAdminDealerActions } from './seller-admin-dealers.actions';
import { sellerAdminLimitActions } from './seller-admin-limit.actions';
import { RequestState } from '../constants/request-state';

export const profileActions = {
    init,
    toggleEditPhoneMode,
    toggleEditBloombergEmail,
    savePhoneNumber,
    savePhoneNumberFailure,
    changeContactPopupVisible,
    changePasswordFormVisible,
    personalInfoReset,
    passwordSettingsReset,
    reset,
    addNewCompanyTraderToStore,
    editNewCompanyTraderInStore,
    editCompanyContact,
    enableCompanyTrader,
    disableCompanyTrader,
    setVisibleUploadImagePopup,
    uploadUserImage,
    deleteUserImage,
    saveBloombergEmail,
    changeBwicAnnouncementNotifications
};

function init() {
    return async dispatch => {
        dispatch({ type: actionTypes.PROFILE_INIT, requestState: RequestState.request });
        try {
            const profilePromise = accountService.getProfile();
            const companyPromise = accountService.getProfileCompany();
            const profile = await profilePromise;
            const company = await companyPromise;
            dispatch({ type: actionTypes.GET_PROFILE_SUCCESS, profile });
            dispatch({ type: actionTypes.GET_PROFILE_COMPANY_SUCCESS, payload: { company } });
            dispatch({ type: actionTypes.PROFILE_INIT, requestState: RequestState.success });

        } catch (e) {
            dispatch({ type: actionTypes.PROFILE_INIT, requestState: RequestState.failure });
            dispatch(errorActions.criticalError(e));
        }
    };
}

function toggleEditPhoneMode(changePhoneVisible) {
    return {
        type: actionTypes.CHANGE_PHONE_VISIBLE,
        changePhoneVisible
    };
}

function toggleEditBloombergEmail() {
    return { type: actionTypes.TOGGLE_VISIBILITY_FORM_BLOOMBERG_EMAIL }
}

function saveBloombergEmail(bloombergEmail) {
    return async dispatch => {
        try {
            dispatch(setRequestStatusSaveBloombergEmail(true));
            await accountService.saveBloombergEmail(bloombergEmail);
            user.setBloombergEmail(bloombergEmail);
        } catch (e) {
            dispatch(errorActions.unexpectedError(e));
        } finally {
            dispatch(setRequestStatusSaveBloombergEmail(false));
        }
    }
}

function setRequestStatusSaveBloombergEmail(status) {
    return { type: actionTypes.IS_REQUEST_SAVE_BLOOMBERG_EMAIL, payload: { status } }
}

function savePhoneNumber(phone) {
    return (dispatch, getState) => {
        const profile = getState().profile.profile;
        profile.phone = phone;

        dispatch({
            type: actionTypes.SAVE_PHONE_NUMBER,
            profile: {
                ...profile
            }
        });
    };
}

function savePhoneNumberFailure(errorMessage) {
    return {
        type: actionTypes.CHANGE_PHONE_NUMBER_FAILURE,
        error: { errorMessage: errorMessage }
    };
}

function changeContactPopupVisible(changeContactPopupVisible, contactId) {
    return {
        type: actionTypes.CHANGE_CONTACT_POPUP_VISIBLE,
        contact: {
            changeContactPopupVisible,
            contactId
        }
    };
}

function changePasswordFormVisible(changePasswordVisible) {
    return {
        type: actionTypes.CHANGE_PASSWORD_FORM_VISIBLE,
        changePasswordVisible
    };
}

function personalInfoReset() {
    return dispatch => {
        dispatch(toggleEditPhoneMode(false));
    };
}

function passwordSettingsReset() {
    return dispatch => {
        dispatch(accountActions.changePasswordReset());
        dispatch(changePasswordFormVisible(false));
    };
}

function reset() {
    return dispatch => {
        dispatch({ type: actionTypes.ACCOUNT_SETTINGS_RESET });
        dispatch(sellerAdminUsersActions.reset());
        dispatch(sellerAdminDealerActions.reset());
        dispatch(sellerAdminLimitActions.reset());
    };
}

function addNewCompanyTraderToStore(trader) {
    return (dispatch, getState) => {
        const profile = getState().profile;
        if (profile.company && profile.company.traders) {
            const company = { ...profile.company };
            company.traders.push(trader);
            dispatch({ type: actionTypes.PROFILE_COMPANY_ADD_NEW_TRADER, payload: { company } })
        }
    }
}

function editNewCompanyTraderInStore(trader, traderId) {
    return (dispatch, getState) => {
        const profile = getState().profile;
        if (profile.company && profile.company.traders) {
            const company = { ...profile.company };
            company.traders = company.traders.map((item) => {
                if (item.id === traderId) {
                    return { ...trader }
                }
                return item
            });
            dispatch({ type: actionTypes.PROFILE_COMPANY_EDIT_TRADER, payload: { company } })
        }
    }
}

function editCompanyContact(submitModel, departmentValue) {
    return (dispatch) => {
        accountService.editCompanyContact(submitModel, departmentValue)
            .then(() => {
                dispatch({ type: actionTypes.PROFILE_COMPANY_CONTACT_CHANGED, payload: { submitModel, departmentValue } });
            })
            .catch((e) => {
                dispatch(errorActions.unexpectedError(e));
            });
    };
}

function enableCompanyTrader(contact) {
    return async (dispatch) => {
        dispatch({ type: actionTypes.ENABLE_COMPANY_TRADER_REQUEST });
        try {
            const { id, status } = await accountService.enableCompanyTrader(contact.id);

            dispatch({ type: actionTypes.ENABLE_COMPANY_TRADER_SUCCESS });
            dispatch(editNewCompanyTraderInStore({ ...contact, status }, id));
        } catch (e) {
            dispatch(errorActions.unexpectedError(e));
            dispatch({ type: actionTypes.ENABLE_COMPANY_TRADER_FAIL });
        }
    };
}

function disableCompanyTrader(contact) {
    return async (dispatch) => {
        dispatch({ type: actionTypes.DISABLE_COMPANY_TRADER_REQUEST });
        try {
            const { id, status } = await accountService.disableCompanyTrader(contact.id);

            dispatch({ type: actionTypes.DISABLE_COMPANY_TRADER_SUCCESS });
            dispatch(editNewCompanyTraderInStore({ ...contact, status }, id));
        } catch (e) {
            dispatch(errorActions.unexpectedError(e));
            dispatch({ type: actionTypes.DISABLE_COMPANY_TRADER_FAIL });
        }
    };
}

function setVisibleUploadImagePopup(visible) {
    return {
        type: actionTypes.PROFILE_SET_VISIBLE_UPLOAD_USER_IMAGE_POPUP,
        payload: { visible }
    };
}

function requestStateUploadUserImage(requestState) {
    return {
        type: actionTypes.PROFILE_SET_REQUEST_STATE_UPLOAD_USER_IMAGE,
        payload: { requestState }
    };
}

function requestStateDeleteUserImage(requestState) {
    return {
        type: actionTypes.PROFILE_SET_REQUEST_STATE_DELETE_USER_IMAGE,
        payload: { requestState }
    }
}

function uploadUserImage(file) {
    return dispatch => {
        dispatch(requestStateUploadUserImage(true));
        accountService.uploadUserImage(file)
            .then(() => {
                const currentUser = user.current();
                dispatch(userProfilePhotoActions.fetchUserImage(currentUser.id));
                dispatch(setVisibleUploadImagePopup(false));
            })
            .catch(e => dispatch(errorActions.unexpectedError(e)))
            .finally(() => dispatch(requestStateUploadUserImage(false)));
    };
}

function deleteUserImage() {
    return dispatch => {
        dispatch(requestStateDeleteUserImage(true));
        accountService.deleteUserImage()
            .then(() => {
                const currentUser = user.current();
                dispatch(userProfilePhotoActions.fetchUserImage(currentUser.id));
                dispatch(setVisibleUploadImagePopup(false));
            })
            .catch(e => dispatch(errorActions.unexpectedError(e)))
            .finally(() => dispatch(requestStateDeleteUserImage(false)))
    };
}

function changeBwicAnnouncementNotifications(bwicAnnouncementNotifications) {
    return dispatch => {
        dispatch(sellerAdminUsersActions.changeBwicAnnouncementNotificationsRequest(RequestState.request));
        accountService.saveBwicAnnouncementNotifications(bwicAnnouncementNotifications)
            .then((bwicAnnouncementNotifications) => {
                dispatch(sellerAdminUsersActions.changeBwicAnnouncementNotificationsRequest(RequestState.success));
                dispatch(sellerAdminUsersActions.changeBwicAnnouncementNotifications({
                    bwicCreateNotifyAdmins: bwicAnnouncementNotifications.bwicCreateNotifyAdmins,
                    bwicCreateNotifyViewers: bwicAnnouncementNotifications.bwicCreateNotifyViewers
                }));
            })
            .catch(e => {
                dispatch(errorActions.unexpectedError(e));
                dispatch(sellerAdminUsersActions.changeBwicAnnouncementNotificationsRequest(RequestState.failure));
            })
    }
}