import { uploadDocumentsActions } from '.';

export const documentsActions = {
    init,
    reset,
}

function init() {
    return (dispatch, getState) => {
        const { grid } = getState();

        const securityIdList = grid.dataItems
            .filter(s => !s.draft)
            .map(s => s.securityId);

            dispatch(uploadDocumentsActions.init(securityIdList));
    }
}

function reset() {
    return dispatch => {
        dispatch(uploadDocumentsActions.reset());
    };
}
