import { createAction } from 'typesafe-actions';
import { RequestState } from '../../constants/request-state';
import { CloSystemInfrastructures } from '../../types/clo-managers/CloSystemInfrastructures';

const requestData = createAction(
    'entities/cloSystemInfrastructure/REQUEST',
    resolve => (requestState: RequestState) => resolve({ requestState }),
);

const responseData = createAction(
    'entities/cloSystemInfrastructure/RESPONSE',
    resolve => (requestState: RequestState, cloSystemInfrastructure: CloSystemInfrastructures[]) =>
        resolve({ requestState, cloSystemInfrastructure}),
);

export const cloSystemInfrastructureActions = {
    requestData,
    responseData
};
