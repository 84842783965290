import { createAction } from 'typesafe-actions';
import { RequestState } from "../constants/request-state";
import { UserSlim } from '../types/account/UserSlim';

const request = createAction('entities/broker-dealer-user-list/REQUEST');
const requestResult = createAction('entities/broker-dealer-user-list/REQUEST_RESULT',
    resolve => (requestState: RequestState, result: UserSlim[] = []) =>
        resolve({ requestState, result })
);

const resetEntities = createAction('entities/broker-dealer-user-list/RESET');

export const brokerDealerUserListActions = {
    request,
    requestResult,
    resetEntities
};
