import { ActionType, createAction } from 'typesafe-actions';
import { RequestState } from '../../constants/request-state';
import { Company } from '../../types/amr-pipeline/models/Company';

const request = createAction('entities/availableBrokerDealers/REQUEST');
const requestResult = createAction(
    'entities/availableBrokerDealers/REQUEST_RESULT',
    resolve =>
        (requestState: RequestState, companies: Company[] = []) =>
            resolve({ requestState, companies }),
);

export type TAvailableBrokerDealersActionType = ActionType<(typeof availableBrokerDealersActions)[keyof typeof availableBrokerDealersActions]>;

export const availableBrokerDealersActions = {
    request,
    requestResult,
};
