import { ActionType, createAction } from 'typesafe-actions';
import { CloManager } from '../types/clo-managers/CloManager';
import { CloManagerDetailed } from '../types/clo-managers/CloManagerDetailed';
import { CloManagerSave } from '../types/clo-managers/CloManagerSave';
import { CloManagerAccessType } from '../types/amr-pipeline/enums/CloManagerAccessType';
import { CloManagerAnalytics } from '../types/clo-managers/CloManagerAnalytics';
import { InvestmentTeamMember } from '../types/amr-pipeline/models/InvestmentTeamMember';
import { CloManagerAnalyticsFilter } from '../types/analytics/AnalyticsFilter';
import { TreeSelectOption } from '../components/controls/TreeSelect';
import { CloManagerClientActivity, CloManagerProfileView } from '../types/amr-pipeline/models/CloManagerSession';
import { DealSecurity } from '../types/security/DealSecurityStatistics';

const getCloManagers = createAction(
    'cloManagers/get/REQUEST',
    resolve => (companyReferenceName?: string) => resolve({ companyReferenceName })
);

const getCloManagersResult = createAction('cloManagers/get/REQUEST_RESULT',
    resolve => (cloManagers: CloManager[]) => resolve({ cloManagers })
);

const getCloManager = createAction(
    'cloManagers/getOne/REQUEST',
    resolve => (referenceName: string) => resolve({ referenceName }),
);

const getCloManagerResult = createAction(
    'cloManagers/getOne/REQUEST_RESULT',
    resolve => (
        referenceName: string,
        cloManager: CloManagerDetailed,
        bwicCloManagers: InvestmentTeamMember[],
    ) => resolve({
        referenceName,
        cloManager,
        bwicCloManagers,
    }),
);

const createOrUpdateCloManager = createAction(
    'cloManagers/createOrUpdate/REQUEST',
    resolve => (referenceName: string, cloManager: CloManagerSave) => resolve({ referenceName, cloManager }),
);

const setCloManagerSaveState = createAction(
    'cloManagers/createOrUpdate/STATE',
    resolve => (isUpdating: boolean) => resolve({ isUpdating }),
);

const logUserActivity = createAction(
    'cloManagers/logUserActivity/REQUEST',
    resolve => (companyReferenceName: string, accessType: CloManagerAccessType) =>
        resolve({ companyReferenceName, accessType })
);

const switchFavoriteRequest = createAction(
    'cloManagers/favorite/REQUEST',
    resolve => (companyReferenceName: string, favorite: boolean) => resolve({ companyReferenceName, favorite })
);

const switchFavoriteResponse = createAction(
    'cloManagers/favorite/RESPONSE',
    resolve => (companyReferenceName: string, favorite: boolean) => resolve({ companyReferenceName, favorite })
);

const analyticsInit = createAction(
    'cloManagers/analytics/INIT',
    (resolve) => (companyReferenceName: string) => resolve({ companyReferenceName })
);

const analyticsInitResponse = createAction(
    'cloManagers/analytics/INIT/RESPONSE',
    (resolve) =>
        (data: {
            users: TreeSelectOption<string>[];
            analytics: CloManagerAnalytics;
            numberOfHiddenItems: number;
        }) =>
            resolve(data)
);

const analyticsReset = createAction('cloManagers/analytics/RESET');

const clientsActivityRequest = createAction(
    'cloManagers/clientsActivity/REQUEST',
    resolve => (
        companyReferenceName: string,
        startDate?: Date,
        endDate?: Date,
    ) => resolve({
        companyReferenceName,
        startDate,
        endDate,
    })
);

const clientsActivityResponse = createAction(
    'cloManagers/clientsActivity/RESPONSE',
    resolve => (data: CloManagerClientActivity[], numberOfHiddenItems: number) => resolve({ data, numberOfHiddenItems })
);

const clientsActivityFilterChange = createAction(
    'cloManagers/clientsActivity/FILTER_CHANGE',
    resolve => (filter: CloManagerAnalyticsFilter) => resolve(filter),
);

const profileViewHistoryRequest = createAction(
    'cloManagers/profileViewHistory/REQUEST',
    resolve => (companyReferenceName: string, startDate?: Date | null, endDate?: Date | null) =>
        resolve({
            companyReferenceName,
            startDate,
            endDate,
        }),
);

const profileViewHistoryResponse = createAction(
    'cloManagers/profileViewHistory/RESPONSE',
    resolve => (data: CloManagerProfileView[], numberOfHiddenItems: number) => resolve({ data, numberOfHiddenItems }),
);

const setManagerForAlertPopup = createAction(
    'cloManagers/profileViewHistory/SET_MANAGER_FOR_ALERT_POPUP',
    resolve => (referenceName?: string) => resolve({ referenceName }),
);

const profileViewHistoryFilterChange = createAction(
    'cloManagers/profileViewHistory/FILTER_CHANGE',
    resolve => (filter: CloManagerAnalyticsFilter) => resolve(filter),
);

const profileViewHistoryFilterByBar = createAction(
    'cloManagers/profileViewHistory/FILTER_BY_BAR',
    resolve => (
        companyReferenceName: string,
        accessType: CloManagerAccessType,
        date: Date
    ) => resolve({
        companyReferenceName,
        accessType,
        date
    }),
);

const reset = createAction('cloManagers/RESET');

const selectedSecurityChange = createAction(
    'cloManagers/SELECTED_SECURITY_CHANGE',
    resolve => (security?: DealSecurity) => resolve({ security })
);

const editPlatformUsers = createAction(
    'cloManagers/editPlatformUsers',
    resolve => (email: string) => resolve({ email })
);

const resetEditPlatformUsers = createAction('cloManagers/resetEditPlatformUsers');

export type TCloManagersActions = ActionType<typeof cloManagersActions[keyof typeof cloManagersActions]>;

export const cloManagersActions = {
    getCloManagers,
    getCloManagersResult,
    getCloManager,
    getCloManagerResult,
    setManagerForAlertPopup,
    createOrUpdateCloManager,
    setCloManagerSaveState,
    logUserActivity,
    switchFavoriteRequest,
    switchFavoriteResponse,
    analyticsInit,
    analyticsInitResponse,
    analyticsReset,
    clientsActivityRequest,
    clientsActivityResponse,
    clientsActivityFilterChange,
    profileViewHistoryRequest,
    profileViewHistoryResponse,
    profileViewHistoryFilterChange,
    profileViewHistoryFilterByBar,
    reset,
    selectedSecurityChange,
    editPlatformUsers,
    resetEditPlatformUsers,
};
