import { ActionType, createAction } from "typesafe-actions";
import { RequestState } from "../constants/request-state";
import { SettlementAccount } from "../types/settlement/SettlementAccount";

const request = createAction('entities/settlement-account/REQUEST');
const requestResult = createAction('entities/settlement-account/REQUEST_RESULT',
    resolve => (requestState: RequestState, settlementAccounts: SettlementAccount[] = []) =>
        resolve({ requestState, settlementAccounts })
);
const update = createAction('entities/settlement-account/UPDATE',
        resolve => (account: SettlementAccount) => resolve({ account })
);
const deleteAccount = createAction('entities/settlement-account/DELETE',
        resolve => (accountId: number) => resolve({ accountId })
);
const reset = createAction('entities/settlement-account/RESET');

export type TSettlementAccountActionType =
    ActionType<
        typeof request |
        typeof requestResult |
        typeof update |
        typeof deleteAccount |
        typeof reset
    >;

export const settlementAccountActions = {
    request,
    requestResult,
    update,
    deleteAccount,
    reset
}
