import { createAction } from 'typesafe-actions';
import { PushHistoryStateItem, PushHistoryStateItemType } from '../../types/state/PushHistoryState';

const add = createAction(
    'push-history/add',
        resolve => (item: PushHistoryStateItem, type: PushHistoryStateItemType) => resolve({ item, type })
);
const reset = createAction('push-history/reset');
const resetForType = createAction(
    'push-history/resetHistoryForType',
        resolve => (...types: PushHistoryStateItemType[]) => resolve({ types: [...types] })
);

export const pushHistoryActions = {
    add,
    reset,
    resetForType,
}
