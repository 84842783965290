import {
    bwicHistoryActions,
    autoFeedbackSettingsActions,
    securityDetailsActions,
    sellerBiddingActions,
    pxTalkListActions,
    rulesActions
} from ".";
import { blastMessageActions } from "./blast-message.actions";
import { dealerListPanelActions } from "./dealer-list-panel.actions";
import { companyDetailsActions } from './company-details.actions';
import { textRulesPanelActions } from "./text-rules-panel.actions"
import { bwicLogActions } from "./bwic-log.actions";
import { history } from "../history";


export const controlPanelActions = {
    hide
};

function hide() {
    return dispatch => {
        dispatch(securityDetailsActions.securityDetailsReset());
        dispatch(bwicLogActions.bwicLogReset());
        dispatch(sellerBiddingActions.showBidList(false));
        dispatch(autoFeedbackSettingsActions.showAutoFeedbackSettingsPanel(false));
        dispatch(bwicHistoryActions.showHistory(false));
        dispatch(rulesActions.hide());
        dispatch(pxTalkListActions.hide());
        dispatch(dealerListPanelActions.hide());
        dispatch(blastMessageActions.hide());
        dispatch(companyDetailsActions.hide());
        dispatch(textRulesPanelActions.hide());

        if (history.location?.state?.panel) {
            history.replace({ ...history.location, state: undefined });
        }
    };
}
