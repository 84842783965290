import { createAction } from 'typesafe-actions';

const confirmRequest = createAction(
    'directBiddingDisclaimer/CONFIRM_REQUEST',
    resolve => (onConfirm?: () => void) => resolve({ onConfirm })
);
const confirmSuccess = createAction('directBiddingDisclaimer/CONFIRM_SUCCESS');
const confirmFailure = createAction('directBiddingDisclaimer/CONFIRM_FAILURE');

const downloadPdfRequest = createAction('directBiddingDisclaimer/DOWNLOAD_PDF_REQUEST');
const downloadPdfSuccess = createAction('directBiddingDisclaimer/DOWNLOAD_PDF_SUCCESS');
const downloadPdfFailure = createAction('directBiddingDisclaimer/DOWNLOAD_PDF_FAILURE');

const show = createAction(
    'directBiddingDisclaimer/SHOW',
    resolve => (blockerId: string) => resolve({ blockerId })
);
const hide = createAction(
    'directBiddingDisclaimer/HIDE',
    resolve => (confirmed: boolean) => resolve({ confirmed })
);

const reset = createAction('directBiddingDisclaimer/RESET');

export const directBiddingDisclaimerActions = {
    show,
    hide,
    confirmRequest,
    confirmSuccess,
    confirmFailure,
    downloadPdfRequest,
    downloadPdfSuccess,
    downloadPdfFailure,
    reset
};
