import { createAction } from "typesafe-actions"
import { SalesRepresentative } from "../types/sales-representative/SalesRepresentative";

const loadUserSalesRepresentatives = createAction('salesRepresentatives/LOAD');
const storeSalesRepresentatives = createAction('salesRepresentatives/STORE',
    resolve => (salesRepresentatives: SalesRepresentative[]) => resolve({ salesRepresentatives})
);
const reset = createAction('salesRepresentatives/RESET');

export const salesRepresentativeActions = {
    loadUserSalesRepresentatives,
    storeSalesRepresentatives,
    reset
}
