import { createAction } from 'typesafe-actions';
import { RequestState } from '../constants/request-state';
import { SettlementAgentAgreement } from '../types/bid-as-dealer/SettlementAgentAgreement';

const request = createAction('entities/settlement-agent-agreements/REQUEST');
const requestResult = createAction('entities/settlement-agent-agreements/REQUEST_RESULT',
    resolve => (requestState: RequestState, result: SettlementAgentAgreement[] = []) =>
        resolve({ requestState, result })
);
const pushDataReceived = createAction(
    'entities/settlement-agent-agreements/PUSH_DATA_RECEIVED',
        resolve => (agreement: SettlementAgentAgreement) => resolve({ agreement })
);
const resetEntities = createAction('entities/settlement-agent-agreements/RESET');

export const settlementAgentAgreementActions = {
    request,
    requestResult,
    pushDataReceived,
    resetEntities
};
