import { otherSellerBiddingActions as actionTypes } from '../constants';

export const otherSellerBiddingActions = {
    init,
    reset
}

function init(bwic) {
    return dispatch => {
        dispatch(storeSecurities(bwic.securities));
    };
}

function storeSecurities(securities) {
    return {
        type: actionTypes.OTHER_SELLER_BIDDING_STORE_SECURITIES,
        securities
    };
}

function reset() {
    return {
        type: actionTypes.OTHER_SELLER_BIDDING_RESET
    };
}
