import { createAction } from 'typesafe-actions';
import { RequestState } from '../constants/request-state';
import { Releases } from '../types/marketing/Release';

const fetchReleases = createAction('release/FETCH_RELEASES');
const getReleaseRequest = createAction('release/GET_RELEASE_REQUEST');
const getReleaseResponse = createAction('release/GET_RELEASE_RESPONSE',
    resolve => (requestStatus: RequestState, releases?: Releases) => resolve({ requestStatus, releases })
);

const markAsReadRequest = createAction('release/MARK_AS_READ_REQUEST');
const markAsReadResponse = createAction('release/MARK_AS_READ_RESPONSE');

export const releaseActions = {
    fetchReleases,
    getReleaseRequest,
    getReleaseResponse,
    markAsReadRequest,
    markAsReadResponse
};
