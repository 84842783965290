import { ActionType, createAction } from "typesafe-actions";
import { CanBidOnPosition, LiveBiddingPosition } from "../../types/live-bidding/LiveBiddingPosition";
import { RequestState } from "../../constants/request-state";

const request = createAction('entities/live-bidding/REQUEST');
const response = createAction('entities/live-bidding/RESPONSE',
    resolve => (positions: LiveBiddingPosition[], requestState: RequestState) =>
        resolve({ positions, requestState })
);
const reset = createAction('entities/live-bidding/RESET',
    resolve => (bwicReferenceName: string) => resolve({ bwicReferenceName })
);
const stage2LevelChange = createAction('entities/live-bidding/STAGE_2_LEVEL',
    resolve => (bwicReferenceName: string, positionId: number, level: number, tiedForBest: boolean, sentDateUtc: Date) =>
        resolve({ bwicReferenceName, positionId, level, tiedForBest, sentDateUtc })
);
const stage2Start = createAction('entities/live-bidding/STAGE_2_START',
    resolve => (
        bwicReferenceName: string,
        canDealerBidOnPositions?: CanBidOnPosition[],
        stage1EndDateUtc?: Date) =>
        resolve({ bwicReferenceName, canDealerBidOnPositions, stage1EndDateUtc }))

export const liveBiddingActions = {
    request,
    response,
    reset,
    stage2LevelChange,
    stage2Start
};

export type TLiveBiddingAction = ActionType<typeof liveBiddingActions[keyof typeof liveBiddingActions]>;