import { createAction, ActionType } from "typesafe-actions";
import { RequestState } from "../constants/request-state";
import { EmailPreferences } from "../types/email-preferences/EmailPreferences";
import { AlertEnabledState } from "../types/enums/AlertEnabledState";

const request = createAction('entities/email-preferences/REQUEST');
const requestResult = createAction('entities/email-preferences/REQUEST_RESULT',
    resolve => (requestState: RequestState, preferences?: EmailPreferences) => resolve({ preferences, requestState })
);
const reset = createAction('entities/email-preferences/RESET');
const inventoryAlertChange = createAction('entities/email-preferences/INVENTORY_ALERT_CHANGE',
    resolve => (enabled: AlertEnabledState) => resolve({ enabled })
);
const bwicAlertChange = createAction('entities/email-preferences/BWIC_ALERT_CHANGE',
    resolve => (enabled: AlertEnabledState) => resolve({ enabled })
);

export type TEmailPreferecnesActionTypes = ActionType<
    typeof request |
    typeof requestResult |
    typeof reset |
    typeof inventoryAlertChange |
    typeof bwicAlertChange
>;

export const emailPreferencesActions = {
    request,
    requestResult,
    reset,
    bwicAlertChange,
    inventoryAlertChange
};
